export default [
  {
    name:'Sales',
  },
  {
    name: 'Clients',
    icon: 'mdi-database',
    route: { name: 'PageServiceModelBrowse', params: { modelKey: 'client' } },
    disabled: true,
  },
  {
    name: 'Orders',
    icon: 'mdi-database',
    route: { name: 'PageServiceModelBrowse', params: { modelKey: 'order' } },
    disabled: true,
  },
  {
    name: 'Subscriptions',
    icon: 'mdi-database',
    route: { name: 'PageServiceModelBrowse', params: { modelKey: 'subscription' } },
    disabled: true,
  },
  {
    type: 'divider',
  },
]