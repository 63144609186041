<template>
  <transition name="fade">
    <v-alert
      v-if="api.isError"
      dense
      border="left"
      type="warning"
      class="text-pre-wrap"
    >{{api.error}}</v-alert>
  </transition>
</template>

<script>
  export default {
    props:['api']
  }
</script>
