<template>
  <div>
    <v-select
      v-model="parentValue"
      :label="field.parentName"
      :items="parentItems"
      :item-text="field.parentOptionText ? field.parentOptionText : 'name'"
      :item-value="field.parentOptionValue ? field.parentOptionValue : 'id'"
      :loading="parentApi.isLoading"
      :rules="parentRules"
      @change="loadParentItemToChild"
    />

    <v-text-field
      v-model="form.data[field.parentOptionTextSaveAs]"
      :label="field.parentName"
      v-if="isShow"
    />
  </div>
</template>
    
    <script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    services: (state) => state.service.data.services,
    rolePermission: (state) => state.rolePermission.data,
  }),
  props: {
    field: {
      type: Object,
    },
    parentRules: {
      type: Array,
    },

    form: {
      type: Object,
    },
  },
  watch: {
    parentValue(parentNewValue) {
      if (this.field.parentOptionTextSaveAs) {
        let parentValue = parentNewValue;
        let parentTextValue = this.parentItems.find(
          (item) => item[this.field.parentOptionValue] === parentValue
        );
        this.form.setDataProperty(
          this.field.parentOptionTextSaveAs,
          parentTextValue[this.field.parentOptionText]
        );
      }
      this.$emit("inputParent", parentNewValue);
    },
  },
  data: () => ({
    //BOC:[api]
    parentApi: new Api(),

    //BOC:[model]
    parentModel: new Model(),

    //BOC:[service]
    parentService: new Service(),
    //EOC
    //BOC:[select]
    parentItems: [],
    //EOC
    parentValue: null,
    isShow: false,
  }),
  created() {
    //console.log(this.auth.uuid);

    //BOC:[model]
    this.parentModel.getByKey(this.field.parentModelKey);
    //EOC
    //BOC:[service]
    if (this.parentModel.serviceKey)
      this.parentService.set("key", this.parentModel.serviceKey);
    //EOC
    //BOC:[api]
    if (this.field.parentApiListUrl) {
      var userRoles = this.auth.roles.map((role) => {
        return role.slug;
      });

      this.setUrlBasedOnUserRole(userRoles, this.field.modelServiceKey);
    } else {
      this.parentApi.setUrl(
        `${this.$service[this.parentService.key]}/v1/en/console/tabulator/model/${
          this.field.parentModelKey
        }/upsert/dropdown`
      );
    }

    this.parentApi.setCallbackCompleted((response) => {
      this.parentItems = response.data;
    });
    //EOC
    //BOC
    this.parentApi.fetch();
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    loadParentItemToChild() {
      let parentValue = this.parentValue;
      this.$emit("getParentSelectedItem", parentValue);
    },
    //${this.field.parentModelKey}
    setUrlBasedOnUserRole(userRoles, modelServiceKey) {
      switch (modelServiceKey) {
        case "lead":
          if (userRoles.includes("sales-lead")) {
            this.parentApi.setUrl(
              `${this.$service.sso}/v1/en/console/ssoTeam/list/teams/${this.auth.uuid}`
            );

          } else if (userRoles.includes("salesperson")) {
            this.parentApi.setUrl(
              `${this.$service.sso}/v1/en/console/ssoTeamToInternalUser/list/ssoTeam/${this.auth.uuid}`
            );
          } else if (userRoles.includes("sales-manager")) {
            this.parentApi.setUrl(
              `${this.$service[this.parentService.key]}${
                this.field.parentApiListUrl
              }`
            );
          } else {
            this.parentApi.setUrl(
              `${this.$service[this.parentService.key]}${
                this.field.parentApiListUrl
              }`
            );
          }
          break;

        case "leadSource":
          if (userRoles.includes("sales-lead")) {
            this.parentApi.setUrl(
              `${this.$service.sso}/v1/en/console/ssoBusiness/list/ssoBusiness/${this.auth.uuid}`
            );
          } else if (userRoles.includes("salesperson")) {
            this.parentApi.setUrl(
              `${this.$service.sso}/v1/en/console/ssoTeamToInternalUser/list/ssoTeam/${this.auth.uuid}`
            );
          } else if (userRoles.includes("sales-manager")) {
            this.parentApi.setUrl(
              `${this.$service[this.parentService.key]}${
                this.field.parentApiListUrl
              }`
            );
          } else {
            this.parentApi.setUrl(
              `${this.$service[this.parentService.key]}${
                this.field.parentApiListUrl
              }`
            );
          }
          break;

        default:
          this.parentApi.setUrl(
            `${this.$service[this.parentService.key]}${
              this.field.parentApiListUrl
            }`
          );
          break;
      }
    },
  },
};
</script>