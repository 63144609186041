import LayoutNested from '@/components/layouts/LayoutNested.vue'
//BOC:[lead]
import routerLead from "@/services/lead/router/mockup";
//EOC

export default {
  path: '/mockup',
  component: LayoutNested,
  children: [
    //BOC:[lead]
    routerLead,
    //EOC
    {
      path: 'leads',
      name: 'MockupConsoleLeadBrowse',
      component: () => import ('@/mockups/console/MockupConsoleLeadBrowse.vue'),
    },
    {
      path: 'contacts',
      name: 'MockupConsoleContactBrowse',
      component: () => import ('@/mockups/console/MockupConsoleContactBrowse.vue'),
    },
    {
      path: 'companies',
      name: 'MockupConsoleCompanyBrowse',
      component: () => import ('@/mockups/console/MockupConsoleCompanyBrowse.vue'),
    },
    {
      path: 'clients',
      name: 'MockupConsoleClientBrowse',
      component: () => import ('@/mockups/console/MockupConsoleClientBrowse.vue'),
    },
  ],
}