<template>
    <div class="text-center">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
          >
            Action
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="manageDetach">
            <v-list-item-title>Detach</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </template>

<script>
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import { mapState } from "vuex";

export default {
  components: {},
  computed: mapState({
    auth: (state) => state.auth.data,
    services: (state) => state.service.data.services,
    rolePermission: (state) => state.rolePermission.data,
  }),
  props: { 
    cellData: {},
    cellValue: {},
    cellTableData: {},
    cellRowData: {},
    ssoTeamId: {},
    childModelServiceKey: {},
    parentModelKey: {},
    intermediateChildModelKey: {},
    childModelKey: {},
    modelKey: {},
    modelServiceKey: {},
    parentModelId: {},
    intermediateModelId: {},
  },

  data: () => ({
    model: new Model(),
    service: new Service(),
    api: new Api(),
    isTeamAdmin: false,
    adminCount: 0,
  }),

  created() {
    this.model.getByKey(this.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    //console.log(this.manageDetachButton);

    // get url params
      if(this.childModelKey === 'ssoTeamToInternalUser'){
        let modelId = parseInt(this.parentModelId, 10);
        let internalUserUuid = this.auth.uuid;

        // get value from API
        this.api.setMethod('GET');
        this.api.setUrl(`${this.$service[this.childModelServiceKey]}/v1/en/console/ssoTeamToInternalUser/${modelId}/${internalUserUuid}`);
        this.api.setCallbackCompleted((resp) => {
          if(resp.data[0].isAdmin){
            this.isTeamAdmin = true;
          }else{
            this.isTeamAdmin = false;
          }
        });
        this.api.fetch();

        //console.log(modelId, internalUserUuid);
      } 
  },

  mounted() {
    //
  },

  methods: {
      
    manageDetach() {

      if(this.auth.uuid !== 'internal_admin'){

        if(this.childModelKey === 'ssoTeamToInternalUser'){

            if(!this.isTeamAdmin){
              this.$store.dispatch("showMessage", {
                message: "You are not allowed to detach this member.",
                messageType: "error",
                timeout:2000
              });
              return;
            }

            let tableRowCount = this.cellTableData.length;
            if(tableRowCount == 1){
                this.$store.dispatch("showMessage", {
                    message: "Cannot detach the last member.",
                    messageType: "error",
                    timeout:2000
                });
            return;
            }

            this.cellTableData.forEach((item) => {
              if (item.isAdmin) {
                this.adminCount++;
              }
            });

            if (this.cellRowData.isAdmin && this.adminCount == 1) {
              //this.$store.dispatch("showMessage", "Cannot detach the last admin.");
              this.$store.dispatch("showMessage", {
                    message: "Cannot detach the last admin.",
                    messageType: "error",
                    timeout:2000

                });
              this.adminCount = 0;
              return;
            }

        }

      }

      this.$router.push({
        name: 'PageServiceModelBreadTabulatorChildDetach',
        params: {
            serviceKey: this.childModelServiceKey,
            parentModelKey: this.parentModelKey,
            parentModelId: this.parentModelId,
            intermediateModelKey: this.intermediateChildModelKey,
            intermediateModelId: this.intermediateModelId,
            childModelKey: this.childModelKey,
        },
      });
    }
  }
}

</script>

<style>

.action-button {
  width: 90% !important;
  height: 100% !important;
}

</style>