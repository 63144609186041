export default [
  // {
  //   name:'Bahasa Melayu',
  //   label:'BM',
  //   value:'ms',
  // },
  // {
  //   name:'中文',
  //   label:'中文',
  //   value:'zh',
  // },
  {
    name:'English',
    label:'EN',
    value:'en',
  },
]