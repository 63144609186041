export default class LeadStatus{
    constructor() {
        this.items = [
            { label: "New Lead", value: "New Lead" },
            { label: "Open", value: "Open" },
            { label: "Working", value: "Working" },
            { label: "Unqualified", value: "Unqualified" },
            { label: "Tried to Contact", value: "Tried to Contact"},
            { label: "Contacted", value: "Contacted" },
            { label: "Connected", value: "Connected" },
            { label: "Qualified", value: "Qualified" },
            { label: "Engaged", value: "Engaged" },
            { label: "Nurturing", value: "Nurturing" },
            { label: "Converted", value: "Converted" },
            { label: "Recycled", value: "Recycled" },
            { label: "Closed", value: "Closed" },
        ];
        
        /* this.items = this.items.map((item) => {
            item.label = item.label.toUpperCase();
            return item;
        }); */
    }

    getItems() {
        return this.items;
    }
}