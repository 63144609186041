<template>
  <div v-if="debugMode.isDeveloping">
    <v-btn 
      fab
      dark
      style="position:fixed; top:1em;right:1em;z-index:10000;"
      @click="dialog = true"
    >DEV</v-btn>
    <v-dialog
      transition="dialog-top-transition"
      v-model="dialog"
      width="auto"
    >
      <v-card>
        <v-card-title>Note for Developer</v-card-title>
        <v-card-text>
          <ul v-if="Array.isArray(text)">
            <span v-for="(t1,i) of text" :key="i">
              <ul v-if="Array.isArray(t1)">
                <li v-for="(t2,i2) of t1" :key="i2">{{t2}}</li>
              </ul>
              <li v-else>{{t1}}</li>
            </span>
          </ul>
          <div v-else>{{text}}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
  computed: {
    ...mapState({
      debugMode: (state) => state.debugMode.data,
    }),
  },
    props:{
      text:{
        type:[Array,String],
        default:'-',
      },
    },
    data: () => ({
      dialog:false,
    }),
    watch: {
      //
    },
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>