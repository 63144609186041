export default {
  state: {
    data: {
      visible: false,
      text: null,
      timeout: 1000,
      messageType: "success",
    },
  },
  getters: {
    //
  },
  actions: {
    showMessage(context, payload) {
      const { message, messageType, timeout } = payload;

      context.commit("updateSnackbar", {
        visible: true,
        text: message,
        timeout: timeout || 1000,
        color: messageType || "success",
      });
    },

    updateSnackbar(context, data) {
      context.commit("updateSnackbar", data);
    },
  },
  mutations: {
    updateSnackbar(state, data) {
      return (state.data = {
        ...state.data,
        ...data,
      });
    },
  },
};
