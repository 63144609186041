<template>
  <div>
    <!-- 2024-03-14: Disabled breadcrumbs, instead remove from every page, hide it from view for temporary quick fix -->
    <!-- <v-breadcrumbs class="px-0" :items="items"></v-breadcrumbs> -->
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:{
      items:{
        type:Array,
      }
    },
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>