export default class Filtertypes {
  constructor() {
    this.items = [
      { text: "Equal", value: "=" },
      { text: "Not Equal", value: "!=" },
      { text: "Like", value: "like" },
      { text: "Keywords", value: "keywords" },
      { text: "Starts With", value: "starts" },
      { text: "Ends With", value: "ends" },
      { text: "Less Than", value: "<" },
      { text: "Less Than Or Equal To", value: "<=" },
      { text: "Greater Than", value: ">" },
      { text: "Greater Than Or Equal To", value: ">=" },
    ];
  }

  getAllFilterTypes() {
    return this.items;
  }

  getFilterTypeByType(type) {
    if (type === "float" || type === "integer" || type === "numeric") {
      return [
        { text: "Equal", value: "=" },
        { text: "Not Equal", value: "!=" },
        { text: "Less Than", value: "<" },
        { text: "Less Than Or Equal To", value: "<=" },
        { text: "Greater Than", value: ">" },
        { text: "Greater Than Or Equal To", value: ">=" },
      ];
    }

    if (type === "boolean") {
      return [
        { text: "Equal", value: "=" },
        { text: "Not Equal", value: "!=" },
      ];
    }

    if (type === "date") {
      return [
        { text: "Equal", value: "=" },
        { text: "Not Equal", value: "!=" },
        { text: "Less Than", value: "<" },
        { text: "Less Than Or Equal To", value: "<=" },
        { text: "Greater Than", value: ">" },
        { text: "Greater Than Or Equal To", value: ">=" },
      ];
    }

    if (type === "text") {
      return [
        { text: "Equal", value: "=" },
        { text: "Not Equal", value: "!=" },
        { text: "Like", value: "like" },
        { text: "Keywords", value: "keywords" },
        { text: "Starts With", value: "starts" },
        { text: "Ends With", value: "ends" },
      ];
    }

    if (type === "onlyLikeEqualNotEqual") {
      return [
        { text: "Like", value: "like" },
        { text: "Equal", value: "=" },
        { text: "Not Equal", value: "!=" },
      ];
    }

    if (type === "onlyGteLte") {
      return [
        { text: "Greater Than Or Equal To", value: ">=" },
        { text: "Less Than Or Equal To", value: "<=" },
      ];
    }

    if (type === "onlyEqualNotEqual") {
      return [
        { text: "Equal", value: "=" },
      ];
    }

    if (type === "onlyLike") {
      return [
        { text: "Like", value: "like" },
      ];
    }
  }
}
