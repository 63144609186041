<template>
  <div>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <ALoader v-if="isLoading" :isLoading="isLoading"/>
      <v-card v-else :loading="isLoading">
        <v-card-text>
          <template v-for="(field, index) in form.fields">
            <template v-if="field.type == 'model'">
              <BreadFormSelectModel
                :form="form"
                :key="index"
                :field="field"
                @input="form.data[field.key] = $event"
                :rules="rules[field.key] ? rules[field.key] : []"
                :disabled="(parentModel) ? (field.key == ((parentModel.column) ? `${parentModel.key}${$_ucfirst(parentModel.column.uid)}` : `${parentModel.key}Id`)) : false"
              ></BreadFormSelectModel>
            </template>

            <template v-else-if="field.type == 'boolean'">
              <v-checkbox
                v-model="form.data[field.key]"
                :key="index"
                :label="field.name"
                :rules="rules[field.key] ? rules[field.key] : []"
              ></v-checkbox>
            </template>

            <template v-else>
              <v-text-field
                v-model="form.data[field.key]"
                :type="field.type"
                :key="index"
                :label="field.name"
                :max="field.max ? field.max : false"
                :min="field.min ? field.min : false"
                :rules="rules[field.key] ? rules[field.key] : []"
              ></v-text-field>
            </template>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)">
            <span>Cancel</span>
          </v-btn>
          <v-btn
            :disabled="isLoading"
            color="primary"
            :loading="api.isLoading"
            @click="validate"
          >
            <span>Confirm</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import BreadFormSelectModel from "@/components/common/BreadFormSelectModel.vue";
//BOC:[form]
import Form from "@/objects/form";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    BreadFormSelectModel,
  },
  computed: mapState({
    //
  }),
  props: {
    action: {
      type: String,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      required: true,
    },
    parentModel: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    //BOC:[form]
    form: new Form(),
    //EOC
    //BOC:[rules]
    rules: {},
    //EOC
  }),
  watch: {
    //
  },
  created() {
    //BOC:[form]
    this.form.get({ model: this.model, action: this.action });
    this.createRules();
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    setData(data) {
      this.form.setData(data);
    },
    //BOC:[form]
    validate() {
      this.api.callbackReset();
      if (this.$refs.form.validate()) {
        this.submit();
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    submit() {
      var data = this.$_.clone(this.form.data);
      //BOC: remove unneccessary data
      if (data.timestampUpdated) delete data.timestampUpdated;
      if (data._count) delete data._count;
      //EOC
      //BOC: remove parent data
      if(this.model.parents) {
        for(var parent of this.model.parents) {
          delete data[this.$_ucfirst(parent)];
        }
      }
      //EOC
      data = JSON.stringify(data);
      console.log(data);
      //
      this.api.setParams({
        modelKey: this.model.key,
        data: data,
      });
      this.api.fetch();
    },
    //EOC
    //BOC:[rules]
    createRules() {
      var rules = {};
      var validations = {
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid email.";
        },
        mobile: (value) => {
          const pattern = /^[+()-\d]+$/;
          return pattern.test(value) || "Invalid mobile number.";
        },
        required: (value) => !!value || "Required.",
      };
      //
      for (const [key, value] of Object.entries(this.form.rules)) {
        rules[key] = [];
        for (const ruleKey of value) {
          rules[key].push(validations[ruleKey]);
        }
      }
      //
      this.rules = rules;
    },
    //EOC
  },
};
</script>