<template>
  <div>
    <v-select
      v-model="childValue"
      :label="field.childName"
      :items="childItems"
      :item-text="field.childOptionText ? field.childOptionText : 'name'"
      :item-value="field.childOptionValue ? field.childOptionValue : 'id'"
      :loading="childApi.isLoading"
      :rules="childRules"
    />

    <v-text-field
      v-model="form.data[field.childOptionTextSaveAs]"
      :label="field.childName"
      v-if="isShow"
    />
  </div>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    services: (state) => state.service.data.services,
    rolePermission: (state) => state.rolePermission.data,
    parentSelectedModelValue: (state) => state.parentSelectedModelValue.data,
  }),
  props: {
    field: {
      type: Object,
    },

    childRules: {
      type: Array,
    },
    form: {
      type: Object,
    },
    parentSelectedValue: {
      type: String,
    },
  },
  watch: {
    parentSelectedValue(newParentValue) {
      this.getParentSelectedItem(newParentValue);
    },

    childValue(childNewValue) {
      if (this.field.childOptionTextSaveAs) {
        let childValue = childNewValue;
        let childTextValue = this.childItems.find(
          (item) => item[this.field.childOptionValue] === childValue
        );
        this.form.setDataProperty(
          this.field.childOptionTextSaveAs,
          childTextValue[this.field.childOptionText]
        );
      }

      this.$emit("inputchild", childNewValue);
    },
  },
  data: () => ({
    childApi: new Api(),
    //EOC
    childModel: new Model(),
    //EOC
    childService: new Service(),
    //EOC
    //BOC:[select]
    childItems: [],
    //EOC
    childValue: null,
    isShow: false,
  }),
  created() {
    //BOC:[model]
    this.childModel.getByKey(this.field.childModelKey);
    //EOC
    //BOC:[service]
    if (this.childModel.serviceKey)
      this.childService.set("key", this.childModel.serviceKey);
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    getParentSelectedItem(parentValue) {
      if (this.field.childApiListUrl) {

        var userRoles = this.auth.roles.map((role) => {
          return role.slug;
        });

        this.setUrlBasedOnUserRole(userRoles, this.field.modelServiceKey, parentValue);

      } else {

        this.childApi.setUrl(
          `${this.$service[this.childService.key]}/v1/en/console/tabulator/model/${
            this.field.childModelKey
          }/upsert/dropdown`
        );
      }

      this.childApi.setCallbackCompleted((response) => {
        this.childItems = response.data;
      });
      //EOC
      //BOC
      this.childApi.fetch();
      //EOC
    },

    setUrlBasedOnUserRole(userRoles, modelServiceKey, parentValue) {

      let defaultUrl = `${this.$service[this.childService.key]}${
        this.field.childApiListUrl
      }/${parentValue}`;

      switch (modelServiceKey) {
        case "lead":
          if (userRoles.includes("salesperson")) {
            if(this.field.childKey == 'leadSourceUuid'){
                this.childApi.setUrl(defaultUrl);
            }
            if(this.field.childKey == 'salespersonInternalUserUuid'){
              this.childApi.setUrl(
                `${this.$service.sso}/v1/en/console/ssoTeamToInternalUser/list/internalUsers/${parentValue}/${this.auth.uuid}`
              );
            }
          }else{
            this.childApi.setUrl(defaultUrl);
          }
          break;

        case "leadSource":
          if (userRoles.includes("sales-lead")) {
            this.childApi.setUrl(
              `${this.$service.sso}/v1/en/console/ssoTeam/list/${parentValue}/${this.auth.uuid}`
            );
          } else if (userRoles.includes("salesperson")) {
            this.childApi.setUrl(
              `${this.$service.sso}/v1/en/console/ssoTeamToInternalUser/list/ssoTeam/${this.auth.uuid}`
            );
          }else {
            this.childApi.setUrl(defaultUrl);
          }
          break;

          default:
            this.childApi.setUrl(defaultUrl);
          break;
      }
    },
  },
};
</script>
