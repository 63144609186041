import LayoutNested from '@/components/layouts/LayoutNested.vue'

export default {
  path: `/service/:serviceKey`,
  component: LayoutNested,
  children: [
    {
      path: 'components',
      name: 'PageDeveloperComponent',
      component: () => import("@/services/developer/views/PageDeveloperComponent.vue"),
    },
    {
      path: 'components/bread-tabulator',
      name: 'PageDeveloperComponentBreadTabulator',
      component: () => import("@/services/developer/views/PageDeveloperComponentBreadTabulator.vue"),
    },

    {
      path: 'components/bread-tabulator/component',
      name: 'PageDeveloperComponentBreadTabulatorComponent',
      component: () => import("@/services/developer/views/PageDeveloperComponentBreadTabulatorComponent.vue"),
    },

    {
      path: 'components/bread-tabulator/table',
      name: 'PageDeveloperComponentBreadTabulatorTable',
      component: () => import("@/services/developer/views/PageDeveloperComponentBreadTabulatorTable.vue"),
    },

    {
      path: 'components/bread-tabulator/filter',
      name: 'PageDeveloperComponentBreadTabulatorFilter',
      component: () => import("@/services/developer/views/PageDeveloperComponentBreadTabulatorFilter.vue"),
    },


    {
      path: 'components/bread-tabulator/columns',
      name: 'PageDeveloperComponentBreadTabulatorColumns',
      component: () => import("@/services/developer/views/PageDeveloperComponentBreadTabulatorColumns.vue"),
    },

    {
      path: 'components/bread-tabulator/columns/:columnKey',
      name: 'PageDeveloperComponentBreadTabulatorColumnsView',
      component: () => import("@/services/developer/views/PageDeveloperComponentBreadTabulatorColumnsView.vue"),
    },

    {
      path: 'components/bread-tabulator/edit-cell',
      name: 'PageDeveloperComponentBreadTabulatorEditCell',
      component: () => import("@/services/developer/views/PageDeveloperComponentBreadTabulatorEditCell.vue"),
    },

    {
      path: 'components/bread-tabulator/export',
      name: 'PageDeveloperComponentBreadTabulatorExport',
      component: () => import("@/services/developer/views/PageDeveloperComponentBreadTabulatorExport.vue"),
    },

    {
      path: 'microservices',
      name: 'PageDeveloperMicroservice',
      component: () => import("@/services/developer/views/PageDeveloperMicroservice.vue"),
    },

    // bread-tabulator browse and bred pages

    /* {
      path: 'components/bread-tabulator/browse',
      name: 'PageDeveloperComponentBreadTabulatorBrowse',
      component: () => import("@/services/developer/views/PageDeveloperComponentBreadTabulatorBrowse.vue"),
    }, */

     /* {
      path: 'dev-model/:modelKey',
      name: 'PageDeveloperComponentBreadTabulatorBrowse',
      component: () => import('@/views/service/TabulatorPages/PageServiceModelBrowse.vue'),
    },  */

    /* {
      path: 'dev-model/:modelKey/:parentColumnName/:parentId',
      name: 'PageDeveloperComponentBreadTabulatorBrowseParent',
      component: () => import('@/views/service/TabulatorPages/PageServiceModelParentBrowse.vue'),
    },  */

    /* {
      path: 'dev-model/browse/template',
      name: 'PageDeveloperComponentBreadTabulatorBrowseTemplate',
      component: () => import("@/views/template/BrowseTemplate.vue"),
    }, */

  
    
  ],
}