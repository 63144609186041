<template>
  <v-data-table
    v-model="selectedRows"
    :headers="viewableColumns"
    :items="api.data ? api.data : []"
    :loading="api ? api.isLoading : false"
    :server-items-length="api.info ? api.info.totalItems : 0"
    :options.sync="options"
    no-data-text="No data."
    no-results-text="No data."
    :show-select="operation == 'browse' ? ((model.browse.tools && typeof model.browse.tools.enableRowSelection != 'undefined') ? model.browse.tools.enableRowSelection : true) : false"
    :footer-props="{
      showFirstLastPage: true,
      showCurrentPage: true,
      itemsPerPageOptions: [10, 50, 100, 500, 1000, 5000, 10000],
    }"
    :mobile-breakpoint="operation == 'browse' ? 600 : 9999"
    :hide-default-footer="operation == 'browse' ? false : true"
    :disable-sort="operation == 'read'"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
  >
    <!--BOC: duplicate footer to top -->
    <template v-slot:top="{ pagination, options, updateOptions }">
      <v-data-footer
        v-if="operation == 'browse'"
        :showFirstLastPage="true"
        :showCurrentPage="true"
        :pagination="pagination"
        :options="options"
        @update:options="updateOptions"
        items-per-page-text="$vuetify.dataTable.itemsPerPageText"
        :itemsPerPageOptions="[10, 50, 100, 500, 1000, 5000, 10000]"
      />
    </template>
    <!-- EOC -->

    <template
      v-for="(header, index) in viewableColumns"
      v-slot:[`item.${header.value}`]="props"
    >
      <div :key="header.value">
        <!--BOC:[parent]-->
        <BreadDataTableColumnParentV2
          v-if="header.dataType === 'model' && header.relationship === 'parent'"
          :key="index"
          :header="header"
          :item="props.item"
        />
        <!--BOC:[child]-->
        <BreadDataTableColumnChildV2
          v-else-if="header.dataType === 'model' && header.relationship === 'child'"
          :key="index"
          :header="header"
          :item="props.item"
        />
        <!-- EOC -->
        <!-- BOC: [dataType] -->
        <div v-else-if="header.dataType === 'boolean'">
          <span v-if="props.item[header.value]" class="success--text">⬤ Yes</span>
          <span v-else class="error--text">⬤ No</span>
        </div>


        <div v-else-if="header.dataType === 'manageTeamMemberAdminStatus'">
          <span v-if="props.item[header.value]" class="success--text">⬤ Admin
            <br>
              <v-btn x-small @click="changeTeamMemberAdminStatus(props.item)">Remove from admin</v-btn>
          </span>
          <span v-else class="error--text">⬤ Member
            <br><v-btn x-small @click="changeTeamMemberAdminStatus(props.item)">Assign as Admin</v-btn>
          </span>
          <v-dialog v-model="teamMemberAdminStatus" persistent :retain-focus="false" max-width="350px">
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div style="margin: 20px 0px;">
                        <h5 style="font-size: 16px;"> {{ teamMemberAdminConformationText }}</h5>
                    </div>
                  </v-col>
                  <!-- Add other fields as necessary -->
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDialog()">No</v-btn>
              <v-btn color="blue darken-1" text @click="updateTeamMemberAdminStatus()">Yes</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        </div>

        <!-- EOC -->
        <v-edit-dialog
          v-else-if="header.editableSingleCell"
          :key="header.value"
          :return-value.sync="props.item[header.value]"
          large
          @save="
            saveSingleCellValue(
              props.item.id,
              header.value,
              props.item[header.value]
            )
          "
          @cancel="cancel"
        >
          {{ props.item[header.value] }}
          <template v-slot:input>
            <v-text-field
              v-if="header.dataType === 'string'"
              v-model="props.item[header.value]"
              :rules="[rules.required]"
              label="Edit"
              single-line
            ></v-text-field>
            <v-text-field
              v-if="header.dataType === 'email'"
              v-model="props.item[header.value]"
              :rules="[rules.required, rules.email]"
              label="Edit"
              single-line
            ></v-text-field>
            <v-text-field
              v-if="header.dataType === 'number'"
              v-model="props.item[header.value]"
              :rules="[rules.required, rules.number]"
              label="Edit"
              single-line
              type="number"
            ></v-text-field>
            <v-text-field
              v-if="header.dataType === 'url'"
              v-model="props.item[header.value]"
              :rules="[rules.required, rules.url]"
              label="Edit"
              single-line
            ></v-text-field>
            <v-text-field
              v-if="header.dataType === 'mobile'"
              v-model="props.item[header.value]"
              :rules="[rules.required]"
              label="Edit"
              single-line
            ></v-text-field>
          </template>
        </v-edit-dialog>
        <span class="v-small-dialog__activator__content" v-else>
          <span v-if="props.item[header.value]">{{ props.item[header.value] }}</span>
          <span v-else>-</span>
        </span>
      </div>
    </template>
    <!--BOC:[parent]-->
    <!-- <template
      v-for="(parent, index) in models"
      v-slot:[`item.${parent.parentColumnName}`]="{ item }"
    >
      <BreadDataTableColumnParent
        :key="index"
        :model="parent.model"
        :item="item"
      />
    </template> -->
    <!--EOC-->
    <!--BOC:[child]-->
    <!-- <template
      v-for="(child, index) in models"
      v-slot:[`item.${child.childColumnName}`]="{ item }"
    >
      <BreadDataTableColumnChild
        :key="index"
        :model="child.model"
        :item="item"
      />
    </template> -->
    <!--EOC-->
    <!--BOC: customise column [in alphabetical order] -->
    <template v-slot:item.actions="{ item }">
      <div
        v-if="table.actions && table.actions.single"
        class="d-flex justify-end"
      >
        <div v-if="item.timestampDeleted">
          <v-btn
            :disabled="!table.actions.single.deleteHard"
            text
            small
            color="error"
            class="mx-1"
            :to="{
              name: 'PageServiceModelDeleteHard',
              params: {
                serviceKey: model.serviceKey,
                modelKey: modelKey,
                modelId: item.id,
              },
            }"
            >Delete Forever</v-btn
          >
          <v-btn
            :disabled="!table.actions.single.restore"
            text
            small
            color="primary"
            class="mx-1"
            :to="{
              name: 'PageServiceModelRestore',
              params: {
                serviceKey: model.serviceKey,
                modelKey: modelKey,
                modelId: item.id,
              },
            }"
            >Restore</v-btn
          >
        </div>
        <div v-else>
          <div v-if="modelKey == 'ssoTeamToInternalUser'">
            <v-btn
            v-if="table.actions.single.detach"
            text
            small
            color="error"
            class="mx-1"
            @click="manageDetachStatus(item)"
            >Detach</v-btn>
          </div>

          <div v-else>

            <v-btn
            v-if="table.actions.single.detach"
            text
            small
            color="error"
            class="mx-1"
            :to="{
              name: 'PageServiceModelChildDetach',
              params: {
                serviceKey: model.serviceKey,
                parentModelKey: parentModelKey,
                parentModelId: parentModelId,
                childModelKey: intermediateChildModelKey,
                intermediateModelKey: modelKey,
                intermediateModelId: item.id,
              },
            }"
            >Detach</v-btn>

          </div>

          
          <v-btn
            v-if="table.actions.single.delete"
            text
            small
            color="error"
            class="mx-1"
            :to="{
              name: 'PageServiceModelDelete',
              params: {
                serviceKey: model.serviceKey,
                modelKey: modelKey,
                modelId: item.id,
              },
            }"
            >Delete</v-btn
          >
          <v-btn
            v-if="table.actions.single.edit"
            text
            small
            color="primary"
            class="mx-1"
            :to="{
              name: 'PageServiceModelEdit',
              params: {
                serviceKey: model.serviceKey,
                modelKey: modelKey,
                modelId: item.id,
              },
            }"
            >Edit</v-btn
          >
          <v-btn
            v-if="table.actions.single.read"
            text
            small
            color="primary"
            class="mx-1"
            :to="{
              name: 'PageServiceModelRead',
              params: {
                serviceKey: model.serviceKey,
                modelKey: modelKey,
                modelId: item.id,
              },
            }"
            >Read</v-btn
          >
        </div>
      </div>
    </template>
    <template v-slot:item.index="{ item, index }">
      <div>{{ index + 1 }}</div>
    </template>
    <template v-slot:item.timestampCreated="{ item }">
      <div>
        {{ $moment(item.timestampCreated).format("YYYY-MM-DD HH:mm:ss A") }}
      </div>
    </template>
    <template v-slot:item.timestampUpdated="{ item }">
      <div>
        <!-- {{ $moment(item.timestampUpdated).fromNow() }} -->
        {{ $moment(item.timestampUpdated).format("YYYY-MM-DD HH:mm:ss A") }}
      </div>
    </template>
    <!-- EOC -->
    <!-- BOC：service specified -->
    <template v-slot:item.status="{ item }">
      <div>
        <span v-if="!item.status">-</span>
        <v-chip x-small v-else-if="item.status == 'Success'" color="success">Success</v-chip>
        <v-chip x-small v-else-if="item.status == 'Failed'" color="error">Failed</v-chip>
        <v-chip x-small v-else-if="item.status == 'Ongoing'" color="warning">Ongoing</v-chip>
        <v-chip x-small v-else>{{item.status}}</v-chip>
      </div>
    </template>
    <!-- EOC -->
  </v-data-table>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
//BOC:[models]
import models from "@/models";
//EOC
//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC
//BOD:[parent]
// import BreadDataTableColumnParent from "@/components/common/BreadDataTableColumnParent";
import BreadDataTableColumnParentV2 from "@/components/common/BreadDataTableColumnParentV2";
//EOC
//BOD:[child]
// import BreadDataTableColumnChild from "@/components/common/BreadDataTableColumnChild";
import BreadDataTableColumnChildV2 from "@/components/common/BreadDataTableColumnChildV2";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //BOC:[parent]
    // BreadDataTableColumnParent,
    BreadDataTableColumnParentV2,
    //EOC
    //BOC:[child]
    // BreadDataTableColumnChild,
    BreadDataTableColumnChildV2,
    //EOC
  },
  computed: mapState({
    //
  }),
  props: {
    api: {
      type: Object,
      required: true,
    },
    conditions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    searchedColumn: {
      type: String,
      default: null,
    },
    searchedKeyword: {
      type: String,
      default: "",
    },
    modelKey: {
      type: String,
    },
    operation: {
      type: String,
      default: "browse",
    },
    sortBy: {
      type: Array,
      default: () => {
        return [];
      },
    },
    sortDesc: {
      type: Array,
      default: () => {
        return [];
      },
    },
    table: {
      type: Object,
      required: true,
    },
    viewableColumns: {
      type: Array,
    },
    //BOC
    parentModelKey: {
      type: String,
      default:null,
    },
    parentModelId: {
      type: Number,
      default:null,
    },
    intermediateChildModelKey: {
      type: String,
      default:null,
    },
    //EOC
  },
  data: () => ({

    apiUpdateCellRecord: new Api(),
    teamMemberAdminStatus: false,
    teamMemberEditedItem: {},
    teamInternalUserNames: '',
    teamMemberAdminConformationText: '',
    adminCount: 0,
    options: {},
    selectedRows: [],
    //BOC:[models]
    models: [],
    //EOC
    //BOC:[model]
    model: new Model(),
    //EOC
    //BOC:[service]
    service: new Service(),
    //EOC
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      number: (value) => {
        const pattern = /^\d+$/;
        return pattern.test(value) || "Invalid number.";
      },
      url: (value) => {
        const pattern =
          /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
        return pattern.test(value) || "Invalid URL.";
      },
    },
  }),
  watch: {
    options: {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },
  created() {
    //BOC:[models]
    for (const [key, value] of Object.entries(models)) {
      this.models.push({
        model: value,
        parentColumnName: `Parent${this.$_ucfirst(key)}`,
        childColumnName: `Child${this.$_ucfirst(key)}`,
      });
    }
    //EOC
    //BOC:[model]
    this.model.getByKey(this.$route.params.modelKey);
    //EOC
    //BOC:[service]
    if(this.model.serviceKey) this.service.set("key",this.model.serviceKey);
    //EOC

    
  },
  mounted() {
    //
  },
  methods: {
    fetch() {
      var { page, itemsPerPage, sortBy, sortDesc } = this.options; //defined by Vuetify 2 Data Table
      var query = `page=${page}&itemsPerPage=${itemsPerPage}`;
      if (this.conditions)
        query += `&conditions=${JSON.stringify(this.conditions)}`;
      if (sortBy.length > 0) query += `&sortBy=${sortBy[0]}`;
      if (sortDesc.length > 0) query += `&sortDesc=${sortDesc[0]}`;
      if (this.searchedColumn && this.searchedKeyword)
        query += `&searchedColumn=${this.searchedColumn}&searchedKeyword=${this.searchedKeyword}`;
      this.api.setQuery(query);
      if (this.api) this.api.fetch();
    },
    deleteSelectedRows() {
      return this.selectedRows;
    },

    saveSingleCellValue(rowId, columnName, cellValue) {

      const modelId = rowId;

      const data = {
        [columnName]: cellValue,
      };

      this.apiUpdateCellRecord.setMethod(`POST`);
      this.apiUpdateCellRecord.setUrl(
        `${this.$service[this.service.key]}/v1/en/console/model/${this.model.key}/${modelId}/edit`
      );
      this.apiUpdateCellRecord.setParams({
        data: JSON.stringify(data),
      });
      this.apiUpdateCellRecord.setCallbackCompleted(() => {
        /**
         * Please can you let me know how to refresh the table after the update?
         */

        this.fetch();
        this.$store.dispatch("showMessage", "Updated successfully.");
      });
      this.apiUpdateCellRecord.fetch(); 
    },

    cancel() {},

    changeTeamMemberAdminStatus(item) {

      this.teamMemberEditedItem = Object.assign({}, item);
      this.teamInternalUserNames = this.teamMemberEditedItem.InternalUser.name;
      this.teamMemberAdminConformationText = this.teamMemberEditedItem.isAdmin ? `Remove ${this.teamInternalUserNames} from admin ?` : `Assign ${this.teamInternalUserNames} as admin ?`;
      
      this.api.data.forEach((itemCount) => {
        if (itemCount.isAdmin) {
          this.adminCount++;
        }
      });
      this.teamMemberAdminStatus = true;

    },


    updateTeamMemberAdminStatus() {
      if (this.teamMemberEditedItem.isAdmin) {
        if (this.adminCount == 1) {
          this.$store.dispatch("showMessage", "At least one admin is required. Cannot remove the last admin.");
          this.teamMemberAdminStatus = false;
          this.teamMemberEditedItem = {};
          this.adminCount = 0;
          return;
        }
      }
      

      var itemId = this.teamMemberEditedItem.id;
      var data = {
        isAdmin: !this.teamMemberEditedItem.isAdmin,
      };

      this.apiUpdateCellRecord.setMethod(`POST`);
      this.apiUpdateCellRecord.setUrl(
        `${this.$service[this.service.key]}/v1/en/console/model/ssoTeamToInternalUser/${itemId}/edit`
      );
      this.apiUpdateCellRecord.setParams({
        data: JSON.stringify(data),
      });

      this.apiUpdateCellRecord.setCallbackError(()=>{
        this.$store.dispatch("showMessage", "API Error updating.");
      });

      this.apiUpdateCellRecord.setCallbackCompleted(() => {
        this.fetch();
        this.$store.dispatch("showMessage", "Updated successfully.");
      });
      this.apiUpdateCellRecord.fetch();

      this.teamMemberAdminStatus = false;
      this.teamMemberEditedItem = {};
      this.adminCount = 0;
    },

    closeDialog() {
      this.teamMemberAdminStatus = false;
      this.teamMemberEditedItem = {};
      this.adminCount = 0;
    },

    manageDetachStatus(item){
      // when click the button and redirect to the page

      let itemLength = this.api.data.length;
      if(itemLength == 1){
        this.$store.dispatch("showMessage", "Cannot detach the last member.");
        return;
      }

      // cannot detach the last admin from the team if there is only one admin
      this.api.data.forEach((itemCount) => {
        if (itemCount.isAdmin) {
          this.adminCount++;
        }
      });
      if (item.isAdmin && this.adminCount == 1) {
        this.$store.dispatch("showMessage", "Cannot detach the last admin.");
        this.adminCount = 0;
        return;
      }

      this.$router.push({
        name: 'PageServiceModelChildDetach',
        params: {
          serviceKey: this.model.serviceKey,
          parentModelKey: this.parentModelKey,
          parentModelId: this.parentModelId,
          childModelKey: this.intermediateChildModelKey,
          intermediateModelKey: this.modelKey,
          intermediateModelId: item.id,
        }
      });

    }

  },
};
</script>