<template>
<v-card class="yellow my-3">
  <v-card-title>{{title}}</v-card-title>
  <v-card-text>
    <ul v-if="Array.isArray(text)">
      <li v-for="(t,i) of text" :key="i">{{t}}</li>
    </ul>
    <div v-else>{{text}}</div>
  </v-card-text>
</v-card>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:{
      text:{
        type:[Array,String],
        default:'-',
      },
      title:{
        type:String,
        default:'Note',
      },
    },
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>