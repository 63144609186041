<template>
    <div class="text-center">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
             v-bind="attrs"
            v-on="on"
            class="action-button"
          >
            Action
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list v-if="!this.timestampDeleted">
          <v-list-item :disabled="!model.read"  @click="manageRead" class="read-list-items" v-if="this.rolePermission[`${this.model.permissionKeys.read}`].isAllowed">
            <v-list-item-title>Read</v-list-item-title>
          </v-list-item>
          <v-list-item :disabled="!model.edit" @click="manageEdit" class="edit-list-items" v-if="this.rolePermission[`${this.model.permissionKeys.edit}`].isAllowed">
            <v-list-item-title>Edit</v-list-item-title>
          </v-list-item>
          <v-list-item :disabled="!model.delete" @click="manageDelete" class="delete-list-items" v-if="this.rolePermission[`${this.model.permissionKeys.delete}`].isAllowed">
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
        </v-list>

        <v-list v-else>
          <v-list-item @click="manangeRestore" v-if="this.rolePermission[`${this.model.permissionKeys.restore}`].isAllowed">
            <v-list-item-title>Restore</v-list-item-title>
          </v-list-item>
          <v-list-item @click="manageHardDelete" v-if="this.rolePermission[`${this.model.permissionKeys.forceDelete}`].isAllowed">
            <v-list-item-title>Delete Hard</v-list-item-title>
          </v-list-item>
        </v-list>

      </v-menu>
    </div>
  </template>

<script>

import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import { mapState } from "vuex";

export default {
  name: "BreadTabulatorActionButton",
  
  computed: mapState({
    auth: (state) => state.auth.data,
    services: (state) => state.service.data.services,
    rolePermission: (state) => state.rolePermission.data,
  }),

  props: { 
    cellData: {},
    cellValue: {},
    cellTableData: {},
    cellRowData: {},
    serviceKey: {},
    modelKey: {},
    modelId: {},
  },

  data: () => ({
    model: new Model(),
    service: new Service(),
    api: new Api(),
    manageDetachButton: null,
    isTeamAdmin: false,
    adminCount: 0,
    viewId: null,
    timestampDeleted: null,
  }),

  created() {
    this.model.getByKey(this.modelKey);
    this.viewId = this.$route.query.viewId;
    this.timestampDeleted = this.cellRowData.timestampDeleted;

  },

  mounted() {
    //
  },

  methods: {
    manageRead(){
        this.$router.push({
        name: 'PageServiceModelBreadTabulatorRead',
        params: {
            serviceKey: this.serviceKey,
            modelKey: this.modelKey,
            modelId: this.modelId,
        },
        query: {
            viewId: this.viewId,
          },
      });
    },

    manageEdit(){
        this.$router.push({
        name: 'PageServiceModelBreadTabulatorEdit',
        params: {
            serviceKey: this.serviceKey,
            modelKey: this.modelKey,
            modelId: this.modelId,
        },
      });
    },

    manageDelete(){
        this.$router.push({
        name: 'PageServiceModelBreadTabulatorDelete',
        params: {
            serviceKey: this.serviceKey,
            modelKey: this.modelKey,
            modelId: this.modelId,
        },
      });
    },
    manangeRestore(){
        this.$router.push({
            name: "PageServiceModelBreadTabulatorRestore",
            params: {
            serviceKey: this.serviceKey,
            modelKey: this.modelKey,
            modelId: this.modelId,
            },
        });
    },
    manageHardDelete(){
        this.$router.push({
              name: "PageServiceModelBreadTabulatorDeleteHard",
              params: {
                serviceKey: this.serviceKey,
                modelKey: this.modelKey,
                modelId: this.modelId,
              },
            });
    },

  }
};
</script>

<style>
.action-button {
  width: 90% !important;
  height: 100% !important;
}
.read-list-item{
    color: #ffffff !important;
    background-color: #1976d2;
}

.edit-list-item{
    color: #ffffff !important;
    background-color: green;
}

.delete-list-item{
    color: #ffffff !important;
    background-color: rgb(128, 0, 0);
}
</style>