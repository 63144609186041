export default class ApiServices{
    constructor() {
        /* this.APP_SERVICE_URL_HRDC = process.env.VUE_APP_SERVICE_URL_HRDC; */
        this.services = {
            sso: process.env.VUE_APP_SERVICE_URL_SSO,
            lead: process.env.VUE_APP_SERVICE_URL_LEAD,
        };

    }

    getServices(key) {
        return this.services[key];
    }


}