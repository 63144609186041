<template>
  <div>
    <div v-if="parent != null">
      <router-link
        class="text-decoration-none"
        exact-active-class="grey--text"
        :to="{
          name: 'PageServiceModelRead',
          params: {
            serviceKey: parentModel.serviceKey,
            modelKey: parentModel.key,
            modelId: parent.id,
          },
        }"
        >{{ text }}</router-link
      >
    </div>
    <span v-else>-</span>
  </div>
</template>

<script>
//BOC:[model]
import Model from "@/objects/model";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: {
    header: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    parent: null,
    text: "???",
    //BOC:[parentModel]
    parentModel: new Model(),
    //EOC
  }),
  created() {
    //BOC:[parentModel]
    this.parentModel.getByKey(this.header.modelKey);
    //EOC
    this.parent = this.item[this.$_ucfirst(this.header.modelKey)];
    this.text =
      this.parentModel.column && this.parentModel.column.text
        ? this.parent[this.parentModel.column.text]
        : `${this.parentModel.name.singular} ${this.parent.id}`;
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>