import LayoutNested from '@/components/layouts/LayoutNested.vue'

export default {
  path: '/service/:serviceKey',
  component: LayoutNested,
  children: [
    {
      path: 'lms/:modelKey/sources',
      name: 'BrowseLeadSource',
      component: () => import("@/views/service/TabulatorPages/PageServiceModelBrowse.vue"),
    },

    {
      path: 'lms/:modelKey/leads/new',
      name: 'BrowseNewLead',
      component: () => import("@/views/service/TabulatorPages/PageServiceModelBrowse.vue"),
    },

    {
      path: 'lms/:modelKey/leads/assigned',
      name: 'BrowseAssignedLead',
      component: () => import("@/views/service/TabulatorPages/PageServiceModelBrowse.vue"),
    }
  ],
}