<template>
    <div class="text-center">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="action-button"
          >
            Action
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="manageDetach" class="read-list-items" >
            <v-list-item-title>Detach</v-list-item-title>
          </v-list-item>
        </v-list>

      </v-menu>
    </div>
  </template>

<script>

import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import { mapState } from "vuex";

export default {
  name: "BreadTabulatorActionDetachButton",
  
  computed: mapState({
    auth: (state) => state.auth.data,
    services: (state) => state.service.data.services,
    rolePermission: (state) => state.rolePermission.data,
  }),

  props: { 
    cellData: {},
    cellValue: {},
    cellTableData: {},
    cellRowData: {},
    serviceKey: {},
    parentModelKey: {},
    parentModelId: {},
    intermediateModelKey: {},
    intermediateModelId: {},
    childModelKey: {},
  },

  data: () => ({
    model: new Model(),
    service: new Service(),
    api: new Api(),
    manageDetachButton: null,
    isTeamAdmin: false,
    adminCount: 0,
    viewId: null,
    timestampDeleted: null,
  }),

  created() {
    this.model.getByKey(this.modelKey);
    this.viewId = this.$route.query.viewId;
    this.timestampDeleted = this.cellRowData.timestampDeleted;

  },

  mounted() {
    //
  },

  methods: {
    manageDetach(){

      this.$router.push({
          name: "PageServiceModelBreadTabulatorChildDetach",
          params: {
            serviceKey: this.serviceKey,
            parentModelKey: this.parentModelKey,
            parentModelId: this.parentModelId,
            intermediateModelKey: this.intermediateModelKey,
            intermediateModelId: this.intermediateModelId,
            childModelKey: this.childModelKey,
          },
        });
    },

  }
};
</script>

<style>
.action-button {
  width: 90% !important;
  height: 100% !important;
}

.read-list-item{
    color: #ffffff !important;
    background-color: #1976d2;
}

.edit-list-item{
    color: #ffffff !important;
    background-color: green;
}

.delete-list-item{
    color: #ffffff !important;
    background-color: rgb(128, 0, 0);
}
</style>