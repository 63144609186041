import ManageActionColumn from "../../../components/common/BreadTabulatorActionButton.vue";

import Vue from "vue";
import Vuetify from "vuetify";
import store from "../../../store";
import router from "../../../router";

export default {
  serviceKey: "sso",
  key: "ssoTeam",
  column: {
    uid: "uuid", // tell the system which column to be used as unique id
    text: "name", // tell the system which column to display as text
  },
  name: {
    singular: "Team",
    plural: "Teams",
  },
  parents: ["ssoBusiness"],

  apiUrls: {
    apiBrowseUrl: "",
    apiStoreUrl: "/v1/en/console/ssoTeam/add",
    apiUpdateUrl: "",
    apiReadUrl: "",
    apiMultiUpdateUrl: "",
    apiTrashedBrowseUrl: "",
    apiSoftDeleteUrl: "",
    apiHardDeleteUrl: "",
    apiRestoreUrl: "",
  },

  permissionKeys: {
    browse: "sso-ssoTeam-browse",
    read: "sso-ssoTeam-read",
    add: "sso-ssoTeam-add",
    edit: "sso-ssoTeam-edit",
    delete: "sso-ssoTeam-delete",
    restore: "sso-ssoTeam-restore",
    forceDelete: "sso-ssoTeam-forceDelete",
  },

  // Record specific user access control with role-based management
  // for the entire record. The key is the record action and the value is an array of roles
  // that are allowed to perform that action.
  recordAccessControl: {
    browse: {
      all: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      own: {
        roles: ["sales-lead", "salesperson"],
        apiUrl: "/v1/en/console/ssoTeam/listUserTeam",
      },
      assignedTo: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      belongsTo: {
        ssoTeam: {
          roles: ["superadmin"],
          apiUrl: "",
        },
      },
    },
  },

  roleBasedFunctionalities: {
    browse: {
      all: ["superadmin"],
      own: ["salesperson"],
      team: ["sales-lead"],
    },
  },

  browse: {
    table: {
      actions: {
        manageCellEdit: false,
        columnSelectionDisabledCount: 3,
        isActionColumnVisible: true,
        isTrashedTabVisible: true,
      },
      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          headerFilter: false,
          selectableColumn: false,
          visible: true,
          download: false,
        },

        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          visible: true,
          download: false,
        },

        {
          title: "Business",
          field: "ssoBusinessSlug",
          dataType: "modelParent",
          modelKey: "ssoBusiness",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },

        {
          title: "Name",
          field: "name",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },

        {
          title: "Total Members",
          field: "_count.SsoTeamToInternalUser",
          dataType: "integer",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
        },

        {
          title: "Action",
          formatter: (cell) => {
            Vue.use(Vuetify);
            let ComponentClass = Vue.extend(ManageActionColumn);
            let instance = new ComponentClass({
              propsData: {
                cellData: cell.getData(),
                cellValue: cell.getValue(),
                cellTableData: cell.getTable().getData(),
                cellRowData: cell.getRow().getData(),
                serviceKey: "sso",
                modelKey: "ssoTeam",
                modelId: cell.getRow().getData().id,
              },
              vuetify: new Vuetify({}),
              store,
              router,
            });
            instance.$mount();
            return instance.$el;
          },
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: "ssoTeamToInternalUser", //key for tab
        label: "Members",
        dataType: "model",
        relationship: "intermediate",
        modelKey: "ssoTeamToInternalUser",
        intermediateChildModelKey: "internalUser",
        mapping: {
          parent: "uuid",
          child: "ssoTeamUuid",
        },
      },
    ],
    table: {
      headers: [
        {
          dataType: "model",
          relationship: "parent",
          modelKey: "ssoBusiness",
          title: "Business",
          field: "ssoBusinessSlug",
        },
        {
          title: "Name",
          field: "name",
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: "model",
        key: "ssoBusinessSlug",
        name: "Business",
        modelKey: "ssoBusiness",
        optionText: "name",
        optionValue: "slug",
      },
      {
        type: "string",
        name: "Name",
        key: "name",
      },
    ],
    rules: {
      ssoBusinessSlug: ["required"],
      name: ["required"],
    },
  },
  edit: false,
  delete: true,
};
