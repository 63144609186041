<template>
  
    <div class="p-2">
      <v-btn
        color="primary"
        :to="{
          name: 'PageServiceModelBreadTabulatorChildAdd',
          params: {
            parentModelKey: parentModel.key,
            parentModelId: parent.id,
            childModelKey: childModel.key,
          },
        }"
        class="float-right mb-5"
        >+ New {{ childModel.name.singular }} 
        </v-btn>

        <div>
            <span id="record-count" style="float: left; font-size: 12px"></span>
        </div>
        <BreadTabulator
        ref="tabulatorDataTable"
        layout="fitColumns"
        :autoColumns="false"
        :paginationSize="paginationSize"
        :paginationSizeSelector="paginationSizeSelector"
        :apiUrl="apiGetBrowseUrl()"
        :columns="columns"
        :modelKey="childModelKey"
      ></BreadTabulator>
    </div>
</template>

<script>
//BOC:[service]
import Service from "@/objects/service";
//EOC
import Model from "@/objects/model";
//EOC

export default {
  data: () => ({
    service: new Service(),
    childModel: new Model(),
    paginationSizeSelector: [10, 50, 100],
    paginationSize: 10,
    columns: [],
  }),
  methods: {
    apiGetBrowseUrl() {
      let parentRelationKey = this.tabProperties.mapping.parent;
      return `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${this.parentModel.key}/${parentRelationKey}/${this.parent[parentRelationKey]}/child/${this.childModelKey}/browse/all`;
    },
  },
  mounted() {
    console.log(this.parent);
  },

  created() {
    this.childModel.getByKey(this.childModelKey);
    //BOC:[service]
    if (this.childModel.serviceKey)
      this.service.set("key", this.childModel.serviceKey);
    //EOC

    this.columns = this.$_.filter(
        this.childModel.browse.table.headers,
        (headers) => {
          return headers;
        }
      );

      var columnIndex = 0;
      for (var column of this.columns) {
        if (column.dataType == "modelParent") {
          /* var parentColumnName = column.field; */
          var parentModel = new Model();
          parentModel.getByKey(column.modelKey);
          this.columns[columnIndex].formatter = (cell) => {
            var parent = cell.getRow().getData()[this.$_ucfirst(parentModel.key)];
            var parentLabel = parent.id;
            if (parent.name) parentLabel = parent.name;
            if (parentModel.column && parentModel.column.text)
              parentLabel = parent[parentModel.column.text];
  
            return `<span>${parentLabel}</span>`;
          };

          this.columns[columnIndex].accessorDownload = (e, data) => {
            return data[this.$_ucfirst(parentModel.key)].name;
          }
  
        }
        columnIndex++;
      }
      //EOC

      /* this.columns.push({
        title:"Action",
        filterable: false,
        headerSort: false,
        formatter: () => {
          return `<button class="read-btn btn-primary">Read</button>`;
        },

        cellClick:(e, cell) => {
            e.preventDefault();
            this.$router.push({
              name: "PageServiceModelBreadTabulatorRead",
              params: {
                serviceKey: this.childModel.serviceKey,
                modelKey: this.childModelKey,
                modelId: cell.getRow().getData().id,
              },
            });
        },
        download: false,

      }); */

  },

  props: {
    childModelKey: {
      type: String,
      default: null,
    },

    tabProperties: {
      type: Object,
      default: () => {
        return {};
      },
    },

    parent: {
      type: Object,
      default: () => {
        return {};
      },
    },

    parentModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    //
  },
};
</script>

<style scoped>
</style>