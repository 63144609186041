<template>
    <div>
          <span v-if="cellValue" class="success--text">⬤ Admin
            <br>
              <v-btn :disabled="!isTeamAdmin" x-small @click="changeTeamMemberAdminStatus(cellRowData)">Remove from admin</v-btn>
          </span>
          <span v-else class="error--text">⬤ Member
            <br><v-btn :disabled="!isTeamAdmin" x-small @click="changeTeamMemberAdminStatus(cellRowData)">Assign as Admin</v-btn>
          </span>
          <v-dialog v-model="teamMemberAdminStatus" persistent :retain-focus="false" max-width="350px">
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div style="margin: 20px 0px;">
                        <h5 style="font-size: 16px;"> {{ teamMemberAdminConformationText }}</h5>
                    </div>
                  </v-col>
                  <!-- Add other fields as necessary -->
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDialog()">No</v-btn>
              <v-btn color="blue darken-1" text @click="updateTeamMemberAdminStatus()">Yes</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        </div>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC

import { mapState } from "vuex";

export default {

    props: {
        cellRowData: {},
        cellValue: {},
        cellTableData: {},
        ssoTeamId: {},
    },

    computed: mapState({
        auth: (state) => state.auth.data,
    }),

    watch: {
        //
    },

    data: () => ({
        teamMemberAdminConformationText: '',
        teamMemberAdminStatus: false,
        adminCount: 0,
        model: new Model(),
        service: new Service(),
        api: new Api(),
        apiUpdate: new Api(),
        tableAllData: [],
        isTeamAdmin: false,

    }),
    methods: {
        changeTeamMemberAdminStatus(cellRowData) {

            this.teamInternalUserNames = cellRowData.InternalUser.name;
            this.teamMemberAdminConformationText = cellRowData.isAdmin ? `Remove ${this.teamInternalUserNames} from admin ?` : `Assign ${this.teamInternalUserNames} as admin ?`;
            this.teamMemberAdminStatus = true;

            this.cellTableData.forEach((item) => {
                if (item.isAdmin) {
                    this.adminCount++;
                }
            });

        },

        closeDialog() {
            this.teamMemberAdminStatus = false;
            this.teamMemberEditedItem = {};
            this.adminCount = 0;
        },

        updateTeamMemberAdminStatus() {

            if (this.cellRowData.isAdmin) {
                if (this.adminCount == 1) {
               this.$store.dispatch("showMessage", {
                    message: "At least one admin is required. Cannot remove the last admin",
                    messageType: "error",
                    timeout: 5000
                });
                this.teamMemberAdminStatus = false;
                this.teamMemberEditedItem = {};
                this.adminCount = 0;
                return;
                }
            }

            var itemId = this.cellRowData.id;
            var data = {
                isAdmin: !this.cellRowData.isAdmin,
            };

            this.apiUpdate.setMethod(`POST`);
            this.apiUpdate.setUrl(
                `${this.$service[this.service.key]}/v1/en/console/model/ssoTeamToInternalUser/${itemId}/edit`
            );
            this.apiUpdate.setParams({
                data: JSON.stringify(data),
            });

            this.apiUpdate.setCallbackError(()=>{
                this.$store.dispatch("showMessage", {
                    message: "API Error updating.",
                    messageType: "error",
                    timeout: 5000
                });
            });

            this.apiUpdate.setCallbackCompleted(() => {
                this.$store.dispatch("showMessage", {
                    message: "Updated successfully.",
                    messageType: "success",
                    timeout: 5000
                });
                window.location.reload();
            });

            this.apiUpdate.fetch();

            this.teamMemberAdminStatus = false;
            this.adminCount = 0;
        
        },

    },
    mounted() {
        // push the data to the array
    },

    created() {
    this.model.getByKey('ssoTeamToInternalUser');
    if(this.model.serviceKey) this.service.set("key",this.model.serviceKey);
    //EOC
        let modelId = this.ssoTeamId;
        let internalUserUuid = this.auth.uuid;
    //const ssoTeamId =this.cellRowData.SsoTeam.id;
    // get value from API
    this.api.setMethod('GET');
        this.api.setUrl(`${this.$service['sso']}/v1/en/console/ssoTeamToInternalUser/${modelId}/${internalUserUuid}`);
        this.api.setCallbackCompleted((resp) => {
          if(resp.data[0].isAdmin){
            if(internalUserUuid === 'internal_admin'){
              this.isTeamAdmin = true;
            }else{
            this.isTeamAdmin = true;
            }
          }else{
            if(internalUserUuid === 'internal_admin'){
              this.isTeamAdmin = true;
            }else{
            this.isTeamAdmin = false;
            }
          }
        });
        this.api.fetch();

    },

    
};

</script>

<style>
</style>