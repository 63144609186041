export default {
    state: {
      data: {
        parentSelectedModelValue: false,
      },
    },
    getters: {
      //
    },
    actions: {
      updatePrentModelSelectedValue(context, data) {
        context.commit("updatePrentModelSelectedValue", data);
      },
    },
    mutations: {
        updatePrentModelSelectedValue(state, data) {
        return (state.data = data);
      },
    },
  };
  