import ManageActionColumn from "../../../components/common/BreadTabulatorActionButton.vue";

import Vue from "vue";
import Vuetify from "vuetify";
import store from "../../../store";
import router from "../../../router";

export default {
  serviceKey: "sso",
  key: "ssoWebhook",
  column: {
    uid: "id", // tell the system which column to be used as unique id
    text: "id", // tell the system which column to display as text
  },
  name: {
    singular: "Webhook",
    plural: "Webhooks",
  },
  parents: [
    //
  ],

  apiUrls: {
    apiBrowseUrl: "",
    apiStoreUrl: "",
    apiUpdateUrl: "",
    apiReadUrl: "",
    apiMultiUpdateUrl: "",
    apiTrashedBrowseUrl: "",
    apiSoftDeleteUrl: "",
    apiHardDeleteUrl: "",
    apiRestoreUrl: "",
  },

  permissionKeys: {
    browse: "sso-ssoWebhook-browse",
    read: "sso-ssoWebhook-read",
    add: "sso-ssoWebhook-add",
    edit: "sso-ssoWebhook-edit",
    delete: "sso-ssoWebhook-delete",
    restore: "sso-ssoWebhook-restore",
    forceDelete: "sso-ssoWebhook-forceDelete",
  },

  roleBasedFunctionalities: {
    browse: {
      all: ["superadmin"],
      own: ["salesperson"],
      team: ["sales-lead"],
    },
  },

  recordAccessControl: {
    browse: {
      all: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      own: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      assignedTo: {
        roles: ["salesperson", "sales-lead"],
        apiUrl: "",
      },
      belongsTo: {
        ssoBusiness: {
          roles: ["superadmin"],
          apiUrl: "",
        },
        ssoTeam: {
          roles: ["superadmin"],
          apiUrl: "",
        },
      },
    },
  },

  browse: {
    alert: {
      text: [
        "2023-11-27: This module currently is primary used to log all api called by Microsoft system. It can be modified for broader usage in future.",
        "2023-11-27: Ong Boon Sien in Johor is the person in charge for webhooks related to Microsoft account.",
        "2023-11-27: Microsoft system will call same api repeatedly daily for 7 days to prevent HR back date some entries.",
        "2023-11-27: Microsoft system will call both employee/add and employee/restore api whenever have new employee joined the company.",
        "2023-11-27: /api/v1/en/webhook/employee related apis will BREAD SsoMicrosoftAccount.",
      ],
    },

    table: {
      actions: {
        manageCellEdit: false,
        columnSelectionDisabledCount: 3,
        isActionColumnVisible: true,
        isTrashedTabVisible: true,
      },
      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          selectableColumn: false,
          headerFilter: false,
          download: false,
          editable: false,
        },

        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          hozAlign: "left",
          download: false,
          editable: false,
        },

        {
          title: "Created",
          field: "timestampCreated",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          editable: false,
        },
        {
          title: "Request Url",
          field: "requestUrl",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          editable: false,
        },
        {
          title: "Email",
          field: "email",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          editable: false,
        },
        {
          title: "Repeat",
          field: "countRepeated",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          editable: false,
        },
        {
          title: "Status",
          field: "status",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          editable: false,
        },
        {
          title: "Error Message",
          field: "errorMessage",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          editable: false,
        },

        {
          title: "Action",
          formatter: (cell) => {
            Vue.use(Vuetify);
            let ComponentClass = Vue.extend(ManageActionColumn);
            let instance = new ComponentClass({
              propsData: {
                cellData: cell.getData(),
                cellValue: cell.getValue(),
                cellTableData: cell.getTable().getData(),
                cellRowData: cell.getRow().getData(),
                serviceKey: "sso",
                modelKey: "ssoWebhook",
                modelId: cell.getRow().getData().id,
              },
              vuetify: new Vuetify({}),
              store,
              router,
            });
            instance.$mount();
            return instance.$el;
          },
        },
      ],
    },
  },
  read: {
    tabs: [
      //
    ],
    table: {
      headers: [
        {
          title: "Created",
          field: "timestampCreated",
        },
        {
          title: "Request Url",
          field: "requestUrl",
        },
        {
          title: "Request Params",
          field: "requestParams",
        },
        {
          title: "Request Query",
          field: "requestQuery",
        },
        {
          title: "Request Body",
          field: "requestBody",
        },
        {
          title: "Response Body",
          field: "responseBody",
        },
        {
          title: "Status",
          field: "status",
        },
        {
          title: "Repeat",
          field: "countRepeat",
        },
        {
          title: "Error Message",
          field: "errorMessage",
        },
      ],
    },
  },
  add: false,
  edit: false,
  delete: false,
};
