<template>
  <div class="border-b py-1 px-3">
    <div class="pt-1">
      <b>Filters</b>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
        <span>You may apply multiple filters to select the data that you will like to view.</span>
      </v-tooltip>
    </div>
    <div class="py-1 d-flex align-center">
      <div class="d-flex" style="width:calc(100% - 100px);">
        <v-select
          v-model="field"
          :items="fields"
          label="Field"
          outlined
          dense
          hide-details
          class="mr-1"
          style="width:33.3%"
        ></v-select>
        <v-select
          v-model="operation"
          :items="[
            {
              value:'CONTAIN',
            },
            {
              value:'NOT_CONTAIN',
            },
            {
              value:'EQUAL',
            },
            {
              value:'NOT_EQUAL',
            },
          ]"
          item-text="value"
          label="Operation"
          outlined
          dense
          hide-details
          class="mr-1"
          style="width:33.3%"
        ></v-select>
        <v-text-field
          v-model="value"
          label="Value"
          outlined
          dense
          hide-details
          class="mr-1"
          style="width:33.3%"
          @keyup.native.enter="addFilter"
        ></v-text-field>
      </div>
      <v-btn
        :disabled="!value"
        @click="addFilter" 
        dense depressed style="border:solid thin rgba(0,0,0,0.12) !important; width:100px;"
      >
        Add
      </v-btn>
    </div>
    <div class="py-1 d-flex align-center">
      <v-select
        v-model="selectedFilters"
        prepend-icon="mdi-filter"
        :items="filters"
        label="Filter"
        no-data-text="No filters."
        outlined
        dense
        hide-details
        multiple
        class="mx-1"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index < 3" color="yellow" small>
            <span>{{ item.text }}</span>
          </v-chip>
          <span
            v-if="index === 3"
            class="grey--text text-caption"
          >
            (+{{ selectedFilters.length - 3 }} others)
          </span>
        </template>
      </v-select>
      <v-btn 
        @click="clearFilter"
        :disabled="filters.length < 1" 
        dense depressed style="border:solid thin rgba(0,0,0,0.12) !important; min-width:100px;"
      >
        Clear
      </v-btn>
    </div>
  </div>
</template>

<script>
/* 
The filter will generate condition in json format based on user selected field, operation, and value 
*/
import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:{
      model:{
        type: Object,
        required: true,
      },
      setConditions:{
        type: Function,
      },
    },
    data: () => ({
      field:null,
      fields:[],
      filters:[],
      operation:null,
      selectedFilters:[],
      value:null,
    }),
    watch: {
      selectedFilters() {
        this.updateConditions();
      },
    },
    created() {
      this.fields = this.$_.filter(this.model.browse.table.headers,(o)=>{
        if(o.filterable != false) {
          return o
        }
      })
      this.field = this.fields[0] ? this.fields[0].value : null;
      this.operation = 'CONTAIN';
      this.resetCondition();
    },
    mounted() {
      //
    },
    methods: {
      addFilter() {
        var field = this.$_.find(this.fields,{value:this.field});
        var condition = {
          field:field.value,
          operation:this.operation,
          value:this.value,
        }
        var filter = {
          text:`${field.text} ${this.operation} ${this.value}`,
          value:JSON.stringify(condition),
        };
        //BOC:check duplicated filter
        if(this.$_.findIndex(this.selectedFilters,{value:filter.value}) > -1) {
          alert("Same filter already existed.")
          return
        }
        //EOC
        this.filters.push(filter);
        this.selectedFilters.push(filter.value);
        this.resetCondition();
      },
      clearFilter() {
        this.filters = [];
        this.selectedFilters = [];
      },
      setFiltersViaConditions(conditions) {
        if(!conditions) return;
        var filters = [];
        for(var condition of conditions) {
          var filter = {
            text:`${condition.field} ${condition.operation} ${condition.value}`,
            value:JSON.stringify(condition),
          }
          filters.push(filter);
        }
        this.filters = filters;
        this.selectedFilters = this.$_.map(filters,'value');
      },
      updateConditions() {
        var conditions = [];
        for(var condition of this.selectedFilters) {
          conditions.push(JSON.parse(condition))
        }
        this.setConditions(conditions);
      },
      resetCondition() {
        this.value = null;
      },
    }
  }
</script>