export default {
  key: "email",
  column: {
    uid: "id", // tell the system which column to be used as unique id
    text: "email", // tell the system which column to display as text
  },
  name: {
    singular: "Email",
    plural: "Emails",
  },
  parents: [
    //
  ],
  browse: {
    table: {
      actions: {
        single: {
          read: true,
          edit: false,
          delete: false,
          restore: false,
          deleteHard: false,
        },
        multiple: {
          delete: false,
        },
      },
      options: {
        sortBy: ["email"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "No.",
          value: "index",
          sortable: false,
          searchable: false,
          filterable: false,
        },
        {
          text: "Contact",
          value: "ParentContact",
          searchable: false,
          sortable: false,
        },
        {
          text: "Email",
          value: "email",
        },
      ],
    },
  },
  read: {
    table: {
      headers: [
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Contact",
          value: "ParentContact",
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: 'model',
        key: 'contactUuid',
        name: 'Contact',
        modelKey: 'contact',
        optionText: 'name',
        optionValue: 'uuid',
      },
      {
        type: "string",
        name: "Email",
        key: "email",
      },
    ],
    rules: {
      email: ["required", "email"],
    },
  },
  edit: false,
  delete: false,
};
