export default {
  state: {
    data: {
      isDebugging: false,
      isDeveloping: false,
      isDisguising: false,
    },
  },
  getters: {
    //
  },
  actions: {
    startDebug(context) {
      context.commit("updateDebugMode", {
        isDebugging: true,
      })
    },
    stopDebug(context) {
      context.commit("updateDebugMode", {
        isDebugging: false,
      })
    },
    startDevelop(context) {
      context.commit("updateDevelopMode", {
        isDeveloping: true,
      })
    },
    stopDevelop(context) {
      context.commit("updateDevelopMode", {
        isDeveloping: false,
      })
    },
    startDisguise(context) {
      context.commit("updateDisguiseMode", {
        isDisguising: true,
      })
    },
    stopDisguise(context) {
      context.commit("updateDisguiseMode", {
        isDisguising: false,
      })
    },
  },
  mutations: {
    updateDebugMode(state, data) {
      return state.data = {
        ...state.data,
        ...data,
      }
    },
    updateDevelopMode(state, data) {
      return state.data = {
        ...state.data,
        ...data,
      }
    },
    updateDisguiseMode(state, data) {
      return state.data = {
        ...state.data,
        ...data,
      }
    },
  }
}