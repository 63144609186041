//BOC:[crm]
import crmService from "@/services/crm/models/crmService";
import crmServicePermission from "@/services/crm/models/crmServicePermission";
import crmServicePermissionToInternalRole from "@/services/crm/models/crmServicePermissionToInternalRole";
//EOC
//BOC:[lead]
import leadSource from "@/services/lead/models/leadSource";
import leadNew from "@/services/lead/models/leadNew";
import leadOther from "@/services/lead/models/leadOther";
//EOC
//BOC:[log]
import logRequest from "@/services/log/models/logRequest";
//EOC
//BOC:[profiling]
import contact from "@/services/profiling/models/contact";
import email from "@/services/profiling/models/email";
//EOC
//BOC:[sso]
import internalCompany from "@/services/sso/models/internalCompany";
import internalDepartment from "@/services/sso/models/internalDepartment";
import internalRole from "@/services/sso/models/internalRole";
import internalUser from "@/services/sso/models/internalUser";
import internalRoleToInternalUser from "@/services/sso/models/internalRoleToInternalUser";
import ssoBusiness from "@/services/sso/models/ssoBusiness";
import ssoMicrosoftAccount from "@/services/sso/models/ssoMicrosoftAccount";
import ssoTeam from "@/services/sso/models/ssoTeam";
import ssoTeamToInternalUser from "@/services/sso/models/ssoTeamToInternalUser";
import ssoWebhook from "@/services/sso/models/ssoWebhook";
//EOC
//BOC:[hrdc]
import hrdcApplication from "@/services/hrdc/models/application";
import hrdcEmailClient from "@/services/hrdc/models/emailClient";
import hrdcRevertForm from "@/services/hrdc/models/revertForm";
import hrdcManualInvoice from "@/services/hrdc/models/manualInvoice";
import hrdcClaimSubmit from "@/services/hrdc/models/claimSubmit";
import hrdcClaimApproved from "@/services/hrdc/models/claimApproved";
import hrdcPaymentReceived from "@/services/hrdc/models/paymentReceived";
import hrdcCreditNoteGenerate from "@/services/hrdc/models/creditNoteGenerate";
import hrdcCreditNoteRequested from "@/services/hrdc/models/creditNoteRequested";
import hrdcCreditNoteDocumented from "@/services/hrdc/models/creditNoteDocumented";
import hrdcRefundEmailSubmission from "@/services/hrdc/models/refundEmailSubmission";
import hrdcRefundEmailApproval1 from "@/services/hrdc/models/refundEmailApproval1";
import hrdcRefundEmailApproval2 from "@/services/hrdc/models/refundEmailApproval2";
import hrdcRefundEmailArToAp from "@/services/hrdc/models/refundEmailArToAp";
import hrdcRefundEmailApToCimb from "@/services/hrdc/models/refundApToCimb";
import hrdcRefundFailed from "@/services/hrdc/models/refundFailed";
import hrdcFinanceRefundToClient from "@/services/hrdc/models/financeRefundToClient";
import hrdcEmailPaymentAdviceToClient from "@/services/hrdc/models/emailPaymentAdviceToClient";
import hrdcEnrollmentDetail from "@/services/hrdc/models/enrollmentDetail";
import hrdcGrantNoSubmit from "@/services/hrdc/models/grantNoSubmit";
import hrdcApplicationSubmissionDate from "@/services/hrdc/models/submissionDate";
import HrdcReportAnalysis from "@/services/hrdc/models/reportAnalysis";
import hrdcGeneralInfo from "@/services/hrdc/models/generalInfo";
import HrdcSettingsPrice from "@/services/hrdc/models/price";
import HrdcSettingsErrorLog from "@/services/hrdc/models/errorLog";
//EOC

//EOC:[development]
import demoCustomer from "@/services/developer/models/customer";
import demoProduct from "@/services/developer/models/product";
import demoCategory from "@/services/developer/models/category";
import demoOrder from "@/services/developer/models/order";
import demoOrderToDemoProduct from "@/services/developer/models/demoOrderToDemoProduct";
//EOC

export default {
  //BOC:[crm]
  crmService,
  crmServicePermission,
  crmServicePermissionToInternalRole,
  //EOC
  //BOC:[lead]
  leadSource,
  leadNew,
  leadOther,
  //EOC
  //BOC:[log]
  logRequest,
  //EOC
  //BOC:[profiling]
  contact,
  email,
  //EOC
  //BOC:[sso]
  internalCompany,
  internalDepartment,
  internalRole,
  internalUser,
  internalRoleToInternalUser,
  ssoBusiness,
  ssoMicrosoftAccount,
  ssoTeam,
  ssoTeamToInternalUser,
  ssoWebhook,
  //EOC
  //BOC:[hrdc]
  hrdcApplication,
  hrdcEmailClient,
  hrdcRevertForm,
  hrdcManualInvoice,
  hrdcClaimSubmit,
  hrdcClaimApproved,
  hrdcPaymentReceived,
  hrdcCreditNoteGenerate,
  hrdcCreditNoteRequested,
  hrdcCreditNoteDocumented,
  hrdcRefundEmailSubmission,
  hrdcRefundEmailApproval1,
  hrdcRefundEmailApproval2,
  hrdcRefundEmailArToAp,
  hrdcRefundEmailApToCimb,
  hrdcRefundFailed,
  hrdcFinanceRefundToClient,
  hrdcEmailPaymentAdviceToClient,
  hrdcEnrollmentDetail,
  hrdcGrantNoSubmit,
  hrdcApplicationSubmissionDate,
  HrdcReportAnalysis,
  hrdcGeneralInfo,
  HrdcSettingsPrice,
  HrdcSettingsErrorLog,
  //EOC
  //EOC:[development]
  demoCustomer,
  demoProduct,
  demoCategory,
  demoOrder,
  demoOrderToDemoProduct,
  //EOC
};
