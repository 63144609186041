<template>
  <v-dialog
    v-model="loadingDialog"
    persistent
    width="300"
    content-class="elevation-0"
  >
    <v-card class="" flat>
      <v-progress-linear indeterminate color="#063058"></v-progress-linear>
      <v-img src="../../../../public/gif/hrdc/hrdc_loader.gif"></v-img>
      <v-card-text class="text-center">{{
        loadingText || "Loading..."
      }}</v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["loadingDialog", "loadingText"],
};
</script>
