<template>
  <div>
    <router-view/>
    <TheSnackbar/>
    <TheModalConfirmation ref="modalConfirmation"/>
  </div>
</template>

<script>
import TheSnackbar from "@/components/common/TheSnackbar";
import TheModalConfirmation from "@/components/common/TheModalConfirmation";
export default {
  components: {
    TheSnackbar,
    TheModalConfirmation,
  },
  name: 'App',
  data: () => ({
    //
  }),

  mounted() {
    this.$root.$modalConfirmation = this.$refs.modalConfirmation.open;
  },
};
</script>