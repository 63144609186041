import ManageActionColumn from "../../../components/common/BreadTabulatorActionButton.vue";

import Vue from "vue";
import Vuetify from "vuetify";
import store from "../../../store";
import router from "../../../router";

export default {
  serviceKey: "sso",
  key: "ssoBusiness",
  column: {
    uid: "slug", // tell the system which column to be used as unique id
    text: "name", // tell the system which column to display as text
    key: "ssoBusinessSlug", // tell the system which column to display as key
  },
  name: {
    singular: "Business",
    plural: "Businesses",
  },
  parents: [
    //
  ],

  apiUrls: {
    apiBrowseUrl: "",
    apiStoreUrl: "",
    apiUpdateUrl: "",
    apiReadUrl: "",
    apiMultiUpdateUrl: "",
    apiTrashedBrowseUrl: "",
    apiSoftDeleteUrl: "",
    apiHardDeleteUrl: "",
    apiRestoreUrl: "",
  },

  permissionKeys: {
    browse: "sso-ssoBusiness-browse",
    read: "sso-ssoBusiness-read",
    add: "sso-ssoBusiness-add",
    edit: "sso-ssoBusiness-edit",
    delete: "sso-ssoBusiness-delete",
    restore: "sso-ssoBusiness-restore",
    forceDelete: "sso-ssoBusiness-forceDelete",
  },

  recordAccessControl: {
    browse: {
      all: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      own: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      assignedTo: {
        roles: ["salesperson", "sales-lead"],
        apiUrl: "",
      },
      belongsTo: {
        ssoBusiness: {
          roles: ["superadmin"],
          apiUrl: "",
        },
        ssoTeam: {
          roles: ["superadmin"],
          apiUrl: "",
        },
      },
    },
  },

  roleBasedFunctionalities: {
    browse: {
      all: ["superadmin"],
      own: ["salesperson"],
      team: ["sales-lead"],
    },
  },

  browse: {
    alert: {
      text: "Data is seeded. Contact developer if wanna make any changes.",
    },

    table: {
      actions: {
        manageCellEdit: false,
        columnSelectionDisabledCount: 3,
        isActionColumnVisible: true,
        isTrashedTabVisible: true,
      },
      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          selectableColumn: false,
          headerFilter: false,
          download: false,
          editable: false,
        },

        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          hozAlign: "left",
          download: false,
          editable: false,
        },

        {
          title: "Name",
          field: "name",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
          editable: false,
        },

        /* {
          dataType: "model",
          relationship: "child",
          modelKey: "ssoTeam",
          text: "Total Teams",
        }, */

        {
          title: "Action",
          formatter: (cell) => {
            Vue.use(Vuetify);
            let ComponentClass = Vue.extend(ManageActionColumn);
            let instance = new ComponentClass({
              propsData: {
                cellData: cell.getData(),
                cellValue: cell.getValue(),
                cellTableData: cell.getTable().getData(),
                cellRowData: cell.getRow().getData(),
                serviceKey: "sso",
                modelKey: "ssoBusiness",
                modelId: cell.getRow().getData().id,
              },
              vuetify: new Vuetify({}),
              store,
              router,
            });
            instance.$mount();
            return instance.$el;
          },
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: "ssoTeam", //key for tab
        label: "Teams",
        dataType: "model",
        relationship: "child",
        modelKey: "ssoTeam",
        mapping: {
          parent: "slug",
          child: "ssoBusinessSlug",
        },
      },
    ],
    table: {
      headers: [
        {
          title: "Name",
          field: "name",
        },
      ],
    },
  },
  add: false,
  edit: false,
  delete: false,
};
