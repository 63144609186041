const DISABLED_COLOR = "#C3C4C3";

import moment from "moment";

export default {
  key: "submission_date",
  serviceKey: "hrdc",
  name: {
    singular: "Submission Date",
    plural: "Submission Date",
  },
  parents: [
    //
  ],
  add: {
    name: "Submission Date",
  },
  browse: {
    table: {
      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          headerFilter: false,
          selectableColumn: false,
          visible: false,
        },
        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          visible: false,
        },
        {
          title: "Company",
          field: "company",
          dataType: "onlyLikeEqualNotEqual",
          widthGrow: 3,
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          frozen: true,
          formatter: (cell) => {
            return `<span style="color:blue;">${cell
              .getValue()
              .toUpperCase()}</span>`;
          },
        },
        {
          title: "Claim ID",
          field: "claimId",
          dataType: "onlyLikeEqualNotEqual",
          widthGrow: 2,
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "center",
          headerHozAlign: "center",
          headerWordWrap: true,
          frozen: true,
          formatter: (cell) => {
            const data = cell.getData();
            if (data.claimId) {
              return data.claimId;
            }
            return "-";
          },
        },
        {
          title: "Price",
          field: "totalPrice",
          dataType: "onlyLikeEqualNotEqual",
          widthGrow: 1,
          filterable: false,
          searchable: false,
          headerFilter: false,
          selectableColumn: true,
          headerSort: false,
          hozAlign: "center",
          headerWordWrap: true,
          frozen: true,
          formatter: (cell) => {
            cell.getElement().style.borderRight = `1px solid ${DISABLED_COLOR}`;

            const data = cell.getData();
            if (data.totalPrice) {
              return `RM ${data.totalPrice}`;
            }
            return "-";
          },
        },
        {
          title: "Claim Approved From HRDC",
          field: "claimApprovedFromHrdc",
          dataType: "date",
          editor: "date",
          formatter: "datetime",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          editable: (cell) => {
            cell.getElement().style.backgroundColor = DISABLED_COLOR;
            return false;
          },
        },
        {
          title: "Payment Received from HRDC",
          field: "paymentReceivedFromHrdc",
          dataType: "date",
          editor: "date",
          formatter: "datetime",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
        },
        {
          title: "CN Requested",
          field: "creditNoteRequested",
          dataType: "date",
          editor: "date",
          formatter: "datetime",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          editable: (cell) => {
            cell.getElement().style.backgroundColor = DISABLED_COLOR;
            return false;
          },
        },
        {
          title: "CN Documented",
          field: "creditNoteDocumented",
          dataType: "date",
          editor: "date",
          formatter: "datetime",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          editable: (cell) => {
            cell.getElement().style.backgroundColor = DISABLED_COLOR;
            return false;
          },
        },
        {
          title: "Refund AR to AP",
          field: "refundEmailArToAp",
          dataType: "date",
          editor: "date",
          formatter: "datetime",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          editable: (cell) => {
            const data = cell.getData();
            if (data.paymentChannel == 2) {
              cell.getElement().style.backgroundColor = DISABLED_COLOR;
              return false;
            }

            return true;
          },
        },
        {
          title: "Refund Email Updated",
          field: "refundEmailUpdated",
          dataType: "onlyGteLte",
          editor: "date",
          filterable: true,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          editable: false,
          formatter: function (cell) {
            var dateValue = cell.getValue();
            if (dateValue) {
              var formattedDate = new Date(dateValue).toLocaleDateString(
                "en-CA"
              );
              cell.getElement().style.backgroundColor = "#ffc4cc";
              return formattedDate;
            }
          },
        },
        {
          title: "Finance Refund to Client",
          field: "financeRefundToClient",
          dataType: "onlyEqualNotEqual",
          // editor: "date",
          // formatter: "datetime",
          filterable: true,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          formatter: (cell) => {
            const value = cell.getValue();
            if (value) {
              return moment(value).format("YYYY-MM-DD");
            }
          },
          editable: (cell) => {
            const data = cell.getData();
            if (data.paymentChannel == 2) {
              cell.getElement().style.backgroundColor = DISABLED_COLOR;
              return false;
            }

            return true;
          },
        },
        {
          title: "Email Payment Advice to Client",
          field: "emailPaymentAdviceToClient",
          dataType: "date",
          editor: "date",
          formatter: "datetime",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          editable: (cell) => {
            cell.getElement().style.backgroundColor = DISABLED_COLOR;
            return false;
          },
        },
      ],
    },
  },
};
