<template>
  <div >
    <v-row no-gutters>
      <v-col md="4">
        <div class="tb-select">
          <v-select
            style="border-radius: 4px 0px 0px 4px; border-right: 0px"
            v-model="column_name"
            :items="columns"
            item-text="title"
            item-value="field"
            label="Table Column"
            solo
            dense
            hide-details
            @change="changeSearchType"
          ></v-select>
        </div>
        
      </v-col>

      <v-col md="4">
        <div class="tb-text-filed tb-with-textfield-clear">
          <v-text-field
            v-model="value"
            label="Value"
            :type="this.valueInputType"
            solo
            dense
            clearable
            hide-details
            @click:clear="clearSearch"
            @keyup.native.enter="searchRecords"
          ></v-text-field>
        </div>
      </v-col>

      <v-col md="2">
        
          <v-btn
            :disabled="!value"
            @click="searchRecords"
            color="primary"
            class="tb-btn"
          >
            Search
          </v-btn>

          
        
      </v-col>
      <v-col md="2">
        <!-- <v-btn
            @click="clearSearch"
            class="tb-btn-clear"
            text
            style="margin-left: 33px; border: 1px solid #c8c8c8; color: #767374;"
            >
            Clear
          </v-btn> -->
      </v-col>
    </v-row>

    <!-- <div class="pt-1">
      <b>Search</b>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
        <span>you can seach record from the selected rows</span>
      </v-tooltip>
    </div> -->
  </div>
</template>

<script>
/* 
The filter will generate condition in json format based on user selected field, type, and value 
*/
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: {
    model: {
      type: Object,
      required: true,
    },
    setConditions: {
      type: Function,
    },
  },
  data: () => ({
    column_name: null,
    columns: [],
    filters: [],
    type: null,
    selectedFilters: [],
    value: null,
    valueInputType: "text",
    selectedViewFilteredData: [],
  }),
  watch: {
    selectedFilters() {
      this.updateConditions();
    },
  },
  created() {
    this.columns = this.$_.filter(this.model.browse.table.headers, (o) => {
      if (o.searchable != false) {
        return o;
      }
    });
    this.column_name = this.columns[0] ? this.columns[0].field : null;
    this.type = "like";
    this.clearSearch();
  },
  mounted() {
    //
  },
  methods: {
    searchRecords() {

      this.selectedFilters = [];

      var selectedColumn = this.$_.find(this.columns, {
        field: this.column_name,
      });

      switch (selectedColumn.dataType) {
        case "float":
          this.value = parseFloat(this.value);
          break;
        case "integer":
          this.value = parseInt(this.value, 10);
          break;
        case "boolean":
          this.value = this.value == "true" ? true : false;
          break;

        case "date":
          this.value = this.$moment(new Date(this.value)).format("YYYY-MM-DD");
          /* this.value = new Date(this.value); */
          break;
      }

      let condition = {
        field: selectedColumn.field,
        type: this.type,
        value: selectedColumn.dataType == "date" ? new Date(this.value) : this.value,
      };

      this.selectedFilters.push(JSON.stringify(condition));
    },

    clearSearch() {
      this.selectedFilters = [];
      this.value = null;
    },

    updateConditions() {
      var conditions = [];
      for (var condition of this.selectedFilters) {
        conditions.push(JSON.parse(condition));
      }

      if (this.selectedViewFilteredData.length > 0) {
        conditions = conditions.concat(this.selectedViewFilteredData);
      }
      
      this.setConditions(conditions);
    },

    resetCondition() {
      this.value = null;
    },

    changeSearchType() {
      var selectedColumn = this.$_.find(this.columns, {
        field: this.column_name,
      });

      switch (selectedColumn.dataType) {
        case "float":
          this.type = "=";
          this.valueInputType = "number";
          break;
        case "integer":
          this.type = "=";
          this.valueInputType = "number";
          break;
        case "boolean":
          this.type = "=";
          this.valueInputType = "text";
          break;
        case "date":
          this.type = "=";
          this.valueInputType = "date";
          break;

        default:
          this.type = "like";
          this.valueInputType = "text";
          break;
      }
    },

    getFilterCondition(filterCondition){
      this.selectedViewFilteredData = filterCondition;
    }

  },
};
</script>