/* import axios from 'axios';  */
/* import {leadSoureceType} from '../objects/leadSourceType'; */

import ManageActionColumn from "../../../components/common/BreadTabulatorActionButton.vue";

import Vue from "vue";
import Vuetify from "vuetify";
import store from "../../../store";
import router from "../../../router";

export default {
  serviceKey: "lead",
  key: "leadSource",
  column: {
    uid: "uuid", // tell the system which column to be used as unique id
    text: "name", // tell the system which column to display as text
  },
  name: {
    singular: "Lead Source",
    plural: "Lead Sources",
  },
  parents: [
    //
  ],

  apiUrls: {
    apiBrowseUrl: "",
    apiStoreUrl: "",
    apiUpdateUrl: "",
    apiReadUrl: "",
    apiMultiUpdateUrl: "",
    apiTrashedBrowseUrl: "",
    apiSoftDeleteUrl: "",
    apiHardDeleteUrl: "",
    apiRestoreUrl: "",
  },

  permissionKeys: {
    browse: "lead-leadSource-browse",
    read: "lead-leadSource-read",
    add: "lead-leadSource-add",
    edit: "lead-leadSource-edit",
    delete: "lead-leadSource-delete",
    restore: "lead-leadSource-restore",
  },

  roleBasedFunctionalities: {
    browse: {
      all: ["superadmin"],
      own: [],
      team: ["sales-lead"],
    },
    read: {
      all: ["superadmin"],
      own: [], // salesperson can read lead which is assigned to him/her
      team: ["sales-lead"], // sales-lead can read lead for his team
    },
    add: {
      all: ["superadmin"],
      own: [], // salesperson can add lead for himself
      team: ["sales-lead"], // sales-lead can add lead for his team
    },
    edit: {
      all: ["superadmin"],
      own: [], // salesperson can edit lead which is assigned to him/her
      team: ["sales-lead"], // sales-lead can edit lead for his team
    },
    delete: {
      all: ["superadmin"],
      own: [], // salesperson can delete lead which is assigned to him/her
      team: ["sales-lead"], // sales-lead can delete lead for his team
    },
  },

  recordAccessControl: {
    browse: {
      all: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      own: {
        roles: ["salesperson"],
        apiUrl: "",
      },
      assignedTo: {
        roles: ["salesperson", "sales-lead"],
        apiUrl: "",
      },
      belongsTo: {
        ssoTeam: {
          roles: ["sales-lead"],
          apiUrl: "",
        },
      },
    },
  },

  browse: {
    table: {
      actions: {
        manageCellEdit: false,
        columnSelectionDisabledCount: 3,
        isActionColumnVisible: true,
        isTrashedTabVisible: true,
      },
      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          selectableColumn: false,
          headerFilter: false,
          download: false,
          editable: false,
        },

        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          hozAlign: "left",
          download: false,
          editable: false,
        },

        {
          title: "Source Type",
          field: "sourceType",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
          editor: "list",
          editorParams: {
            values: [
              "Purchased List",
              "Trade Show",
              "Webinar",
              "Click Funnel",
              "Advertisement",
              "Customer Event",
              "Employee Referral",
              "External Referral",
              "Google AdWords",
            ],
          },
          editable: false,
        },

        {
          title: "Name",
          field: "name",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
          editable: false,
        },

        {
          title: "Business",
          field: "ssoBusinessName",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: true,
          selectableColumn: true,
          headerSort: true,
          editable: false,
        },

        {
          title: "Team",
          field: "ssoTeamName",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: true,
          selectableColumn: true,
          headerSort: true,
          editable: false,
        },

        // {
        //   title: "Business",
        //   field: "ssoBusinessSlug",
        //   dataType: "string",
        //   filterable: true,
        //   searchable: true,
        //   headerFilter: false,
        //   headerSort: true,
        //   selectableColumn: true,
        //   hozAlign: "left",
        //   editor: "select",
        //   editorParams: {
        //     valuesLookup: () => {
        //       return new Promise((resolve, reject) => {
        //         fetch(
        //           "http://localhost:8001/api/v1/en/console/model/ssoBusiness"
        //         )
        //           .then((response) => response.json())
        //           .then((json) => {
        //             resolve(json.data);
        //             json.data.map((item) => {
        //               item.label = item.name;
        //               item.value = item.slug;
        //               return item;
        //             });
        //           })
        //           .catch((error) => {
        //             reject(error);
        //           });
        //       });
        //        return [
        //           {
        //               "label": "Boss Boleh",
        //               "value": "bossboleh"
        //           },
        //           {
        //               "label": "TaxPOD",
        //               "value": "taxpod"
        //           },
        //           {
        //               "label": "YYC Business School",
        //               "value": "yyc-business-school"
        //           }
        //       ] */
        //     },
        //   },
        //   editable: false,
        // },

        // {
        //   title: "Team",
        //   field: "ssoTeamUuid",
        //   dataType: "string",
        //   filterable: false,
        //   searchable: false,
        //   headerFilter: false,
        //   headerSort: false,
        //   selectableColumn: true,
        //   hozAlign: "left",

        //   formatter: (cell) => {

        //     console.log(cell.getData());

        //    const teamName = fetch("http://localhost:8001/api/v1/en/console/model/ssoTeam/" + cell.getValue() + "/list")
        //     .then(response => response.json())
        //     .then(data => {
        //       if (cell.getValue() === data.uuid) {
        //         console.log(cell.getData());
        //         return data.name;
        //       }
        //     });

        //     const displayTeamName = () => {
        //       teamName.then((a) => {

        //        return a;
        //       });

        //     };

        //     console.log(displayTeamName());

        //     return 'sss';

        //     /* return cell.getData().ssoTeamUuid; */

        //   },

        //    // // following code is working fine but not returning the value to the cell. its returning the value to the console.log only.

        //   editable: false,
        //   visible: true,
        // },

        {
          title: "Total Leads",
          field: "_count.Lead",
          dataType: "integer",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          formatter: (cell) => {
            return cell.getData()._count.Lead;
          },
          editable: false,
        },

        {
          title: "Status",
          field: "status",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
          editor: "list",
          editorParams: { values: ["Open", "Closed"] },
          editable: false,
        },

        {
          title: "Action",
          formatter: (cell) => {
            Vue.use(Vuetify);
            let ComponentClass = Vue.extend(ManageActionColumn);
            let instance = new ComponentClass({
              propsData: {
                cellData: cell.getData(),
                cellValue: cell.getValue(),
                cellTableData: cell.getTable().getData(),
                cellRowData: cell.getRow().getData(),
                serviceKey: "lead",
                modelKey: "leadSource",
                modelId: cell.getRow().getData().id,
              },
              vuetify: new Vuetify({}),
              store,
              router,
            });
            instance.$mount();
            return instance.$el;
          },
        },
      ],
      advanceFilter: {
        columns: [
          {
            name: "name",
            type: "nominal",
            values: [],
          },
          {
            name: "description",
            type: "nominal",
            values: [],
          },
        ],
      },

      pdf: {
        headers: [
          {
            title: "Id",
            field: "id",
          },
          {
            title: "Lead Source Type",
            field: "sourceType",
          },

          {
            title: "Name",
            field: "name",
          },
        ],
      },
    },
  },

  read: {
    tabs: [
      /* {
          key: "demoProduct", //key for tab
          label: "Products",
          dataType: "model",
          relationship: "child",
          modelKey: "demoProduct",
          mapping: {
            parent: "id",
            child: "demoCategoryId",
          },
        },   */
    ],
    table: {
      headers: [
        {
          title: "Id",
          field: "id",
        },

        {
          title: "UUID",
          field: "uuid",
        },

        {
          title: "Sourece Type",
          field: "sourceType",
        },

        {
          title: "Name",
          field: "name",
        },

        {
          title: "Business",
          field: "ssoBusinessName",
        },

        {
          title: "Team",
          field: "ssoTeamName",
        },

        {
          title: "Lead Count",
          field: "leadCount",
        },

        {
          title: "Status",
          field: "status",
        },

        {
          title: "Webhook URL",
          field: "customWebhookUrl",
        },

        {
          title: "Created",
          field: "timestampCreated",
        },
        {
          title: "Updated",
          field: "timestampUpdated",
        },
      ],
    },
  },

  add: {
    fields: [
      {
        type: "select",
        name: "source Type",
        key: "sourceType",
        options: [
          { text: "Purchased List", value: "Purchased List" },
          { text: "Trade Show", value: "Trade Show" },
          { text: "Webinar", value: "Webinar" },
          { text: "Click Funnel", value: "Click Funnel" },
          { text: "Advertisement", value: "Advertisement" },
          { text: "Customer Event", value: "Customer Event" },
          { text: "Employee Referral", value: "Employee Referral" },
          { text: "External Referral", value: "External Referral" },
          { text: "Google AdWords", value: "Google AdWords" },
        ],
      },

      {
        type: "string",
        name: "Name",
        key: "name",
      },

      /* {
        type: "modelDependency",
        parentKey: "ssoBusinessSlug",
        parentName: "Business",
        parentModelKey: "ssoBusiness",
        parentApiListUrl: "/v1/en/console/model/ssoBusiness",
        parentOptionText: "name",
        parentOptionValue: "slug",
        parentOptionTextSaveAs: "ssoBusinessName",
        childKey: "ssoTeamUuid",
        childName: "Team",
        childModelKey: "ssoTeam",
        childApiListUrl: "/v1/en/console/ssoTeam/list",
        childOptionText: "name",
        childOptionValue: "uuid",
        childOptionTextSaveAs: "ssoTeamName",
      },  */

      {
        type: "modelParent",
        parentKey: "ssoBusinessSlug",
        parentName: "Business",
        parentModelKey: "ssoBusiness",
        parentApiListUrl: "/v1/en/console/model/ssoBusiness",
        parentOptionText: "name",
        parentOptionValue: "slug",
        parentOptionTextSaveAs: "ssoBusinessName",
        modelServiceKey: "leadSource",
      },

      {
        type: "modelChild",
        childKey: "ssoTeamUuid",
        childName: "Team",
        childModelKey: "ssoTeam",
        childApiListUrl: "/v1/en/console/ssoTeam/list",
        childOptionText: "name",
        childOptionValue: "uuid",
        childOptionTextSaveAs: "ssoTeamName",
        modelServiceKey: "leadSource",
      },

      /* {
        type: "model",
        key: "ssoBusinessSlug",
        name: "Business",
        modelKey: "ssoBusiness",
        apiListUrl: "/v1/en/console/model/ssoBusiness",
        optionText: "name",
        optionValue: "slug",
        optionTextSaveAs: "ssoBusinessName",
      }, 

      {
        type: "model",
        key: "ssoTeamUuid",
        name: "Team",
        modelKey: "ssoTeam",
        apiListUrl: "/v1/en/console/model/ssoTeam",
        optionText: "name",
        optionValue: "uuid",
        optionTextSaveAs: "ssoBusinessName",
      }, 
 */
      /*  {
        type: "number",
        name: "Lead Count",
        key: "leadCount",
      },  */

      {
        type: "select",
        name: "Status",
        key: "status",
        options: [
          {
            text: "Open",
            value: "Open",
          },
          {
            text: "Closed",
            value: "Closed",
          },
        ],
      },
    ],
    rules: {
      name: ["required"],
      sourceType: ["required"],
      ssoBusinessSlug: ["required"],
      ssoTeamUuid: ["required"],
    },
  },
  edit: true,
  delete: true,
  leadMultiAssign: false,
};
