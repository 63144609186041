<template>
  <div class="text-h5 mb-3">{{ title }}</div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:{
      title: {
        type: String,
      },
    },
    data: () => ({
      //
    }),
    watch: {
      //
    },
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>