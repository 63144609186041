export default [
  {
    name: "Services",
    icon: "mdi-database",
    route: {
      name: "PageServiceModelBrowse",
      params: { serviceKey: "crm", modelKey: "crmService" },
      query: {
        viewId: "all",
      },
    },
  },

  /* {
    name: "Services Test",
    icon: "mdi-database",
    route: {
      name: "PageServiceModelBrowseTest",
      params: { serviceKey: "crm", modelKey: "crmService" },
      query: {
        viewId: "all",
      },
    },
  }, */

  {
    name: "Permissions",
    icon: "mdi-database",
    route: {
      name: "PageServiceModelBrowse",
      params: { serviceKey: "crm", modelKey: "crmServicePermission" },
      query: {
        viewId: "all",
      },
    },
  },
  // {
  //   name: 'Role Permissions',
  //   icon: 'mdi-database',
  //   route: { name: 'PageServiceModelBrowse', params: { serviceKey:"crm", modelKey: 'crmServicePermissionToInternalRole' } },
  // },
];
