export default [
  // {
  //   name: "Team Management",
  // },
  // {
  //   name: "Businesses",
  //   icon: "mdi-database",
  //   route: {
  //     name: "PageServiceModelBrowse",
  //     params: { serviceKey: "sso", modelKey: "ssoBusiness" },
  //     query: {
  //       viewId: "all",
  //     },
  //   },
  // },
  // {
  //   name: "Teams",
  //   icon: "mdi-database",
  //   route: {
  //     name: "PageServiceModelBrowse",
  //     params: { serviceKey: "sso", modelKey: "ssoTeam" },
  //     query: {
  //       viewId: "all",
  //     },
  //   },
  // },
  // {
  //   type: "divider",
  // },
  // {
  //   name: "Staff Management",
  // },
  // {
  //   name: "Companies",
  //   icon: "mdi-database",
  //   route: {
  //     name: "PageServiceModelBrowse",
  //     params: { serviceKey: "sso", modelKey: "internalCompany" },
  //     query: {
  //       viewId: "all",
  //     },
  //   },
  // },
  // {
  //   name: "Departments",
  //   icon: "mdi-database",
  //   route: {
  //     name: "PageServiceModelBrowse",
  //     params: { serviceKey: "sso", modelKey: "internalDepartment" },
  //     query: {
  //       viewId: "all",
  //     },
  //   },
  // },
  {
    name: "Roles",
    icon: "mdi-database",
    route: {
      name: "PageServiceModelBrowse",
      params: { serviceKey: "sso", modelKey: "internalRole" },
      query: {
        viewId: "all",
      },
    },
  },
  {
    name: "Users",
    icon: "mdi-database",
    route: {
      name: "PageServiceModelBrowse",
      params: { serviceKey: "sso", modelKey: "internalUser" },
      query: {
        viewId: "all",
      },
    },
  },
  // {
  //   name: "Authorizations",
  //   icon: "mdi-database",
  //   route: {
  //     name: "PageServiceModelBrowse",
  //     params: { serviceKey: "sso", modelKey: "ssoAuthorization" },
  //   },
  //   disabled: true,
  // },
  // {
  //   type: "divider",
  // },
  // {
  //   name: "System Integrations",
  // },
  // {
  //   name: "Microsoft Accounts",
  //   icon: "mdi-database",
  //   route: {
  //     name: "PageServiceModelBrowse",
  //     params: { serviceKey: "sso", modelKey: "ssoMicrosoftAccount" },
  //     query: {
  //       viewId: "all",
  //     },
  //   },
  // },
  // {
  //   name: "Webhooks",
  //   icon: "mdi-database",
  //   route: {
  //     name: "PageServiceModelBrowse",
  //     params: { serviceKey: "sso", modelKey: "ssoWebhook" },
  //     query: {
  //       viewId: "all",
  //     },
  //   },
  // },
];
