export default {
  key: "contact",
  column: {
    uid: "uuid",
    text: "name",
  },
  name: {
    singular: "Contact",
    plural: "Contacts",
  },
  parents: [
    //
  ],
  browse: {
    table: {
      actions: {
        single: {
          read: true,
          edit: false,
          delete: false,
          restore: false,
          deleteHard: false,
        },
        multiple: {
          delete: false,
        },
      },
      options: {
        sortBy: ["name"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "No.",
          value: "index",
          sortable: false,
          searchable: false,
          filterable: false,
        },
        {
          text: "Full Name",
          value: "name",
        },
        {
          text: "Emails",
          value: "ChildEmail",
          sortable: false,
          searchable: false,
          filterable: false,
        },
        {
          text: "Created",
          value: "timestampCreated",
          searchable: false,
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: "email",
        label: "Emails",
      },
    ],
    table: {
      headers: [
        {
          text: "UUID",
          value: "uuid",
        },
        {
          text: "Full Name",
          value: "name",
        },
        {
          text: "First Name",
          value: "firstName",
        },
        {
          text: "Last Name",
          value: "lastName",
        },
      ],
    },
  },
  add: false,
  edit: false,
  delete: false,
};
