<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="auto"
      persistent
    >
      <v-card>
        <v-card-title>Rename View</v-card-title>
        <v-card-text>
          <AError :api="api"/>
        </v-card-text>
        <v-card-text>
          <v-text-field 
            v-model="name" 
            label="Name"
            @keyup.native.enter="save"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn 
            text
            :disabled="api.isLoading"
            @click="close"
          >Cancel</v-btn>
          <v-btn 
            color="primary"
            :loading="api.isLoading"
            @click="save"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  //BOC:[api]
  import Api from '@/objects/api'
  //EOC
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:{
      updateView: {
        type:Function,
      },
    },
    data: () => ({
      dialog:false,
      id:null,
      name:null,
      //BOC:[api]
      api: new Api(),
      //EOC
    }),
    watch: {
      //
    },
    created() {
      //BOC:[api]
      this.api.setMethod(`POST`)
      this.api.setUrl(`${this.$service.crm}/v1/en/console/modelView/rename`)
      this.api.setCallbackCompleted((response)=>{
        this.updateView(response)
        this.dialog = false;
        this.$store.dispatch('showMessage','Renamed successfully.');
      })
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      close() {
        this.api.isError = false;
        this.api.error = null;
        this.dialog = false;
      },
      open(view) {
        this.id = view.id;
        this.name = view.name;
        this.dialog = true;
      },
      save() {
        this.api.setParams({
          id:this.id,
          name:this.name,
        })
        this.api.fetch();
      },
    }
  }
</script>