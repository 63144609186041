import moment from "moment";
import GlobalData from "@/services/hrdc/objects/globalData";

let stages = null;

async function fetchData() {
  const HrdcData = new GlobalData();
  stages = await HrdcData.allStages;
}

fetchData();

export default {
  key: "report_analysis",
  serviceKey: "hrdc",
  name: {
    singular: "Report Analysis",
    plural: "Report Analysis",
  },
  child: {
    durationAnalysisReport: {
      key: "duration_analysis_report",
      name: "Duration Analysis Report",
    },
  },
  parents: [
    //
  ],
  add: {
    name: "Report Analysis",
  },
  browse: {
    // Tabulator setup for duration analysis report.
    table: {
      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          headerFilter: false,
          selectableColumn: false,
          visible: false,
        },
        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          visible: false,
        },
        {
          title: "Company Name",
          field: "uuid",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: true,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          frozen: true,
          width: 200,
          cssClass: "freeze-border-right",
          formatter: (cell) => {
            const cellData = cell.getData();
            const rowData = cell.getRow();
            if (rowData.getPosition(true) === 1) {
              const rowElement = rowData.getElement();
              rowElement.style.borderBottom = "2px solid black";
              rowElement.style.backgroundColor = "lightblue";
              rowElement.style.fontWeight = "bold";
            }

            return `${cellData.oriCompanyName.trim().toUpperCase()}`;
          },
        },
        {
          title: "Created Date",
          field: "timestampCreated",
          dataType: "onlyGteLte",
          filterable: true,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 150,
          visible: false,
          formatter: (cell) => {
            const value = cell.getValue();
            if (value) {
              return moment(value).format("YYYY-MM-DD HH:MM");
            }
          },
        },
        {
          title: "Completed Date",
          field: "timestampCompleted",
          dataType: "onlyGteLte",
          filterable: true,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 150,
          visible: false,
        },
        {
          title: "Payment Channel",
          field: "paymentChannel",
          dataType: "onlyLike",
          filterable: true,
          searchable: true,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 150,
          visible: false,
          formatter: (cell) => {
            const value = cell.getValue();
            if (value) {
              return moment(value).format("YYYY-MM-DD HH:MM");
            }
          },
        },
        // {
        //   title: "Claim Amount",
        //   field: "totalPrice",
        //   dataType: "onlyLikeEqualNotEqual",
        //   filterable: true,
        //   searchable: true,
        //   sortable: false,
        //   headerFilter: false,
        //   selectableColumn: true,
        //   hozAlign: "left",
        //   headerWordWrap: true,
        //   headerSort: false,
        //   formatter: (cell) => {
        //     const value = cell.getValue();
        //     if (value) {
        //       return Number(value).toLocaleString();
        //     }
        //   },
        // },
        {
          title: "Application Submitted Date",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 100,
          formatter: (cell) => {
            let data = "-";
            const value = cell.getValue();
            if (value.length > 0) {
              const applicationSubmittedObj = value.find(
                (item) => item.stageId == stages.APPLICATION_SUBMITTED
              );
              if (applicationSubmittedObj) {
                data = moment(applicationSubmittedObj.startDate).format(
                  "D-MMM-YY"
                );
              }
            }

            return data;
          },
        },
        {
          title: "Days",
          field: "daysSubmittedToApproved",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 60,
          formatter: (cell) => {
            return cell.getValue();
          },
        },
        {
          title: "Application Approved Date",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 100,
          formatter: (cell) => {
            let data = "-";
            const value = cell.getValue();
            if (value.length > 0) {
              const applicationApprovedObj = value.find(
                (item) => item.stageId == stages.APPLICATION_APPROVED
              );
              if (applicationApprovedObj) {
                data = moment(applicationApprovedObj.startDate).format(
                  "D-MMM-YY"
                );
              }
            }

            return data;
          },
        },
        {
          title: "Days",
          field: "daysApprovedToSendHrdcEmail",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 60,
          formatter: (cell) => {
            const cellData = cell.getData();
            let val = cell.getValue();

            if (cellData.hrdcEmailToClientResend) {
              cell.getElement().style.backgroundColor = "#FFF9C4";

              val += ` (${cellData.hrdcEmailToClientResendCount})`;
            }

            return val;
          },
          cellMouseOver: function (e, cell) {
            const cellData = cell.getData();
            if (cellData.hrdcEmailToClientResend) {
              cell
                .getElement()
                .setAttribute(
                  "title",
                  `Total sent ${cellData.hrdcEmailToClientResendCount} times`
                );
            }
          },
        },
        {
          title: "Send HRDC Email to Client Date",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 200,
          formatter: (cell) => {
            const cellData = cell.getData();
            if (cellData.hrdcEmailToClientResend) {
              cell.getElement().style.backgroundColor = "#FFF9C4";
            }

            let firstSend = null;
            let lastSend = null;
            let data = "";
            const value = cell.getValue();
            if (value.length > 0) {
              // Get the latest send hrdc email to client.
              const lastSendHrdcEmailToClientObj = value.find(
                (item) =>
                  item.stageId == stages.SENT_CONFIRMATION_EMAIL_TO_CLIENT
              );
              if (lastSendHrdcEmailToClientObj) {
                lastSend = moment(
                  lastSendHrdcEmailToClientObj.startDate
                ).format("D-MMM-YY");
              }

              // Get the first time send hrdc email date to client.
              const firstSendHrdcEmailToClientObj = value.filter(
                (item) =>
                  item.stageId == stages.SENT_CONFIRMATION_EMAIL_TO_CLIENT
              );

              if (firstSendHrdcEmailToClientObj.length > 1) {
                firstSend = moment(
                  firstSendHrdcEmailToClientObj[
                    firstSendHrdcEmailToClientObj.length - 1
                  ].startDate
                ).format("D-MMM-YY");

                data = `${firstSend}/`;
              }

              data += lastSend ? `${lastSend}` : "-";
            }

            return data;
          },
        },
        {
          title: "Days",
          field: "daysClientCompleteRevert",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 60,
          formatter: (cell) => {
            return cell.getValue();
          },
        },
        {
          title: "Client Revert Form & Grant Completed Date",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 100,
          formatter: (cell) => {
            let data = "-";
            const cellData = cell.getData();

            if (cellData.clientRevertCompletedDate) {
              data = moment(cellData.clientRevertCompletedDate).format(
                "D-MMM-YY"
              );
            }

            return data;
          },
        },
        {
          title: "Days",
          field: "daysPendingHrdcInvoiceReadyToGenerate",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 60,
          formatter: (cell) => {
            return cell.getValue();
          },
        },
        {
          title: "HRDC Invoice Ready to Generate",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 100,
          formatter: (cell) => {
            let data = "-";
            const cellData = cell.getData();

            if (cellData.hrdcInvoiceReadyToGeneratedDate) {
              data = moment(cellData.hrdcInvoiceReadyToGeneratedDate).format(
                "D-MMM-YY"
              );
            }

            return data;
          },
        },
        {
          title: "Days",
          field: "daysPendingToGenerateHrdcInvoice",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 60,
          formatter: (cell) => {
            return cell.getValue();
          },
        },
        {
          title: "HRDC Invoice Generated Date",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 100,
          formatter: (cell) => {
            let data = "-";
            const value = cell.getValue();
            if (value.length > 0) {
              const manualInvoiceToHrdcObj = value.find(
                (item) => item.stageId == stages.MANUAL_INVOICE_TO_HRDC
              );
              if (manualInvoiceToHrdcObj) {
                data = moment(manualInvoiceToHrdcObj.startDate).format(
                  "D-MMM-YY"
                );
              }
            }

            return data;
          },
        },
        {
          title: "Days",
          field: "daysHrdcInvoiceToSubmitClaim",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 60,
          formatter: (cell) => {
            return cell.getValue();
          },
        },
        {
          title: "Claim Submitted to HRDC Date",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 100,
          formatter: (cell) => {
            let data = "-";
            const value = cell.getValue();
            if (value.length > 0) {
              const submittedClaimToHrdcObj = value.find(
                (item) => item.stageId == stages.SUBMITTED_CLAIM_TO_HRDC
              );
              if (submittedClaimToHrdcObj) {
                data = moment(submittedClaimToHrdcObj.startDate).format(
                  "D-MMM-YY"
                );
              }
            }

            return data;
          },
        },
        {
          title: "Days",
          field: "daysClaimSubmittedToApprovedClaim",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 60,
          formatter: (cell) => {
            return cell.getValue();
          },
        },
        {
          title: "Claim Approved from HRDC Date",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 100,
          formatter: (cell) => {
            let data = "-";
            const value = cell.getValue();
            if (value.length > 0) {
              const claimApprovedFromHrdcObj = value.find(
                (item) => item.stageId == stages.CLAIM_APPROVED_FROM_HRDC
              );
              if (claimApprovedFromHrdcObj) {
                data = moment(claimApprovedFromHrdcObj.startDate).format(
                  "D-MMM-YY"
                );
              }
            }

            return data;
          },
        },
        {
          title: "Days",
          field: "daysApprovedClaimToPaymentReceived",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 60,
          formatter: (cell) => {
            return cell.getValue();
          },
        },
        {
          title: "Payment Received from HRDC Date",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 100,
          formatter: (cell) => {
            let data = "-";
            const value = cell.getValue();
            if (value.length > 0) {
              const paymentReceivedObj = value.find(
                (item) => item.stageId == stages.PAYMENT_RECEIVED_FROM_HRDC
              );
              if (paymentReceivedObj) {
                data = moment(paymentReceivedObj.startDate).format("D-MMM-YY");
              }
            }

            return data;
          },
        },
        {
          title: "Days",
          field: "daysReceivedPaymentToAccEnrollment",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 60,
          formatter: (cell) => {
            return cell.getValue();
          },
        },
        {
          title: "Enrollment Date",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 100,
          formatter: (cell) => {
            let data = "-";
            const value = cell.getValue();
            if (value.length > 0) {
              const enrolmentDateObj = value.find((item) =>
                [
                  stages.CLAIM_COMPLETED,
                  stages.TAXPOD_ACCOUNT_ACTIVATION,
                ].includes(item.stageId)
              );

              if (enrolmentDateObj) {
                data = moment(enrolmentDateObj.startDate).format("D-MMM-YY");
                cell.getElement().classList.add("claim-basis-end-border-right");
              }
            }

            return data;
          },
        },
        {
          title: "Days",
          field: "daysPaymentReceivedToCnRequest",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 60,
          formatter: (cell) => {
            return cell.getValue();
          },
        },
        {
          title: "CN Request Form Submission Date",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 100,
          formatter: (cell) => {
            let data = "-";
            const value = cell.getValue();
            if (value.length > 0) {
              const cnRequestObj = value.find(
                (item) => item.stageId == stages.CREDIT_NOTE_REQUESTED
              );
              if (cnRequestObj) {
                data = moment(cnRequestObj.startDate).format("D-MMM-YY");
              }
            }

            return data;
          },
        },
        {
          title: "Days",
          field: "daysCnRequestToCnDocument",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 60,
          formatter: (cell) => {
            return cell.getValue();
          },
        },
        {
          title: "CN Documented Date",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 100,
          formatter: (cell) => {
            let data = "-";
            const value = cell.getValue();
            if (value.length > 0) {
              const cnDocumentObj = value.find(
                (item) => item.stageId == stages.CREDIT_NOTE_DOCUMENTED
              );
              if (cnDocumentObj) {
                data = moment(cnDocumentObj.startDate).format("D-MMM-YY");
              }
            }

            return data;
          },
        },
        {
          title: "Days CN Documented to Refund Email Submission",
          field: "daysCnDocumentToRefundEmailSubmission",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 120,
          formatter: (cell) => {
            return cell.getValue();
          },
        },
        {
          title: "Days Payment Received to Generate CN",
          field: "daysPaymentReceivedToCnGenerated",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 120,
          formatter: (cell) => {
            return cell.getValue();
          },
        },
        {
          title: "Credit Note Auto Generated Date",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 100,
          formatter: (cell) => {
            let data = "-";
            const value = cell.getValue();
            if (value.length > 0) {
              const cnAutoGeneratedObj = value.find(
                (item) => item.stageId == stages.CREDIT_NOTE_GENERATED
              );
              if (cnAutoGeneratedObj) {
                data = moment(cnAutoGeneratedObj.startDate).format("D-MMM-YY");
              }
            }

            return data;
          },
        },
        {
          title: "Days",
          field: "daysCnAutoGeneratedToRefundEmailSubmission",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 60,
          formatter: (cell) => {
            return cell.getValue();
          },
        },
        {
          title: "Refund Email (Draft)",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 100,
          formatter: (cell) => {
            let data = "-";
            const value = cell.getValue();
            if (value.length > 0) {
              const refundEmailSubmissionObj = value.find(
                (item) => item.stageId == stages.REFUND_EMAIL_SUBMISSION
              );
              if (refundEmailSubmissionObj) {
                data = moment(refundEmailSubmissionObj.startDate).format(
                  "D-MMM-YY"
                );
              }
            }

            return data;
          },
        },
        {
          title: "Days",
          field: "daysRefundEmailSubmissionToRefundEmailApproval1",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 60,
          formatter: (cell) => {
            return cell.getValue();
          },
        },
        {
          title: "Refund Email (Approval 1)",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 100,
          formatter: (cell) => {
            let data = "-";
            const value = cell.getValue();
            if (value.length > 0) {
              const refundEmailApproval1Obj = value.find(
                (item) => item.stageId == stages.REFUND_EMAIL_APPROVAL_1
              );
              if (refundEmailApproval1Obj) {
                data = moment(refundEmailApproval1Obj.startDate).format(
                  "D-MMM-YY"
                );
              }
            }

            return data;
          },
        },
        {
          title: "Days",
          field: "daysRefundEmailApproval1ToRefundEmailApproval2",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 60,
          formatter: (cell) => {
            return cell.getValue();
          },
        },
        {
          title: "Refund Email (Approval 2)",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 100,
          formatter: (cell) => {
            let data = "-";
            const value = cell.getValue();
            if (value.length > 0) {
              const refundEmailApproval2Obj = value.find(
                (item) => item.stageId == stages.REFUND_EMAIL_APPROVAL_2
              );
              if (refundEmailApproval2Obj) {
                data = moment(refundEmailApproval2Obj.startDate).format(
                  "D-MMM-YY"
                );
              }
            }

            return data;
          },
        },
        {
          title: "Days",
          field: "daysRefundEmailApproval2ToRefundEmailArToAp",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 60,
          formatter: (cell) => {
            return cell.getValue();
          },
        },
        {
          title: "Refund Email (AR to AP)",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 100,
          formatter: (cell) => {
            let data = "-";
            const value = cell.getValue();
            if (value.length > 0) {
              const refundEmailArToApObj = value.find(
                (item) => item.stageId == stages.REFUND_EMAIL_AR_TO_AP
              );
              if (refundEmailArToApObj) {
                data = moment(refundEmailArToApObj.startDate).format(
                  "D-MMM-YY"
                );
              }
            }

            return data;
          },
        },
        {
          title: "Days",
          field: "daysRefundEmailArToApToRefundToClient",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 60,
          formatter: (cell) => {
            return cell.getValue();
          },
        },
        {
          title: "Finance Refund to Client",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 100,
          formatter: (cell) => {
            let data = "-";
            const value = cell.getValue();
            if (value.length > 0) {
              const financeRefundToClientObj = value.find(
                (item) => item.stageId == stages.FINANCE_REFUND_TO_CLIENT
              );
              if (financeRefundToClientObj) {
                data = moment(financeRefundToClientObj.startDate).format(
                  "D-MMM-YY"
                );
              }
            }

            return data;
          },
        },
        {
          title: "Days",
          field: "daysRefundToClientToEmailPaymentAdvice",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 60,
          formatter: (cell) => {
            return cell.getValue();
          },
        },
        {
          title: "Email Payment Advice to Client",
          field: "ApplicationLog",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 100,
          formatter: (cell) => {
            let data = "-";
            const value = cell.getValue();
            if (value.length > 0) {
              const emailPaymentAdviceObj = value.find(
                (item) => item.stageId == stages.EMAIL_PAYMENT_ADVICE_TO_CLIENT
              );
              if (emailPaymentAdviceObj) {
                data = moment(emailPaymentAdviceObj.startDate).format(
                  "D-MMM-YY"
                );
              }
            }

            return data;
          },
        },
        {
          title: "Total Days",
          field: "totalAvgDays",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          headerSort: false,
          width: 80,
          frozen: true,
          cssClass: "freeze-border-left",
          formatter: (cell) => {
            return cell.getValue();
          },
        },
      ],
    },
  },
};
