<template>
  <div>
    <BreadDataTable
      ref="dataTable"
      :modelKey="childModel.key"
      :api="api"
      :conditions="conditions"
      :sortBy="[]"
      :sortDesc="[]"
      :table="table"
      :showViewableColumns="false"
      :showSearch="false"
    ></BreadDataTable>
  </div>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC
//BOC:[table]
import Table from "@/objects/table";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: {
    childModelKey: {
      type: String,
    },
    parent: {
      type: Object,
    },
    parentModel: {
      type: Object,
    },
  },
  data: () => ({
    //BOC:[api]
    api: new Api(),
    //EOC
    //BOC:[childModel]
    childModel: new Model(),
    //EOC
    //BOC:[childService]
    childService: new Service(),
    //EOC
    //BOC:[table]
    table: new Table(),
    //EOC
    //BOC:[conditions]
    conditions: [],
    //EOC
  }),
  watch: {
    //
  },
  created() {
    //BOC:[childModel]
    this.childModel.getByKey(this.childModelKey);
    //EOC
    //BOC:[childService]
    if (this.childModel.serviceKey) this.childService.set("key", this.childModel.serviceKey);
    //EOC
    //BOC:[conditions]
    var uid = (this.parentModel && this.parentModel.column && this.parentModel.column.uid) ? this.parentModel.column.uid : "id";
    this.conditions = [
      {
        field: `${this.parentModel.key}${this.$_ucfirst(uid)}`,
        operation: "EQUAL",
        value: this.parent[uid],
      },
    ];
    //EOC
    //BOC:[api]
    this.api.setUrl(
      `${this.$service[this.childService.key]}/v1/en/console/model/${
        this.childModel.key
      }`
    );
    //EOC
    //BOC:[table]
    this.table.setTable(this.childModel.browse.table);
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>