export default class Form {
	constructor() {
		this.fields = []
		this.rules = {}
		this.data = {}
	}
	get(params) {
		const {model,action} = params
		this.fields = model[action].fields ?? []
		this.rules = model[action].rules ?? {}
		//BOC: duplicate add operation fields and rules if edit operation value equal to true
		if(action == 'edit' && model[action] === true) {
			this.fields = model.add.fields
			this.rules = model.add.rules
		}
		//EOC
	}
	setData(data) {
		this.data = data;
	}
	setDataProperty(property, value) {
		this.data[property] = value;
	}
}