export default [
  {
    name: 'Marketing',
  },
  {
    name: 'Ads',
    icon: 'mdi-database',
    route: { name: 'PageServiceModelBrowse', params: { modelKey: 'account' } },
    disabled: true,
  },
  // {
  //   name: 'Facebook Pages',
  //   icon: 'mdi-database',
  //   route: { name: 'PageServiceModelBrowse', params: { modelKey: 'account' } },
  //   disabled: true,
  // },
  // {
  //   name: 'Landing Pages',
  //   icon: 'mdi-database',
  //   route: { name: 'PageServiceModelBrowse', params: { modelKey: 'account' } },
  //   disabled: true,
  // },
  {
    type: 'divider',
  },
]