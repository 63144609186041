import _ from "lodash"

export default class Table {
	constructor() {
		this.page = 1;
		this.actions = {};
		this.headers = [];
	}
	setTable(table) {
		var actions = _.cloneDeep(table.actions);
		var headers = _.cloneDeep(table.headers);
		if(actions) {
			headers.push({
				text:'Actions',
				value:'actions',
				sortable: false,
				searchable: false,
				filterable:false,
			})
		}
		this.actions = actions;
		this.headers = headers;
	}
}