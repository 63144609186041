export default {
  key: "generalInfo",
  serviceKey: "hrdc",
  name: {
    singular: "General Info",
    plural: "General Info",
  },
  parents: [
    //
  ],
  add: {
    name: "General Info",
  },
};
