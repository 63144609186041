export default {
  serviceKey: "developer",
  key: "demoProduct",
  column: {
    uid: "id", // tell the system which column to be used as unique id
    text: "name", // tell the system which column to display as text
  },
  name: {
    singular: "Product",
    plural: "Products",
  },
  parents: [
    "demoCategory"
  ],


  apiUrls: {
    apiBrowseUrl: "",
    apiStoreUrl: "",
    apiUpdateUrl: "",
    apiReadUrl: "",
    apiMultiUpdateUrl: "",
    apiTrashedBrowseUrl: "",
      apiSoftDeleteUrl: "",
      apiHardDeleteUrl: "",
      apiRestoreUrl: "",
  },

  permissionKeys: {
    browse: "developer-access",
    read: "developer-access",
    add: "developer-access",
    edit: "developer-access",
    delete: "developer-access",
    restore: "developer-access",
  },

  roleBasedFunctionalities: {
    browse: {
      all: ['superadmin'],
      own: ['salesperson'],
      team: ['sales-lead'],
    },
  },

  browse: {
    table: {
      actions: {
        manageCellEdit: true,
        columnSelectionDisabledCount: 3,
        isActionColumnVisible: true,
          isTrashedTabVisible: true,
      },
      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          headerFilter: false,
          selectableColumn: false,
          visible: true,
          download:false,
        },

        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          visible: true,
          download:false,
        },

        {
          title: "Parent",
          field: "demoCategoryId",
          dataType: "modelParent",
          modelKey: "demoCategory",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },

        {
          title: "Name",
          field: "name",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          editor: "input",
         /*  width:120,  */
         editable: false,
        },

        {
          title: "Cost",
          field: "cost",
          dataType: "float",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          formatter: "money",
          hozAlign: "right",
          editor: "number",
          editable: false,
        },
        {
          title: "Price",
          field: "price",
          dataType: "float",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "right",
          formatter: (cell) => {
            if (cell.getValue() > 900) {
              return (
                "<span style='color:red; font-weight:bold;'>" +
                cell.getValue() +
                "</span>"
              );
            } else {
              return cell.getValue();
            }
          },
          editor: "number",
          editable: false,
        },
        
        {
          title: "Country",
          field: "country",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          editor: "list",
          editorParams: {
            values: ["Malaysia", "Singapore", "Thailand"],
          },
          editable: false,
        },

      /* {
          title: "Expiration Date",
          field: "expirationDate",
          dataType: "dateWithBgColor", // if dateWithBgColor is used, then set filterable to false and searchable to false
          filterable: false,
          searchable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          editor:"date",
          editable: false,
          formatter: function (cell) {
            var dateValue = cell.getValue();
            var formattedDate = new Date(dateValue).toLocaleDateString("en-CA");
              cell.getElement().style.backgroundColor = "lightblue";
              return formattedDate;

          }, 
        },  */

        // normal date column

        {
          title: "Expiration Date",
          field: "expirationDate",
          dataType: "date",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          formatter: "datetime", 
          editor:"date",
          editable: false,
        }, 

        {
          title: "Stock Count",
          field: "stock",
          dataType: "integer",
          filterable: false,
          searchable: false,
          headerFilter: false,
          selectableColumn: true,
          formatter: "progress",
          formatterParams: {
            color: ["green", "orange", "red"],
            legendColor: "#000000",
            legendAlign: "center",
          },
          editor: "number",
          editable: false,
        },

        {
          title: "Availability",
          field: "instock",
          dataType: "boolean",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "center",
          editor: true,
          formatter: "tickCross",
          editable: false,
        },

        {
          title: "Rating",
          field: "rating",
          dataType: "integer",
          filterable: false,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          formatter: "star",
          editor: "number",
          editable: false,
        },
      ],
      advanceFilter: {
        columns: [
          {
            name: "name",
            type: "nominal",
            values: [],
          },
          {
            name: "cost",
            type: "numeric",
            values: [],
          },
          {
            name: "price",
            type: "numeric",
            values: [],
          },
          {
            name: "stock",
            type: "numeric",
            values: [],
          },
        ],
      },

      pdf: {
        headers: [
          {
            title: "Id",
            field: "id",
          },
          {
            title: "Name",
            field: "name",
          },
          {
            title: "Cost",
            field: "cost",
          },
          {
            title: "Price",
            field: "price",
          },
          {
            title: "Expiration Date",
            field: "expirationDate",
          },
          {
            title: "Stock Count",
            field: "stock",
          },
          {
            title: "Availability",
            field: "instock",
          },
          {
            title: "Rating",
            field: "rating",
          },
        ]
      },

    },
  },

  read: {
    tabs: [
      {
        key: "demoOrderToDemoProduct", //key for tab
        label: "Orders",
        dataType: "model",
        relationship: "intermediate",
        modelKey: "demoOrderToDemoProduct",
        intermediateChildModelKey: "demoOrder",
        mapping: {
          parent: "id",
          child: "demoProductId",
        },
      }, 
    ],
    table: {
      headers: [

        {
          title: "Id",
          field: "id",
        },
        
        {
          title: "Name",
          field: "name",
        },

        {
          title: "Description",
          field: "description",
        },

        {
          title: "Cost",
          field: "cost",
        },

        {
          title: "Price",
          field: "price",
        },

        {
          title: "Expiration Date",
          field: "expirationDate",
        },

        {
          title: "Stock Count",
          field: "stock",
        },


        {
          dataType: "model",
          relationship: "parent",
          modelKey: "demoCategory",
          title: "Category",
          field: "demoCategoryId",
        },

        {
          title: "Stock Availability",
          field: "instock",
        },

        {
          title: "Rating",
          field: "rating",
        },
        
        {
          title: "Created",
          field: "timestampCreated",
        },
        {
          title: "Updated",
          field: "timestampUpdated",
        },


      ],
    },
  },

  add: {
    fields: [
      {
        type: "string",
        name: "Name",
        key: "name",
      },

      {
        type:"textarea",
        name:"Description",
        key:"description"
      },

      {
        type: "float",
        name: "Cost",
        key: "cost",
      },

      {
        type: "float",
        name: "price",
        key: "price",
      },

      {
        type: "date",
        name: "Expiration Date",
        key: "expirationDate",
      },


      {
        type: "number",
        name: "Stock Count",
        key: "stock",
      },

      {
        type: 'model',
        key: 'demoCategoryId',
        name: 'Category',
        modelKey: 'demoCategory',
        optionText: 'name',
        optionValue: 'id',
      },


      {
        type: "select",
        name: "Stock Availability",
        key: "instock",
        options: [
          {
            text: "In Stock",
            value: true,
          },
          {
            text: "Out of Stock",
            value: false,
          },
        ],
      },

      {
        type: "number",
        name: "Rating",
        key: "rating",
      },


    ],
    rules: {
      name: ["required"],
      cost: ["required"],
      price: ["required"],
      expirationDate: ["required"],
      stock: ["required"],
    },
  },
  edit: true,
  delete: true,

};
