export default {
    state: {
      data: {
        paginationPageNo: null,
      },
    },
    getters: {},
    actions: {},
    mutations: {
      assignDeveloperData(state, data) {
        state.data = data;
      },
    },
  };
  