<template>
  <div>
    <v-tabs
      v-model="tab.current"
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
      elevation="1"
    >
      <v-tab
        v-for="item in tab.tabs"
        :key="item.key"
        :href="`#${item.key}`"
      >
        {{ item.label }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:{
      tab:{
        type:Object,
        required: true,
      },
    },
    data: () => ({
      //
    }),
    watch: {
      //
    },
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>