import models from '@/models/index'

/**
 * @property {Function} getByKey
 */
export default class Model {
	constructor() {
		this.key = null;
	}
	/**
	 * Get model by key
	 * @param {number} key The first number to add.
	 */
	getByKey(key) {
		for(let [k,v] of Object.entries(models[key])) {
			this[k] = v;
		}
	}
}