export default {
    key: "emailClient",
    serviceKey: "hrdc",
    name: {
      singular: "Send HRDC Email To Client",
      plural: "Send HRDC Email To Client",
    },
    parents: [
      //
    ],
    add: {
      name: "Send HRDC Email To Client",
    },
  };
  