export default {
  serviceKey: "log",
  key: "logRequest",
  column: {
    uid: "id", // tell the system which column to be used as unique id
    text: "id", // tell the system which column to display as text
  },
  name: {
    singular: "Request",
    plural: "Requests",
  },
  parents: ["internalUser"],

  apiUrls: {
    apiBrowseUrl: "",
    apiStoreUrl: "",
    apiUpdateUrl: "",
    apiReadUrl: "",
    apiMultiUpdateUrl: "",
    apiTrashedBrowseUrl: "",
    apiSoftDeleteUrl: "",
    apiHardDeleteUrl: "",
    apiRestoreUrl: "",
  },

  permissionKeys: {
    browse: "log-logRequest-browse",
    read: "log-logRequest-read",
    add: "log-logRequest-add",
    edit: "log-logRequest-edit",
    delete: "log-logRequest-delete",
    restore: "log-logRequest-restore",
    forceDelete: "log-logRequest-forceDelete",
  },

  roleBasedFunctionalities: {
    browse: {
      all: ["superadmin"],
      own: ["salesperson"],
      team: ["sales-lead"],
    },
  },

  browse: {
    table: {
      actions: {
        manageCellEdit: false,
        columnSelectionDisabledCount: 3,
        isActionColumnVisible: true,
        isTrashedTabVisible: true,
      },
      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          headerFilter: false,
          selectableColumn: false,
          visible: true,
          download: false,
        },

        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          visible: true,
          download: false,
        },
        {
          title: "Id",
          field: "id",
          dataType: "integer",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },
        {
          title: "Created",
          field: "timestampCreated",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },

        /* {
          title: "Internal User",
          field: "internalUserUuid",
          dataType: "modelParent",
          modelKey: "internalUser",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        }, */

        {
          title: "Internal User",
          field: "internalUserUuid",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },

        {
          title: "Service",
          field: "serviceKey",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },
        {
          title: "URL",
          field: "requestUrl",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },
        // {
        //   text: "Body",
        //   value: "requestBody",
        //   sortable: true,
        //   searchable: true,
        //   filterable: true,
        // },
        // {
        //   text: "Internal User",
        //   value: "requestUser",
        //   sortable: true,
        //   searchable: true,
        //   filterable: true,
        // },
      ],
    },
  },
  read: {
    tabs: [
      //
    ],
    table: {
      headers: [
        {
          title: "Id",
          field: "id",
        },
        {
          dataType: "model",
          relationship: "parent",
          modelKey: "internalUser",
          title: "Internal User",
          field: "internalUserUuid",
        },
        {
          title: "Service",
          field: "serviceKey",
        },
        {
          title: "Request URL",
          field: "requestUrl",
        },
        {
          title: "Request Params",
          field: "requestParams",
        },
        {
          title: "Request Query",
          field: "requestQuery",
        },
        {
          title: "Request Body",
          field: "requestBody",
        },
        {
          title: "Created",
          field: "timestampCreated",
        },
      ],
    },
  },
  add: false,
  edit: false,
  delete: false,
};
