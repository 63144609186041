<template>
  <div class="d-flex align-center mb-3">
    <span class="text-h5">{{ title }}</span>
    <v-spacer/>
    <slot></slot>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:{
      title:{
        type:String,
      },
    },
    data: () => ({
      //
    }),
    watch: {
      //
    },
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>