export default [
  {
    name: 'Products',
  },
  {
    name: 'Products',
    icon: 'mdi-database',
    route: { name: 'PageServiceModelBrowse', params: { modelKey: 'account' } },
    disabled: true,
  },
  {
    name: 'Pricings',
    icon: 'mdi-database',
    route: { name: 'PageServiceModelBrowse', params: { modelKey: 'account' } },
    disabled: true,
  },
  {
    name: 'Plans',
    icon: 'mdi-database',
    route: { name: 'PageServiceModelBrowse', params: { modelKey: 'account' } },
    disabled: true,
  },
  {
    type: 'divider',
  },
]