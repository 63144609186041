export default {
  key: "emailPaymentAdviceToClient",
  serviceKey: "hrdc",
  name: {
    singular: "Email Payment Advice to Client",
    plural: "Email Payment Advice to Client",
  },
  parents: [
    //
  ],
  add: {
    name: "Email Payment Advice to Client",
  },
  view: {
    name: "Email Payment Advice to Client",
  },
};
