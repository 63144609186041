export default [
  {
    name: 'Profiling',
  },
  {
    name: 'Contacts',
    icon: 'mdi-database',
    route: { name: 'PageServiceModelBrowse', params: { modelKey: 'contact' } },
  },
  {
    name: 'Companies',
    icon: 'mdi-database',
    route: { name: 'PageServiceModelBrowse', params: { modelKey: 'company' } },
    disabled: true,
  },
  {
    name: 'Emails',
    icon: 'mdi-database',
    route: { name: 'PageServiceModelBrowse', params: { modelKey: 'email' } },
  },
  {
    type: 'divider',
  },
]