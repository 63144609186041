export default {
  serviceKey: 'developer',
  key:'demoCustomer',
  column: {
    uid: "id", // tell the system which column to be used as unique id
    text: "name", // tell the system which column to display as text
  },
  name:{
    singular:'Customer',
    plural:'Customers',
  },
  parents: [
    //
  ],

  apiUrls: {
    apiBrowseUrl: "",
    apiStoreUrl: "",
    apiUpdateUrl: "",
    apiReadUrl: "",
    apiMultiUpdateUrl: "",
    apiTrashedBrowseUrl: "",
    apiSoftDeleteUrl: "",
    apiHardDeleteUrl: "",
    apiRestoreUrl: "",
},

permissionKeys: {
  browse: "developer-access",
  read: "developer-access",
  add: "developer-access",
  edit: "developer-access",
  delete: "developer-access",
  restore: "developer-access",
},

roleBasedFunctionalities: {
  browse: {
    all: ['superadmin'],
    own: ['salesperson'],
    team: ['sales-lead'],
  },
},
  
  browse: {
    table: {
      actions: {
        manageCellEdit: true,
        columnSelectionDisabledCount: 3,
        isActionColumnVisible: true,
          isTrashedTabVisible: true,
      },
      headers: [

        {
          formatter:"rowSelection", 
          titleFormatter:"rowSelection", 
          titleFormatterParams:{
              rowRange:"active" //only toggle the values of the active filtered rows
            },
          hozAlign:"left", 
          headerSort:false,
          filterable: false,
          searchable: false,
          selectableColumn: false,
          headerFilter: false,
          editable: false,
        },

        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          editable: false,
        },

        /* {
          title: "ID",
          field: "id",
          dataType: "interger",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: true,
          hozAlign: "left", 
        }, */
        {
          title: "Name",
          field: "name",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
          editor: "input",
          editable: false,
        },

        {
          title: "Address",
          field: "address",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
          editor: "input",
          editable: false,
        },

        {
          title: "Phone No",
          field: "phone",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
          editor: "input",
          editable: false,
        },

        {
          title: "Email",
          field: "email",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
          editor: "input",
          editable: false,
        },
        

      ],
      advanceFilter: {
        columns: [
          {
            name: "name",
            type: "nominal",
            values: [],
          },
          {
            name: "address",
            type: "nominal",
            values: [],
          },
          {
            name: "phone",
            type: "nominal",
            values: [],
          },
          {
            name: "email",
            type: "nominal",
            values: [],
          },
        ],
      },
    },
  },

  add: {
    fields: [
      {
        type: "string",
        name: "Name",
        key: "name",
      },

      {
        type:"textarea",
        name:"Address",
        key:"address"
      },

      {
        type: "string",
        name: "Phone",
        key: "phone",
      },

      {
        type: "string",
        name: "Email",
        key: "email",
      },

      {
        type: "string",
        name: "City",
        key: "city",
      },

      {
        type: "string",
        name: "State",
        key: "state",
      },

      {
        type: "string",
        name: "Zip Code",
        key: "zip",
      },


      {
        type: "string",
        name: "Country",
        key: "country",
      },

      /* {
        type: "select",
        name: "Stock Availability",
        key: "instock",
        options: [
          {
            text: "In Stock",
            value: true,
          },
          {
            text: "Out of Stock",
            value: false,
          },
        ],
      }, */

      {
        type: "textarea",
        name: "Notes",
        key: "notes",
      },

      {
        type: "textarea",
        name: "Shipping Notes",
        key: "shipping",
      },

       {
        type: "select",
        name: "Status",
        key: "status",
        options: [
          {
            text: "Active",
            value: 'active',

          },
          {
            text: "In Active",
            value: 'inactive',
          },
        ],
      }, 


    ],
    rules: {
      name: ["required"],
      address: ["required"],
      phone: ["required"],
      email: ["required", "email"],
    },
  },
  edit: true,
  delete: true,

};
