import ManageActionColumn from "../../../components/common/BreadTabulatorActionButton.vue";

import Vue from "vue";
import Vuetify from "vuetify";
import store from "../../../store";
import router from "../../../router";

export default {
  serviceKey: "sso",
  key: "internalCompany",
  column: {
    uid: "slug", // tell the system which column to be used as unique id
    text: "name", // tell the system which column to display as text
  },
  name: {
    singular: "Internal Company",
    plural: "Internal Companies",
  },
  parents: [
    //
  ],

  apiUrls: {
    apiBrowseUrl: "",
    apiStoreUrl: "",
    apiUpdateUrl: "",
    apiReadUrl: "",
    apiMultiUpdateUrl: "",
    apiTrashedBrowseUrl: "",
    apiSoftDeleteUrl: "",
    apiHardDeleteUrl: "",
    apiRestoreUrl: "",
  },

  permissionKeys: {
    browse: "sso-internalCompany-browse",
    read: "sso-internalCompany-read",
    add: "sso-internalCompany-add",
    edit: "sso-internalCompany-edit",
    delete: "sso-internalCompany-delete",
    restore: "sso-internalCompany-restore",
    forceDelete: "sso-internalCompany-forceDelete",
  },

  recordAccessControl: {
    browse: {
      all: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      own: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      assignedTo: {
        roles: ["salesperson", "sales-lead"],
        apiUrl: "",
      },
      belongsTo: {
        ssoBusiness: {
          roles: ["superadmin"],
          apiUrl: "",
        },
        ssoTeam: {
          roles: ["superadmin"],
          apiUrl: "",
        },
      },
    },
  },

  roleBasedFunctionalities: {
    browse: {
      all: ["superadmin"],
      own: ["salesperson"],
      team: ["sales-lead"],
    },
  },

  browse: {
    // alert:{
    //   text:[
    //     "Kindly contact superadmin if you need to add/edit/delete the data.",
    //   ],
    // },

    table: {
      actions: {
        manageCellEdit: false,
        columnSelectionDisabledCount: 3,
        isActionColumnVisible: true,
        isTrashedTabVisible: true,
      },
      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          headerFilter: false,
          selectableColumn: false,
          visible: true,
          download: false,
        },

        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          visible: true,
          download: false,
        },
        {
          title: "Name",
          field: "name",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          editable: false,
        },
        {
          title: "Slug",
          field: "slug",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          editable: false,
        },

        {
          title: "Total Departments",
          field: "_count.InternalDepartment",
          dataType: "integer",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
        },

        {
          title: "Action",
          formatter: (cell) => {
            Vue.use(Vuetify);
            let ComponentClass = Vue.extend(ManageActionColumn);
            let instance = new ComponentClass({
              propsData: {
                cellData: cell.getData(),
                cellValue: cell.getValue(),
                cellTableData: cell.getTable().getData(),
                cellRowData: cell.getRow().getData(),
                serviceKey: "sso",
                modelKey: "internalCompany",
                modelId: cell.getRow().getData().id,
              },
              vuetify: new Vuetify({}),
              store,
              router,
            });
            instance.$mount();
            return instance.$el;
          },
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: "internalDepartment", //key for tab
        label: "Departments",
        dataType: "model",
        relationship: "child",
        modelKey: "internalDepartment",
        mapping: {
          parent: "slug",
          child: "internalCompanySlug",
        },
      },
    ],
    table: {
      headers: [
        {
          title: "Name",
          field: "name",
        },
        {
          title: "Slug",
          field: "slug",
        },
        {
          dataType: "model",
          relationship: "child",
          modelKey: "internalDepartment",
          title: "Total Departments",
          field: "_count.internalDepartment",
        },
        {
          title: "Created",
          field: "timestampCreated",
        },
        {
          title: "Updated",
          field: "timestampUpdated",
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: "string",
        name: "Name",
        key: "name",
      },
      {
        type: "string",
        name: "Slug",
        key: "slug",
      },
    ],
    rules: {
      name: ["required"],
      slug: ["required"],
    },
  },
  edit: true,
  delete: true,
};
