<template>
  <div>
    <v-menu
      open-on-hover
      bottom
      offset-y
      :close-on-content-click="false"
      :close-on-click="false"
      rounded="lg"
      min-width="400"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          dark
          elevation="0"
          v-bind="attrs"
          v-on="on"
          class="px-1"
        >
          {{ auth.name }} ▼
        </v-btn>
      </template>

      <v-list>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          prepend-icon="mdi-account-details"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>My Roles</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(role, index) in auth.roles" :key="index">
            <v-list-item-content>
              <v-list-item-title>
                <div class="text-caption">
                  {{ role.name }}
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider color="grey"></v-divider>

        <v-list-item :to="{ name: 'PageConsoleLogout' }" link>
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: {
    //
  },
  data: () => ({
    items: [
      {
        action: "mdi-silverware-fork-knife",
        items: [
          { title: "Breakfast & brunch" },
          { title: "New American" },
          { title: "Sushi" },
        ],
        title: "Dining",
      },
    ],
  }),
  watch: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>
