import leadStatus from "../objects/leadStatus";
import apiService from "../objects/apiServices";

import ManageActionColumn from "../../../components/common/BreadTabulatorActionButton.vue";

import Vue from "vue";
import Vuetify from "vuetify";
import store from "../../../store";
import router from "../../../router";

export default {
  serviceKey: "lead",
  key: "leadNew",
  column: {
    uid: "uuid", // tell the system which column to be used as unique id
    text: "name", // tell the system which column to display as text
  },
  name: {
    singular: "New Lead",
    plural: "New Leads",
  },
  parents: [
    //
  ],
  apiUrls: {
    apiBrowseUrl: "/v1/en/console/model/lead/new",
    apiStoreUrl: "/v1/en/console/tabulator/model/lead/add",
    apiUpdateUrl: "/v1/en/console/tabulator/model/lead",
    apiReadUrl: "/v1/en/console/tabulator/model/lead",
    apiMultiUpdateUrl: "/v1/en/console/tabulator/model/lead",
    apiTrashedBrowseUrl: "/v1/en/console/model/lead/new/deletedList",
    apiSoftDeleteUrl: "/v1/en/console/tabulator/model/lead",
    apiHardDeleteUrl: "/v1/en/console/tabulator/model/lead",
    apiRestoreUrl: "/v1/en/console/tabulator/model/lead",
  },

  permissionKeys: {
    browse: "lead-lead-browse",
    read: "lead-lead-read",
    add: "lead-lead-add",
    edit: "lead-lead-edit",
    delete: "lead-lead-delete",
    restore: "lead-lead-restore",
    forceDelete: "lead-lead-forceDelete",
    leadAssign: "lead-lead-assign",
  },

  roleBasedFunctionalities: {
    browse: {
      all: ["superadmin"],
      own: ["salesperson"],
      team: ["sales-lead"],
    },
  },

  recordAccessControl: {
    browse: {
      all: {
        roles: ["superadmin"],
        apiUrl: "/v1/en/console/model/lead/new",
      },
      own: {
        roles: ["salesperson"],
        apiUrl: "/v1/en/console/model/lead/new",
      },
      assignedTo: {
        roles: ["salesperson"],
        apiUrl: "/v1/en/console/model/lead/new",
      },
      belongsTo: {
        ssoTeam: {
          roles: ["sales-lead"],
          apiUrl: "/v1/en/console/model/lead/new",
        },
      },
    },
  },

  browse: {
    table: {
      actions: {
        manageCellEdit: false,
        columnSelectionDisabledCount: 3,
        isActionColumnVisible: true,
        isTrashedTabVisible: true,
      },

      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          selectableColumn: false,
          headerFilter: false,
          download: false,
          editable: false,
          //showByDefault: true,
        },

        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          hozAlign: "left",
          download: false,
          editable: false,
          //showByDefault: true,
        },

        {
          title: "Team",
          field: "ssoTeamUuid",
          dataType: "modelDropdown",
          textColumn: "ssoTeamName",
          apiServiceKey: "sso",
          apiUrl: "/v1/en/console/model/ssoTeam/",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          headerSort: true,
          editor: "list",
          editorParams: {
            valuesLookup: () => {
              var apiUrl = new apiService();
              var ssoApiUrl = apiUrl.getServices("sso");

              return new Promise((resolve, reject) => {
                fetch(
                  `${ssoApiUrl}/v1/en/console/model/ssoTeam/tableColumnDataList/ValueByUuid`
                ) //http://localhost:8001/api/
                  .then((response) => response.json())
                  .then((data) => {
                    resolve(data);
                    const outputData = data;
                    return outputData;
                  })
                  .catch((error) => {
                    reject(error);
                  });
              });
            },
          },

          editable: false,
          formatter: (cell) => {
            return cell.getRow().getData().ssoTeamName;
          },
          //showByDefault: true,
        },

        {
          title: "Name",
          field: "name",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: true,
          selectableColumn: true,
          headerSort: true,
          editable: false,
          //showByDefault: true,
        },

        {
          title: "Position",
          field: "position",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: true,
          selectableColumn: true,
          headerSort: true,
          editable: false,
          //showByDefault: true,
        },

        {
          title: "Email",
          field: "email",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: true,
          selectableColumn: true,
          headerSort: true,
          editable: false,
          //showByDefault: true,
        },

        {
          title: "Phone",
          field: "phone",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: true,
          selectableColumn: true,
          headerSort: true,
          editable: false,
          //showByDefault: true,
        },

        {
          title: "Source",
          field: "leadSourceName",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: true,
          selectableColumn: true,
          headerSort: true,
          editable: false,
          //showByDefault: true,
        },

        {
          title: "Quality",
          field: "leadQuality",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: true,
          selectableColumn: true,
          headerSort: true,
          editable: false,
          //showByDefault: true,
        },

        {
          title: "Status",
          field: "status",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: true,
          selectableColumn: true,
          headerSort: true,
          editorParams: {
            valuesLookup: () => {
              var statusItems = new leadStatus();
              return statusItems.getItems();
            },
          },
          editable: false,
          //showByDefault: false,
        },

        {
          title: "BD",
          field: "salespersonInternalUserUuid",
          dataType: "modelDropdown",
          textColumn: "salespersonInternalUserName",
          apiServiceKey: "sso",
          apiUrl: "/v1/en/console/model/internalUser/",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          headerSort: true,
          editor: "list",
          editorParams: {
            valuesLookup: (cell) => {
              var apiUrl = new apiService();
              var ssoApiUrl = apiUrl.getServices("sso");

              const ssoTeamUuid = cell.getRow().getData().ssoTeamUuid;

              return new Promise((resolve, reject) => {
                fetch(
                  `${ssoApiUrl}/v1/en/console/ssoTeamToInternalUser/ValueByUuid/${ssoTeamUuid}`
                )
                  .then((response) => response.json())
                  .then((data) => {
                    resolve(data);
                    const outputData = data;
                    return outputData;
                  })
                  .catch((error) => {
                    reject(error);
                  });
              });
            },
          },

          formatter: (cell) => {
            return cell.getRow().getData().salespersonInternalUserName;
          },
          editable: false,
          //showByDefault: true,
        },

        {
          title: "Action",
          formatter: (cell) => {
            Vue.use(Vuetify);
            let ComponentClass = Vue.extend(ManageActionColumn);
            let instance = new ComponentClass({
              propsData: {
                cellData: cell.getData(),
                cellValue: cell.getValue(),
                cellTableData: cell.getTable().getData(),
                cellRowData: cell.getRow().getData(),
                serviceKey: "lead",
                modelKey: "leadNew",
                modelId: cell.getRow().getData().id,
              },
              vuetify: new Vuetify({}),
              store,
              router,
            });
            instance.$mount();
            return instance.$el;
          },
        },
      ],

      advanceFilter: {
        columns: [
          {
            name: "name",
            type: "nominal",
            values: [],
          },
          {
            name: "description",
            type: "nominal",
            values: [],
          },
        ],
      },

      pdf: {
        headers: [
          {
            title: "Id",
            field: "id",
          },
          {
            title: "Lead Source Type",
            field: "sourceType",
          },

          {
            title: "Name",
            field: "name",
          },
        ],
      },
    },
  },

  read: {
    tabs: [
      /* {
          key: "demoProduct", //key for tab
          label: "Products",
          dataType: "model",
          relationship: "child",
          modelKey: "demoProduct",
          mapping: {
            parent: "id",
            child: "demoCategoryId",
          },
        },   */
    ],
    table: {
      headers: [
        {
          title: "Id",
          field: "id",
        },

        {
          title: "UUID",
          field: "uuid",
        },

        {
          title: "Source Name",
          field: "leadSourceName",
        },

        {
          title: "Team Name",
          field: "ssoTeamName",
        },

        {
          title: "Lead Assigned To",
          field: "salespersonInternalUserName",
        },

        {
          title: "Company",
          field: "company",
        },

        {
          title: "Name",
          field: "name",
        },

        {
          title: "Position",
          field: "position",
        },

        {
          title: "Email",
          field: "email",
        },

        {
          title: "Phone",
          field: "phone",
        },

        {
          title: "Status",
          field: "status",
        },

        {
          title: "Offer",
          field: "offer",
        },

        {
          title: "Bonus",
          field: "bonus",
        },

        {
          title: "Comment",
          field: "comment",
        },

        {
          title: "Webinar Minutes",
          field: "webinarMinutes",
        },

        {
          title: "Lead Quality",
          field: "leadQuality",
        },

        {
          title: "Assigned Date",
          field: "timestampAssigned",
        },

        {
          title: "Locked Date",
          field: "timestampLocked",
        },

        {
          title: "Won Date",
          field: "timestampWon",
        },

        {
          title: "Created",
          field: "timestampCreated",
        },
        {
          title: "Updated",
          field: "timestampUpdated",
        },
      ],
    },
  },

  add: {
    fields: [
      /* {
        type: "model",
        key: "ssoTeamUuid",
        name: "Team",
        modelKey: "ssoTeam",
        apiListUrl: "/v1/en/console/model/ssoTeam",
        optionText: "name",
        optionValue: "uuid",
        optionTextSaveAs: "ssoTeamName",
      },  */

      /* {
        type: "modelDependency",
        parentKey: "ssoTeamUuid",
        parentName: "Team",
        parentModelKey: "ssoTeam",
        parentApiListUrl: "/v1/en/console/model/ssoTeam",
        parentOptionText: "name",
        parentOptionValue: "uuid",
        parentOptionTextSaveAs: "ssoTeamName",
        childKey: "salespersonInternalUserUuid",
        childName: "Sales Person",
        childModelKey: "ssoTeamToInternalUser",
        childApiListUrl: "/v1/en/console/ssoTeamToInternalUser/list",
        childOptionText: "name",
        childOptionValue: "uuid",
        childOptionTextSaveAs: "salespersonInternalUserName",
      }, */

      {
        type: "modelParent",
        parentKey: "ssoTeamUuid",
        parentName: "Team",
        parentModelKey: "ssoTeam",
        parentApiListUrl: "/v1/en/console/model/ssoTeam",
        parentOptionText: "name",
        parentOptionValue: "uuid",
        parentOptionTextSaveAs: "ssoTeamName",
        modelServiceKey: "lead",
      },

      {
        type: "modelChild",
        childKey: "leadSourceUuid",
        childName: "Source",
        childModelKey: "leadSource",
        childApiListUrl: "/v1/en/console/model/leadSource/ssoTeam",
        childOptionText: "name",
        childOptionValue: "uuid",
        childOptionTextSaveAs: "leadSourceName",
        modelServiceKey: "lead",
        isDisplay: false,
      },

      /* {
        type: "model",
        key: "leadSourceUuid",
        name: "Source",
        modelKey: "leadSource",
        apiListUrl: "/v1/en/console/model/leadSource",
        optionText: "name",
        optionValue: "uuid",
        optionTextSaveAs: "leadSourceName",
      },   */

      {
        type: "string",
        name: "Company Name",
        key: "company",
      },

      {
        type: "string",
        name: "Name",
        key: "name",
      },

      {
        type: "string",
        name: "Position",
        key: "position",
      },

      {
        type: "string",
        name: "Email",
        key: "email",
      },

      {
        type: "string",
        name: "Phone",
        key: "phone",
      },

      {
        type: "modelChild",
        childKey: "salespersonInternalUserUuid",
        childName: "Sales Person",
        childModelKey: "ssoTeamToInternalUser",
        childApiListUrl: "/v1/en/console/ssoTeamToInternalUser/list",
        childOptionText: "name",
        childOptionValue: "uuid",
        childOptionTextSaveAs: "salespersonInternalUserName",
        modelServiceKey: "lead",
        isDisplay: true,
      },

      {
        type: "select",
        name: "Status",
        key: "status",
        options: [
          { text: "New Lead", value: "New Lead", selected: true },
          { text: "Tried to Contact", value: "Tried to Contact" },
          { text: "Contacted", value: "Contacted" },
          { text: "Qualified", value: "Qualified" },
          { text: "Unqualified", value: "Unqualified" },
        ],
      },

      {
        type: "select",
        name: "Lead Quality",
        key: "leadQuality",
        options: [
          { text: "Hight", value: "Hight" },
          { text: "Mid", value: "Mid" },
          { text: "low", value: "low" },
        ],
      },
    ],
    rules: {
      name: ["required"],
      email: ["required", "email"],
      phone: ["required", "mobile"],
      status: ["required"],
      leadQuality: ["required"],
      company: ["required"],
      //salespersonInternalUserUuid: ["required"],
    },
  },

  edit: true,
  delete: true,
  leadMultiAssign: true,
};
