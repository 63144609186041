export default {
  state: {
    data: {
      rolePermissions: [],
    },
  },
  getters: {
    //
  },
  actions: {
    updateRolePermission(context, data) {
      context.commit("updateRolePermission", data);
    },
  },
  mutations: {
    updateRolePermission(state, data) {
      return (state.data = data);
    },
  },
};
