<template>
 <div>
    <v-row no-gutters class="pb-3">
      <v-col md="4">
        <div class="tb-select">
          <v-select
            style="border-radius: 4px 0px 0px 4px; border-right: 0px"
            v-model="column_name"
            :items="columns"
            item-text="title"
            item-value="field"
            label="Table Column"
            dense
            solo
            hide-details
            @change="changeFilterType"
          ></v-select>
        </div>
      </v-col>
      <v-col md="4">
        <div class="tb-select">
          <v-select
            style="border-right: 0px"
            v-model="type"
            :items="this.listFilterTypes"
            item-text="text"
            label="Type"
            dense
            solo
            hide-details
          ></v-select>
        </div>
      </v-col>
      <v-col md="3">
        <div class="tb-text-filed">
          <v-text-field
            v-model="value"
            label="Value"
            :type="this.valueInputType"
            dense
            hide-details
            single-line
            solo
            @keyup.native.enter="addFilter"
          ></v-text-field>
        </div>
      </v-col>

      <v-col md="1">
        <div>
          <v-btn
            :disabled="!value"
            @click="addFilter"
            color="primary"
            class="tb-btn"
          >
            Add
          </v-btn>
        </div>
      </v-col>
    </v-row>
   
    <v-row no-gutters>
      <v-col md="2">
        <div class="tb-filter-applied">
          <h4>Filter Applied:</h4>
        </div>
      </v-col>
      <v-col md="9">
        <div class="tb-select">
          <v-select
            class="with-border"
            v-model="selectedFilters"
            :items="filters"
            label="Filter"
            no-data-text="No filters."
            solo
            dense
            hide-details
            multiple
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 3" color="yellow" small>
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="index === 3" class="grey--text text-caption">
                (+{{ selectedFilters.length - 3 }} others)
              </span>
            </template>
          </v-select>
        </div>
      </v-col>
      <v-col md="1" >
        <div>
          <v-btn
            @click="clearFilter"
            :disabled="filters.length < 1"
            color="#ffffff"
            class="tb-btn-clear"
          >
            Clear
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- <div class="pt-1">
      <b>Filters</b>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
        <span
          >You may apply multiple filters to select the data that you will like
          to view.</span
        >
      </v-tooltip>
    </div> -->
  </div>
</template>

<script>
/* 
The filter will generate condition in json format based on user selected field, type, and value 
*/
import { mapState } from "vuex";
import Filtertypes from "../../services/developer/objects/filtertypes.js";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: {
    model: {
      type: Object,
      required: true,
    },
    setConditions: {
      type: Function,
    },
  },
  data: () => ({
    column_name: null,
    columns: [],
    filters: [],
    type: null,
    selectedFilters: [],
    value: null,

    filtertypes: new Filtertypes(),

    listFilterTypes: [],

    valueInputType: "text",
  }),
  watch: {
    selectedFilters() {
      this.updateConditions();
    },
  },
  created() {
    this.columns = this.$_.filter(this.model.browse.table.headers, (o) => {
      if (o.filterable != false) {
        return o;
      }
    });
    this.column_name = this.columns[0] ? this.columns[0].field : null;
    this.type = "=";
    this.resetCondition();

    this.listFilterTypes = this.filtertypes.getAllFilterTypes();
    this.changeFilterType();
  },
  mounted() {
    //
  },
  methods: {
    addFilter() {
      var selectedColumn = this.$_.find(this.columns, {
        field: this.column_name,
      });

      switch (selectedColumn.dataType) {
        case "float":
          this.value = parseFloat(this.value);
          break;
          
        case "integer":
          this.value = parseInt(this.value,10);
          break;

        case "boolean":
          this.value = this.value == "true" ? true : false;
          break;
        
        case "date":
          this.value = this.$moment(new Date(this.value)).format("YYYY-MM-DD");
          /* this.value = new Date(this.value); */
          break;
      }

      //this.$moment(new Date(exDate)).format("YYYY-MM-DD")

     /*  if(selectedColumn.dataType == "boolean") this.value = this.value == "true" ? true : false;
      if(selectedColumn.dataType == "date") this.value = new Date(this.value);
      if(selectedColumn.dataType == "float") this.value = parseFloat(this.value);
      if(selectedColumn.dataType == "integer") this.value = parseFloat(this.value); */

      var condition = {
        field: selectedColumn.field,
        type: this.type,
        value: selectedColumn.dataType == "date" ? new Date(this.value) : this.value,
      };

      var filter = {
        text: `${selectedColumn.field} ${this.type} ${this.value}`,
        value: JSON.stringify(condition),
      };

      //BOC:check duplicated filter
      if (
        this.$_.findIndex(this.selectedFilters, { value: filter.value }) > -1
      ) {
        alert("Same filter already existed.");
        return;
      }
      //EOC
      this.filters.push(filter);
      this.selectedFilters.push(filter.value);
      this.resetCondition();
    },


    clearFilter() {
      this.filters = [];
      this.selectedFilters = [];
    },

    setFiltersViaConditions(conditions) {
      if (!conditions) return;
      var filters = [];
      for (var condition of conditions) {
        var filter = {
          text: `${condition.field} ${condition.type} ${condition.value} `,
          value: JSON.stringify(condition),
        };
        filters.push(filter);
      }
      this.filters = filters;
      this.selectedFilters = this.$_.map(filters, "value");
    },

    updateConditions() {
      var conditions = [];
      for (var condition of this.selectedFilters) {
        conditions.push(JSON.parse(condition));
      }
      this.setConditions(conditions);
    },

    resetCondition() {
      this.value = null;
    },

    changeFilterType() {
      var selectedColumn = this.$_.find(this.columns, {
        field: this.column_name,
      });

      switch (selectedColumn.dataType) {
        case "float":
          this.listFilterTypes = this.filtertypes.getFilterTypeByType("float");
          this.valueInputType = "number";
          break;

        case "integer":
          this.listFilterTypes =
          this.filtertypes.getFilterTypeByType("integer");
          this.valueInputType = "number";
          break;

        case "boolean":
          this.listFilterTypes =
            this.filtertypes.getFilterTypeByType("boolean");
            this.valueInputType = "text";
          break;

        case "date":
          this.listFilterTypes = this.filtertypes.getFilterTypeByType("date");
          this.valueInputType = "date";
          break;

        case "onlyLikeEqualNotEqual":
          this.listFilterTypes = this.filtertypes.getFilterTypeByType(
            "onlyLikeEqualNotEqual"
          );
          this.valueInputType = "text";
          break;
          
        case "onlyGteLte":
          this.listFilterTypes =
            this.filtertypes.getFilterTypeByType("onlyGteLte");
          this.valueInputType = "date";
          break;
          
        case "onlyEqualNotEqual":
          this.listFilterTypes =
            this.filtertypes.getFilterTypeByType("onlyEqualNotEqual");
          this.valueInputType = "text";
          break;

        case "onlyLike":
          this.listFilterTypes =
            this.filtertypes.getFilterTypeByType("onlyLike");
          this.valueInputType = "text";
          break;

        default:
          this.listFilterTypes = this.filtertypes.getAllFilterTypes();
          this.valueInputType = "text";
          break;
      }
    },
  },
};
</script>