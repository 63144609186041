import ManageActionColumn from "../../../components/common/BreadTabulatorActionButton.vue";

import Vue from "vue";
import Vuetify from "vuetify";
import store from "../../../store";
import router from "../../../router";

export default {
  serviceKey: "crm",
  key: "crmServicePermission",
  column: {
    uid: "key", // tell the system which column to be used as unique id
    text: "name", // tell the system which column to display as text
  },
  name: {
    singular: "Permission",
    plural: "Permissions",
  },
  parents: [
    //
  ],

  apiUrls: {
    apiBrowseUrl: "",
    apiStoreUrl: "",
    apiUpdateUrl: "",
    apiReadUrl: "",
    apiMultiUpdateUrl: "",
    apiTrashedBrowseUrl: "",
    apiSoftDeleteUrl: "",
    apiHardDeleteUrl: "",
    apiRestoreUrl: "",
  },

  permissionKeys: {
    browse: "crm-crmServicePermission-browse",
    read: "crm-crmServicePermission-read",
    add: "crm-crmServicePermission-add",
    edit: "crm-crmServicePermission-edit",
    delete: "crm-crmServicePermission-delete",
    restore: "crm-crmServicePermission-restore",
  },

  recordAccessControl: {
    browse: {
      all: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      own: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      assignedTo: {
        roles: ["salesperson", "sales-lead"],
        apiUrl: "",
      },
      belongsTo: {
        ssoBusiness: {
          roles: ["superadmin"],
          apiUrl: "",
        },
        ssoTeam: {
          roles: ["superadmin"],
          apiUrl: "",
        },
      },
    },
  },

  roleBasedFunctionalities: {
    browse: {
      all: ["superadmin"],
      own: ["salesperson"],
      team: ["sales-lead"],
    },
  },

  browse: {
    table: {
      actions: {
        manageCellEdit: false,
        columnSelectionDisabledCount: 3,
        isActionColumnVisible: true,
        isTrashedTabVisible: true,
      },
      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          headerFilter: false,
          selectableColumn: false,
          visible: true,
          download: false,
        },

        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          visible: true,
          download: false,
        },
        {
          dataType: "modelParent",
          modelKey: "crmService",
          title: "Service",
          field: "crmService",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },
        {
          title: "Key",
          field: "key",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },
        {
          title: "Name",
          field: "name",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },
        {
          title: "Allowed (Default)",
          field: "isAllowedByDefault",
          dataType: "boolean",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },
        /* {
          dataType: "model",
          relationship: "child",
          modelKey: "crmServicePermissionToInternalRole",
          text: "Total Roles",
          value: "crmServicePermissionToInternalRole",
          sortable: false,
          searchable: false,
          filterable: false,
        }, */

        {
          title: "Action",
          formatter: (cell) => {
            Vue.use(Vuetify);
            let ComponentClass = Vue.extend(ManageActionColumn);
            let instance = new ComponentClass({
              propsData: {
                cellData: cell.getData(),
                cellValue: cell.getValue(),
                cellTableData: cell.getTable().getData(),
                cellRowData: cell.getRow().getData(),
                serviceKey: "crm",
                modelKey: "crmServicePermission",
                modelId: cell.getRow().getData().id,
              },
              vuetify: new Vuetify({}),
              store,
              router,
            });
            instance.$mount();
            return instance.$el;
          },
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: "crmServicePermissionToInternalRole", //key for tab
        label: "Roles",
        dataType: "model",
        relationship: "intermediate",
        modelKey: "crmServicePermissionToInternalRole",
        intermediateChildModelKey: "internalRole",
        mapping: {
          parent: "key",
          child: "crmServicePermissionKey",
        },
      },
    ],
    table: {
      headers: [
        {
          title: "Name",
          field: "name",
        },
        {
          title: "Key",
          field: "key",
        },
      ],
    },
  },
  add: false,
  edit: false,
  delete: false,
};
