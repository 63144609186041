export default {
  key: "creditNoteRequested",
  serviceKey: "hrdc",
  name: {
    singular: "Credit Note Requested",
    plural: "Credit Note Requested",
  },
  parents: [
    //
  ],
  add: {
    name: "Credit Note Requested",
  },
  view: {
    name: "Credit Note Requested",
  },
};
