export default {
  key: "grantNoSubmit",
  serviceKey: "hrdc",
  name: {
    singular: "Grant Approval No. Submit",
    plural: "Grant Approval No. Submit",
  },
  parents: [
    //
  ],
  add: {
    name: "Grant Approval No. Submit",
  },
};
