<template>
  <v-simple-table>
    <template v-slot:default>
      <tbody>
        <tr v-for="header in readTableHeaders" :key="header.field">
          <th>
            {{ header.title }}
          </th>
          <td
            v-if="header.dataType == 'model' && header.relationship == 'parent'"
          >
            {{ api.data[0][toFlUc(header.modelKey)]["name"] }}
          </td>

          <td v-if="modelKey == 'leadSource' && header.field == 'customWebhookUrl'">
            {{ showWebhookUrl() }}
          </td>
          <td v-else>
            {{ api.data[0][header.field] }} 
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    //
  }),

  computed: mapState({
    auth: (state) => state.auth.data,
    services: (state) => state.service.data.services,
  }),

  methods: {
    toFlUc(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    showWebhookUrl() {

      let leadSourceUuid = this.api.data[0].uuid;
      let leadSourceType = this.api.data[0].sourceType;
      // remove the wite space and change it to camel case
      leadSourceType = leadSourceType.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index == 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
      
      let webhookApiUrl = `${this.$service['lead']}/v1/en/webhook/${leadSourceUuid}/${leadSourceType}/contactCreated`;
      return webhookApiUrl;
    },


  },
  mounted() {
    //
  },
  props: {
    api: {
      type: Object,
      required: true,
    },
    conditions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    readTableHeaders: {
      type: Array,
      default: () => [],
    },
    modelKey: {
      type: String,
    },
    operation: {
      type: String,
      default: "browse",
    },

    singleDataUrl: {
      type: String,
      default: "",
    },

    //BOC
    parentModelKey: {
      type: String,
      default: null,
    },
    parentModelId: {
      type: Number,
      default: null,
    },
    intermediateChildModelKey: {
      type: String,
      default: null,
    },
    //EOC
  },
  watch: {
    //
  },
};
</script>

<style scoped>
</style>