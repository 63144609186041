export default {
  key: "refundFailed",
  serviceKey: "hrdc",
  name: {
    singular: "Refund Failed",
    plural: "Refund Failed",
  },
  parents: [
    //
  ],
  add: {
    name: "Refund Failed",
  },
  view: {
    name: "Refund Failed",
  },
};
