var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-b py-1 px-3"},[_c('div',{staticClass:"pt-1"},[_c('b',[_vm._v("Filters")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information")])],1)]}}])},[_c('span',[_vm._v("You may apply multiple filters to select the data that you will like to view.")])])],1),_c('div',{staticClass:"py-1 d-flex align-center"},[_c('div',{staticClass:"d-flex",staticStyle:{"width":"calc(100% - 100px)"}},[_c('v-select',{staticClass:"mr-1",staticStyle:{"width":"33.3%"},attrs:{"items":_vm.fields,"label":"Field","outlined":"","dense":"","hide-details":""},model:{value:(_vm.field),callback:function ($$v) {_vm.field=$$v},expression:"field"}}),_c('v-select',{staticClass:"mr-1",staticStyle:{"width":"33.3%"},attrs:{"items":[
            {
              value:'CONTAIN',
            },
            {
              value:'NOT_CONTAIN',
            },
            {
              value:'EQUAL',
            },
            {
              value:'NOT_EQUAL',
            } ],"item-text":"value","label":"Operation","outlined":"","dense":"","hide-details":""},model:{value:(_vm.operation),callback:function ($$v) {_vm.operation=$$v},expression:"operation"}}),_c('v-text-field',{staticClass:"mr-1",staticStyle:{"width":"33.3%"},attrs:{"label":"Value","outlined":"","dense":"","hide-details":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addFilter.apply(null, arguments)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('v-btn',{staticStyle:{"border":"solid thin rgba(0,0,0,0.12) !important","width":"100px"},attrs:{"disabled":!_vm.value,"dense":"","depressed":""},on:{"click":_vm.addFilter}},[_vm._v(" Add ")])],1),_c('div',{staticClass:"py-1 d-flex align-center"},[_c('v-select',{staticClass:"mx-1",attrs:{"prepend-icon":"mdi-filter","items":_vm.filters,"label":"Filter","no-data-text":"No filters.","outlined":"","dense":"","hide-details":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [(index < 3)?_c('v-chip',{attrs:{"color":"yellow","small":""}},[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 3)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedFilters.length - 3)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.selectedFilters),callback:function ($$v) {_vm.selectedFilters=$$v},expression:"selectedFilters"}}),_c('v-btn',{staticStyle:{"border":"solid thin rgba(0,0,0,0.12) !important","min-width":"100px"},attrs:{"disabled":_vm.filters.length < 1,"dense":"","depressed":""},on:{"click":_vm.clearFilter}},[_vm._v(" Clear ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }