<template>
<v-select
  v-model="selectedColumns"
  :items="columns"
  placeholder="Show 0 fields"
  class="mx-1"
  dense
  outlined
  multiple
  hide-details
>
  <!-- BOC:[Select All] -->
  <template v-slot:prepend-item>
    <v-list-item
      ripple
      @mousedown.prevent
      @click="toggle"
    >
      <v-list-item-action>
        <v-icon :color="selectedColumns.length > 0 ? 'indigo darken-4' : ''">
          {{ icon }}
        </v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          Show All
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="mt-2"></v-divider>
  </template>
  <!-- EOC -->
  <template v-slot:selection="{ item, index }">
    <span
      v-if="index === 0"
    >
      <span v-if="selectedColumns.length == columns.length">Show All</span>
      <span v-else>Show {{ selectedColumns.length }} fields</span>
    </span>
  </template>
</v-select>
</template>

<script>
// import { mapState } from 'vuex'
export default {
  components:{
    //
  },
  computed: {
    //BOC:[Select All]
    selectedAll () {
      return this.selectedColumns.length === this.columns.length
    },
    selectedSome () {
      return this.selectedColumns.length > 0
    },
    icon () {
      if (this.selectedAll) return 'mdi-close-box'
      if (this.selectedSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    //EOC
  },
  props:{
    columns: {
      type: Array,
      required: true,
    },
    selectViewableColumns: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    selectedColumns:[],
  }),
  created() {
    this.selectAll()
  },
  mounted() {
    //
  },
  watch: {
    selectedColumns: {
      handler () {
        this.selectViewableColumns(this.selectedColumns);
      },
      deep: true,
    },
  },
  methods: {
    //BOC:[Select All]
    toggle () {
      if(this.selectedAll) {
        this.selectedColumns = []
      } else {
        this.selectAll()
      }
    },
    selectAll() {
      this.selectedColumns = this.$_.map(this.columns,'value')
    },
    //EOC
  }
}
</script>