<template>
  <div>HI</div>
</template>

<script>
export default {
  data: () => ({
      //
  }),
  methods: {
      //
  },
  mounted() {
      //
  },
  props: {
      //
  },
  watch: {
      //
  },
};

</script>

<style scoped>
</style>