// This class is used to get the loged user authentication details
import store from "@/store";

export default class UserAuth{
    constructor() {
        this.user = store.state.auth.data;
    }

    getUserDetails() {
        return this.user;
    }

    getUserUuid() {
        return this.user.uuid;
    }

    checkUserRoleExists(assignRole) {
        //return this.user.roles.includes(role);
        var userRoles = this.user.roles.map((role) => {
            return role.slug;
          });

        return userRoles.includes(assignRole);
    }

}