<template>
  <div>
    <ATabulatorLoder :isLoading="isLoading" />
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on">
          Export As<v-icon right>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="downloadData('csv','all')"
          ><v-list-item-title>CSV</v-list-item-title></v-list-item
        >
        <v-list-item @click="downloadData('xlsx','all')"
          ><v-list-item-title>Excel</v-list-item-title></v-list-item
        >
        <v-list-item @click="downloadData('pdf','all')"
          ><v-list-item-title>PDF</v-list-item-title></v-list-item
        >

        <v-list-group :value="true">
          <template v-slot:activator>
            <v-list-item-title>All Data</v-list-item-title>
          </template>

          <v-list-item @click="downloadAllData('xlsx')"
            ><v-list-item-title>Excel</v-list-item-title></v-list-item
          >

          <v-list-item @click="downloadAllData('pdf')"
            ><v-list-item-title>PDF</v-list-item-title></v-list-item
          >
        </v-list-group>
      </v-list>
    </v-menu>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on">
          Selected<v-icon right>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="downloadData('csv','selected')"
          ><v-list-item-title>Export CSV</v-list-item-title></v-list-item
        >

        <v-list-item @click="downloadData('xlsx','selected')"
          ><v-list-item-title>Export Excel</v-list-item-title></v-list-item
        >

        <v-list-item @click="downloadData('pdf','selected')">
          <v-list-item-title>Export PDF</v-list-item-title>
        </v-list-item>


      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Api from "@/objects/api";
import Service from "@/objects/service";
import { json2excel } from "js2excel";
import { mapState } from "vuex";
import jsPDF from "jspdf";
//EOC
//BOC:[autoTable]
import autoTable from "jspdf-autotable";
//EOC

export default {
  name: "BreadTabulatorExport",
  data: () => ({
    api: new Api(),
    service: new Service(),
    isLoading: false,
  }),

  computed: mapState({
    auth: (state) => state.auth.data,
    services: (state) => state.service.data.services,
  }),

  props: {
    model: {
      type: Object,
      required: true,
    },
    apiUrl: {
      type: String,
      required: true,
    },
  },

  methods: {
    
    downloadData(fileType, rowsType){
        this.$emit("downloadData", fileType, rowsType);
    },

    downloadAllData(downloadType) {
      this.isLoading = true;
      var allRecords = [];
      var page = 0;
      var totalPages = 0;

      this.api.setMethod(`GET`);
      this.api.setUrl(this.apiUrl);
      this.api.setCallbackCompleted((response) => {
        totalPages = response.info.totalPages;
        allRecords = response.data;
        page = page + 1;
        this.downloadAllDataLoop(page, totalPages, allRecords, downloadType);
      });
      this.api.fetch();
    },

    downloadAllDataLoop(page, totalPages, allRecords, downloadType) {
      if (page <= totalPages) {
        this.api.setMethod(`GET`);
        this.api.setUrl(`${this.apiUrl}?page=${page}`);
        this.api.setCallbackCompleted((response) => {
          allRecords = [...allRecords, ...response.data];
          page = page + 1;
          this.downloadAllDataLoop(page, totalPages, allRecords, downloadType);
        });
        this.api.fetch();
      } else {
        // untile all records are fetched and download the file add a loader
        this.isLoading = false;
        if (downloadType == "xlsx") {
          try {
            json2excel({
              data: allRecords,
              name: this.model.name.plural,
              formateDate: "yyyy/mm/dd",
            });
          } catch (e) {
            console.log(e);
          }
        }

        if(downloadType == "pdf"){
            const pdfData = new jsPDF();
            const headerArray = [];
            const dataArray = [];
            this.model.browse.table.pdf.headers.map((header) => {
                headerArray.push(header.title);
            });

            allRecords.map((record) => {

                const recordArray = [];
                this.model.browse.table.pdf.headers.map((header) => {
                    recordArray.push(record[header.field]);
                });
                dataArray.push(recordArray);
            });


            autoTable(pdfData, {
                head: [headerArray],
                body: dataArray,
            });

            pdfData.save(`${this.model.name.plural}.pdf`);
        }

      }
    },
  },
};
</script>