var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex ma-2"},[_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"to":{
          name: 'PageServiceModelChildAdd',
          params: {
            parentModelKey: _vm.parentModel.key,
            parentModelId: _vm.parent.id,
            childModelKey: _vm.childModel.key,
          },
        }}},[_vm._v("New "+_vm._s((_vm.childModel.name[_vm.parentModel.key]) ? _vm.childModel.name[_vm.parentModel.key].singular : _vm.childModel.name.singular))])],1),_c('BreadDataTable',{ref:"dataTable",attrs:{"modelKey":_vm.childModel.key,"api":_vm.api,"conditions":_vm.conditions,"sortBy":[],"sortDesc":[],"table":_vm.table,"showViewableColumns":false,"showSearch":false,"parentModelKey":_vm.parentModel.key,"parentModelId":_vm.parent.id}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }