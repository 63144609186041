<template>
  <div>
    <v-select
      v-model="value"
      :label="field.name"
      :items="items"
      :item-text="field.optionText ? field.optionText : 'name'"
      :item-value="field.optionValue ? field.optionValue : 'id'"
      :loading="api.isLoading"
      :rules="rules"
      :disabled="disabled"
    />

    <v-text-field
      v-model="form.data[field.optionTextSaveAs]"
      :label="field.name"
      v-if="isShow"
    />

  </div>

  </template>
  
  <script>
  //BOC:[api]
  import Api from "@/objects/api";
  //EOC
  //BOC:[model]
  import Model from "@/objects/model";
  //EOC
  //BOC:[service]
  import Service from "@/objects/service";
  //EOC
  import { mapState } from "vuex";
  export default {
    components: {
      //
    },
    computed: {
      ...mapState({
        //
      }),
    },
    props: {
      field: {
        type: Object,
      },
      rules: {
        type: Array,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      form: {
        type: Object,
      },
    },
    watch: {
      value(newValue) {
        if(this.field.optionTextSaveAs){
            let modelValue = newValue;
            let optionTextValue = this.items.find(item => item[this.field.optionValue] === modelValue);
            this.form.setDataProperty(this.field.optionTextSaveAs, optionTextValue[this.field.optionText]);
        }
        this.$emit("input", newValue);
      },
    },
    data: () => ({
      //BOC:[api]
      api: new Api(),
      //EOC
      //BOC:[model]
      model: new Model(),
      //EOC
      //BOC:[service]
      service: new Service(),
      //EOC
      //BOC:[select]
      items: [],
      //EOC
      value:null,
      isShow: false,
    }),
    created() {
      //BOC:[model]
      this.model.getByKey(this.field.modelKey);
      //EOC
      //BOC:[service]
      if(this.model.serviceKey) this.service.set("key",this.model.serviceKey);
      //EOC
      //BOC:[api]
      if(this.field.apiListUrl) {
        this.api.setUrl(
        `${this.$service[this.service.key]}${this.field.apiListUrl}`
      );
      }else{
        this.api.setUrl(
        `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${this.field.modelKey}/upsert/dropdown`
      );
      }
      
      this.api.setCallbackCompleted((response) => {
        this.items = response.data;
        //BOC: get the value
            if(this.form.data) {
            this.value = this.form.data[this.$_ucfirst(this.field.modelKey)]
            //BOC: to overwrite the value in form excluding all other properties
            const prop = `${this.field.modelKey}${this.$_ucfirst(this.field.optionValue)}`
            const val = this.value[this.field.optionValue]
            var that = this
            setTimeout(()=>{
              that.form.setDataProperty(prop, val)
            },100)
            //EOC
          }
        //EOC
      });
      //EOC
      //BOC
      this.api.fetch();
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      //
    },
  };
  </script>