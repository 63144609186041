<template>
<v-select
  v-model="select"
  :items="items"
  :rules="[v => !!v || 'Item is required']"
  :label="label"
  required
></v-select>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:{
      select:{
        type:String,
        default:null,
      },
      items:{
        type:Array,
        default:()=>['Item 1','Item 2','Item 3'],
      },
      label:{
        type:String,
        default:"Label",
      },
    },
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>