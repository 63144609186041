export default [
  {
    name: "Requests",
    icon: "mdi-database",
    route: {
      name: "PageServiceModelBrowse",
      params: { serviceKey: "log", modelKey: "logRequest" },
      query: {
        viewId: "all",
      },
    },
  },
];
