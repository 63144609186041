//BOC:[crm]
import crm from "@/services/crm/config/menu.js"
//EOC
//BOC:[developer]
import developer from "@/services/developer/config/menu.js"
//EOC
//BOC:[event]
import event from "@/services/event/config/menu.js"
//EOC
//BOC:[hrdc]
import hrdc from "@/services/hrdc/config/menu.js"
//EOC
//BOC:[lead]
import lead from "@/services/lead/config/menu.js"
//EOC
//BOC:[log]
import log from "@/services/log/config/menu.js"
//EOC
//BOC:[marketing]
import marketing from "@/services/marketing/config/menu.js"
//EOC
//BOC:[product]
import product from "@/services/product/config/menu.js"
//EOC
//BOC:[profiling]
import profiling from "@/services/profiling/config/menu.js"
//EOC
//BOC:[sales]
import sales from "@/services/sales/config/menu.js"
// //EOC
//BOC:[sso]
import sso from "@/services/sso/config/menu.js"
//EOC
//BOC:[testing]
import testing from "@/services/testing/config/menu.js"
//EOC
export default {
  main: [
    {
      name: 'My Workspace',
    },
    //BOC:[lead]
    {
      name: 'My Leads',
      icon: 'mdi-database',
      route: { name: 'PageServiceModelBrowse', params: { serviceKey: "lead", modelKey: 'myLead' } },
      disabled: true,
    },
    //EOC
    {
      type: 'divider',
    },
  ],
  crm,
  developer,
  event,
  hrdc,
  lead,
  log,
  marketing,
  product,
  profiling,
  sales,
  sso,
  testing,
}