export default {
    state: {
      data: {
        uuid: "",
      },
    },
    getters: {
      //
    },
    actions: {
      updateTeam(context, data) {
        context.commit("updateTeam", data);
      },
    },
    mutations: {
      updateTeam(state, data) {
        return (state.data = data);
      },
    },
  };
  