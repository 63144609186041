export default [
  {
    name: 'Events',
  },
  {
    name: 'Events',
    icon: 'mdi-database',
    route: { name: 'PageServiceModelBrowse', params: { modelKey: 'account' } },
    disabled: true,
  },
  {
    name: 'Venues',
    icon: 'mdi-database',
    route: { name: 'PageServiceModelBrowse', params: { modelKey: 'account' } },
    disabled: true,
  },
  {
    name: 'Video Conferences',
    icon: 'mdi-database',
    route: { name: 'PageServiceModelBrowse', params: { modelKey: 'account' } },
    disabled: true,
  },
  {
    name: 'Speakers',
    icon: 'mdi-database',
    route: { name: 'PageServiceModelBrowse', params: { modelKey: 'account' } },
    disabled: true,
  },
  {
    name: 'Topics',
    icon: 'mdi-database',
    route: { name: 'PageServiceModelBrowse', params: { modelKey: 'account' } },
    disabled: true,
  },
  {
    name: 'Registrants',
    icon: 'mdi-database',
    route: { name: 'PageServiceModelBrowse', params: { modelKey: 'account' } },
    disabled: true,
  },
  {
    name: 'Attendances',
    icon: 'mdi-database',
    route: { name: 'PageServiceModelBrowse', params: { modelKey: 'account' } },
    disabled: true,
  },
  {
    type: 'divider',
  },
]