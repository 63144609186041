<template>
  <div
    ref="table"
    :layout="layout"
    :apiUrl="apiUrl"
    :paginationSize="paginationSize"
    :autoColumns="autoColumns"
    :columns="columns"
    :paginationSizeSelector="paginationSizeSelector"
    :modelKey="modelKey"
  >
  </div>
</template>

<script>
import { TabulatorFull as Tabulator } from "tabulator-tables"; //import Tabulator library
 
//BOC:[api]
import Api from "@/objects/api";
//EOC

import store from '@/store';

//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC

export default {
  name: "BreadTabulator",

  
  props: {
    /* params: {
      type: Object,
    }, */
    apiUrl: {
      type: String,
      default: "",
    },
    layout: {
      type: String,
      default: "",
    },

    paginationSize: {
      type: Number,
      default: 10,
    },
    autoColumns: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    paginationSizeSelector: {
      type: Array,
      default: () => [10, 20, 30, 40, 50],
    },

    modelKey: {
      type: String,
      default: "",
    },

    columnSelectionDisabledCount: {
      type: Number,
      default: 0,
    },
    responsiveLayout: {
      type: Boolean,
      default: true,
    },

    

    /* childTableQuery: {
      type: Array,
      default: () => [],
    }, */
  },
  data: () => ({
    tabulator: null, //variable to hold your table

    //BOC:[api]
    api: new Api(),
    //EOC
    //BOC:[model]
    model: new Model(),
    //EOC
    //BOC:[service]
    service: new Service(),
    //EOC

    tableResponseStatus: "",

    condition: "",
    tableTotalRows: 0,
    tableCurrentPage:1,
  }),

  created() {
    //this.model.getByKey(this.params.modelKey); // using dummy data
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
  },

  mounted() {
    this.tabulator = new Tabulator(this.$refs.table, {
      pagination: true,
      layout: this.layout,
      responsiveLayout: this.responsiveLayout,
      frozenRows: true,
      layoutColumnsOnNewData: true,
      validationMode:"highlight",
      /* autoResize:true, */
      resizableColumnFit:true,
      paginationMode: "remote", //enable remote pagination
      ajaxURL: this.apiUrl, //set url for ajax request
      ajaxConfig:{
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.state.auth.data.token,
        },
      },
      sortMode: "remote", //enable remote sort
      filterMode: "remote", //enable remote filter
      paginationSize: this.paginationSize, //optional parameter to request a certain number of rows per page
      //paginationInitialPage: 1, //optional parameter to set the initial page to load
      paginationButtonCount: 3,
      autoColumns: this.autoColumns,
      columns: this.columns,
      paginationSizeSelector: this.paginationSizeSelector,
      movableColumns: true,
      placeholder: "No Record Found",
      footerElement:"<span id='crm_footer_count'></span>", 
      columnHeaderSortMulti: true,
      /* pageLoaded: function(pageno) {
        this.updateUrlVueRouter(pageno, this.tabulator.getPageSize());
      }, */

      ajaxResponse: function (url, params, response) {
        /* if(response.status !== 500){ */
        // Must configure with server side response
        let last_page = response.info.totalPages;
        let totalItems = response.info.totalItems;
        const page = params.page || 1;
        const pageSize = params.size || 10;
        const startIndex = (page - 1) * pageSize;

        //console.log("response", response);

        // Modify the response data to include row numbers
        const rows = response.data || [];
        rows.forEach((row, index) => {
          row.rownum = startIndex + index + 1;
        });

        return {
          //data: response.data,
          data: rows,
          last_page:last_page,
          last_row:totalItems,
        };
      /* }else{
        return {
          data: [],
          last_page:0,
          last_row:0,
        };
      } */
      },
      paginationCounter: function (
        pageSize,
        currentRow,
        currentPage,
        totalRows,
        totalPages
      ) {
        /* document.getElementById('record-count').textContent = `${totalRows} results found`; 
        document.getElementById("crm_footer_count").textContent = `Showing ${currentPage} - ${totalPages} pages`; */
        //window.location.toString = `&page=${currentPage}&size=${pageSize}`;
        return `showing ${currentPage} - ${totalPages} pages, ${totalRows} results found`;
        
      },

     //paginationCounterElement:"#page-count", //add an element to hold the current page
      
    });

    this.tabulator.on("cellEdited", (cell) => {
      this.updateCellChanges(cell);
    });

    this.tabulator.on("dataProcessed", (data) => {
      //console.log("dataProcessed", data);
      return this.$emit('response', data);
    });
    
    this.tabulator.on("pageSizeChanged", (pagesize) => {
        //this.getCurrentPageSize(pagesize);
        this.$emit("getCurrentPageSize", pagesize); // emit to parent
    });

    this.tabulator.on("dataSorting", (sorters) => {
      //this.getCurrentSort(sorters);
      this.$emit("getCurrentSort", sorters); 
    });

    this.tabulator.on("columnResized", (column) => {
      this.$emit("getCurrentColumnWidth", column); // emit to parent
    });

    this.tabulator.on("pageLoaded", () => {

     // const urlParams = new URLSearchParams(window.location.search); 
      //const urlPageNo = urlParams.get('page');
      //const urlPageSize = urlParams.get('pageSize');

      var pageSize = this.tabulator.getPageSize();
      var pageno = this.tabulator.getPage();

      //console.log("location URL", window.location.href, 'location url search', window.location.search);

      //console.log("pageLoaded", pageno, pageSize, urlPageNo, urlPageSize);

      //alert(urlPageNo);
      
      this.updateUrlParams(pageno, pageSize); 
      //this.updateUrlVueRouter(pageno, pageSize);
    }); 

    /* this.tabulator.on("tableBuilt", function(){
      const urlParams = new URLSearchParams(window.location.search); 
      const page = urlParams.get('page');
      const pageSize = urlParams.get('pageSize');
      console.log("tableBuilt", page, pageSize);
    }); */

    // get the selected rows and emit to parent
    this.tabulator.on("rowSelectionChanged", (data) => {
      this.$emit("getSelectedRows", data); // emit to parent
    });

    this.tabulator.on("dataLoadError", (error) => {
      //console.log("dataLoadError", error);
      this.$emit("getDataLoadErrorCode", error);
    });

    

    /* this.tabulator.on("dataLoadError", function(error){
      console.log("dataLoadError", error);
      //this.$emit("getDataLoadErrorCode", error);
    }); */

// Load the initial data and set the initial page based on URL params
    /* window.addEventListener('load', function() {
      const urlParams = new URLSearchParams(window.location.search);
      console.log("urlParams", urlParams);
      const page = urlParams.get('page') || 1;
      //console.log("page", page);
      const pageSize = urlParams.get('pageSize') || 10;
      //console.log("pageSize", pageSize, page);
      this.tabulator.setPageSize(pageSize);
      this.tabulator.setPage(page); 
    });   */

    /* this.$nextTick(() => {
      const urlParams = new URLSearchParams(window.location.search); 
      const page = urlParams.get('page') || 1;
      const pageSize = urlParams.get('pageSize') || 10;
      this.tabulator.setPageSize(pageSize);
      this.tabulator.setPage(page);

      console.log("urlParams", page, pageSize);
    }); */

  },
  methods: {
    getFilterCondition(filterData) {
      this.tabulator.setFilter(filterData);
    },

    setAdvancedFilterConditions(advanceFilterParams) {
      this.tabulator.setData(this.apiUrl, {
        advanceFilterParam: JSON.stringify(advanceFilterParams),
      });
    },

    applyColumnSelection(selectedColumns) {

      let defaultColumnsFalse = this.columns.filter((column) => {
        if(column.showByDefault == false) {
          return column;
        }
      });

      if (selectedColumns.length == this.columns.length - (this.columnSelectionDisabledCount+defaultColumnsFalse.length)) {
        // hide if all default false columns
        //console.log("all columns selected", this.columns);
        this.tabulator.setColumns(this.columns);
        for(let column in defaultColumnsFalse) {
          this.tabulator.hideColumn(defaultColumnsFalse[column].field);
        }
        return;
      } 

      for(let column in this.columns) {
        if(selectedColumns.includes(this.columns[column].field)) {
          this.tabulator.showColumn(this.columns[column].field);
        } else {
          this.tabulator.hideColumn(this.columns[column].field);
        }
      }

    },

    updateCellChanges(cell) {
      this.$emit("updateEditedCellData", cell); // emit to parent
    },


    /* setAdvanceFilterViewConditions(advanceFilterViewParams) {
      this.tabulator.setData(this.apiUrl, {
        advanceFilterParam: advanceFilterViewParams,
      });
    }, */

    callApiUrl(setApiUrl) {
      this.tabulator.setData(setApiUrl);
    },

    getCurrentApiUrl() {
      let currentApiUrl = this.tabulator.getAjaxUrl();
      console.log("currentApiUrl", currentApiUrl);
    },

    callSavedPageSize(pageSize) {
      this.tabulator.setPageSize(pageSize);
    },

    callSavedColumnSort(columnSort) {
      this.tabulator.setSort(columnSort);
    },

    callSavedHeaderWidth(columnWidth) {

      //console.log(columnWidth);

      if(columnWidth.length == 0){
        this.columns.forEach((column) => {
          column.width = "";
        });
      }

      for(let column in this.columns) {
        for (let key in columnWidth) {
          if(this.columns[column].field === columnWidth[key].field) {
            this.columns[column].width = columnWidth[key].width;
          }
        }
      }

    },

    setCurrentPagNumber(pageNo) {
      this.tabulator.setPage(pageNo);
    },

    setCurrentPagSize(pageSize) {
      this.tabulator.setPageSize(pageSize);
    },

    //BOC:[update table data]
    updateTableRowData(rowId, rowname, rowvalue) {
      this.tabulator.updateData([{ id: rowId, [rowname]: rowvalue }]);
      //console.log("row updated", rowId, rowname, rowvalue);
    },

    // clear table data
    clearTableData() {
      this.tabulator.setData([]);
      this.tabulator.clearData();
    },

    // Function to update the URL with page number and page size
    updateUrlParams(page, pageSize) {
      const url = new URL(window.location);
      url.searchParams.set('page', page);
      url.searchParams.set('pageSize', pageSize);
      /* this.$emit("currentPageInTheUrl", page); */
      window.history.pushState({}, '', url);
    },

    updateUrlVueRouter(page, pageSize) {
      this.$router.push({ query: { page: page, pageSize: pageSize } });
    },

  },
};
</script>