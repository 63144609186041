export default {
  serviceKey: "developer",
  key: "demoOrder",
  column: {
    uid: "id", // tell the system which column to be used as unique id
    text: "orderNo", // tell the system which column to display as text
  },
  name: {
    singular: "Order",
    plural: "Orders",
  },
  parents: [
    //
  ],

  apiUrls: {
    apiBrowseUrl: "",
    apiStoreUrl: "",
    apiUpdateUrl: "",
    apiReadUrl: "",
    apiMultiUpdateUrl: "",
    apiTrashedBrowseUrl: "",
    apiSoftDeleteUrl: "",
    apiHardDeleteUrl: "",
    apiRestoreUrl: "",
},

permissionKeys: {
  browse: "developer-access",
  read: "developer-access",
  add: "developer-access",
  edit: "developer-access",
  delete: "developer-access",
  restore: "developer-access",
},

roleBasedFunctionalities: {
  browse: {
    all: ['superadmin'],
    own: ['salesperson'],
    team: ['sales-lead'],
  },
},

  browse: {
    table: {
      actions: {
        manageCellEdit: true,
        columnSelectionDisabledCount: 3,
        isActionColumnVisible: true,
        isTrashedTabVisible: true,
      },
      
      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          selectableColumn: false,
          headerFilter: false,
          download: false,
          editable: false,
        },

        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          hozAlign: "left",
          download: false,
          editable: false,
        },

        {
            title: "Parent",
            field: "demoCustomerId",
            dataType: "modelParent",
            modelKey: "demoCustomer",
            filterable: false,
            searchable: false,
            headerFilter: false,
            headerSort: true,
            selectableColumn: true,
            hozAlign: "left",
            editable: false,
          },

        {
          title: "Order Date",
          field: "orderDate",
          dataType: "date",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          editor: "date",
          editable: false,
        },

        {
          title: "Order No",
          field: "orderNo",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          editor: "input",
          editable: false,
        },

        {
          title: "Shipped Date",
          field: "shippedDate",
          dataType: "date",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          input: "date",
          editable: false,
        },

        {
          title: "Shipping Fees",
          field: "shippingFee",
          dataType: "number",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          input: "number",
          editable: false,
        },

        {
          title: "Payment Type",
          field: "paymentType",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          editor: "list",
          editorParams: {
            values: ["Cash", "Bank", "Cheque"],
          },
          editable: false,
        },

        {
          title: "Paid Date",
          field: "paidDate",
          dataType: "date",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          input: "date",
          editable: false,
        },

        {
          title: "Order Amount",
          field: "orderAmount",
          dataType: "number",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          input: "number",
          editable: false,
        },

        {
          title: "Order Status",
          field: "status",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          editor: "list",
          editorParams: {
            values: ["Pending", "Processing", "Completed", "Cancelled"],
          },
          editable: false,
        },
      ],
      advanceFilter: {
        columns: [
          {
            name: "orderDate",
            type: "nominal",
            values: [],
          },
          {
            name: "shippedDate",
            type: "nominal",
            values: [],
          },
        ],
      },

      pdf: {
        headers: [
          {
            title: "Id",
            field: "id",
          },

          {
            title: "Order No",
            field: "orderNo",
          },
          {
            title: "Order Date",
            field: "orderDate",
          },

          {
            title: "Shipped Date",
            field: "shippedDate",
          },

          {
            title: "Shipping Fee",
            field: "shippingFee",
          },

          {
            title: "Payment Type",
            field: "paymentType",
          },

          {
            title: "Paid Date",
            field: "paidDate",
          },

          {
            title: "Order Amount",
            field: "orderAmount",
          },

          {
            title: "Order Status",
            field: "status",
          },
        ],
      },
    },
  },

  read: {
    tabs: [
      {
        key: "demoOrderToDemoProduct", //key for tab
        label: "Products", // label to be shown on tab
        dataType: "model",
        relationship: "intermediate",
        modelKey: "demoOrderToDemoProduct",
        intermediateChildModelKey: "demoProduct",
        mapping: {
          parent: "id",
          child: "demoOrderId",
        },
      },
    ], 
    table: {
      headers: [
        {
          title: "Id",
          field: "id",
        },

        {
          title: "Order No",
          field: "orderNo",
        },

        {
          title: "Order Date",
          field: "orderDate",
        },

        {
          title: "Shipped Date",
          field: "shippedDate",
        },

        {
          title: "Shipping Fee",
          field: "shippingFee",
        },

        {
          title: "Payment Type",
          field: "paymentType",
        },

        {
          title: "Paid Date",
          field: "paidDate",
        },

        {
          title: "Order Amount",
          field: "orderAmount",
        },

        {
          dataType: "model",
          relationship: "parent",
          modelKey: "demoCustomer",
          title: "Customer Name",
          field: "demoCustomerId",
        },

        {
          title: "Notes",
          field: "notes",
        },

        {
          title: "Tax Rate",
          field: "taxRate",
        },

        {
          title: "Tax Status",
          field: "taxStatus",
        },

        {
          title: "Status",
          field: "status",
        },

        {
          title: "Created",
          field: "timestampCreated",
        },
        {
          title: "Updated",
          field: "timestampUpdated",
        },
      ],
    },
  },

  add: {
    fields: [
      {
        type: "model",
        key: "demoCustomerId",
        name: "Customer",
        modelKey: "demoCustomer",
        optionText: "name",
        optionValue: "id",
      },

      {
        type: "string",
        name: "Order No",
        key: "orderNo",
      },

      {
        type: "date",
        name: "Order Date",
        key: "orderDate",
      },

      {
        type: "date",
        name: "Shipped Date",
        key: "shippedDate",
      },

      {
        type: "float",
        name: "Shipping Fee",
        key: "shippingFee",
      },

      {
        type: "select",
        name: "Payment Type",
        key: "paymentType",
        options: [
          {
            text: "Cash",
            value: "Cash",
          },
          {
            text: "Bank",
            value: "Bank",
          },
          {
            text: "Cheque",
            value: "Cheque",
          },
        ],
      },

      {
        type: "date",
        name: "Paid Date",
        key: "paidDate",
      },

      {
        type: "float",
        name: "Order Amount",
        key: "orderAmount",
      },

      {
        type: "textarea",
        name: "Notes",
        key: "notes",
      },

      {
        type: "number",
        name: "Tax Rate",
        key: "taxRate",
      },


      {
        type: "select",
        name: "Tax Status",
        key: "taxStatus",
        options: [
          {
            text: "Taxable",
            value: "Taxable",
          },
          {
            text: "Non-Taxable",
            value: "Non-Taxable",
          },
        ],
      },

      {
        type: "select",
        name: "Status",
        key: "status",
        options: [
          {
            text: "Pending",
            value: "Pending",
          },
          {
            text: "Processing",
            value: "Processing",
          },
          {
            text: "Completed",
            value: "Completed",
          },
          {
            text: "Cancelled",
            value: "Cancelled",
          },
        ],
      },
    ],
    rules: {
      orderNo: ["required"],
      demoCustomerId: ["required"],
      orderDate: ["required"],
      shippedDate: ["required"],
      shippingFee: ["required"],
      paidDate: ["required"],
    },
  },
  edit: true,
  delete: true,
};
