import ManageActionColumn from "../../../components/common/BreadTabulatorActionButton.vue";

import Vue from "vue";
import Vuetify from "vuetify";
import store from "../../../store";
import router from "../../../router";

export default {
  serviceKey: "crm",
  key: "crmService",
  column: {
    uid: "key", // tell the system which column to be used as unique id
    text: "name", // tell the system which column to display as text
  },
  name: {
    singular: "Service",
    plural: "Services",
  },
  parents: [
    //
  ],

  apiUrls: {
    apiBrowseUrl: "",
    apiStoreUrl: "",
    apiUpdateUrl: "",
    apiReadUrl: "",
    apiMultiUpdateUrl: "",
    apiTrashedBrowseUrl: "",
    apiSoftDeleteUrl: "",
    apiHardDeleteUrl: "",
    apiRestoreUrl: "",
  },

  permissionKeys: {
    browse: "crm-crmService-browse",
    read: "crm-crmService-read",
    add: "crm-crmService-add",
    edit: "crm-crmService-edit",
    delete: "crm-crmService-delete",
    restore: "crm-crmService-restore",
  },

  recordAccessControl: {
    browse: {
      all: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      own: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      assignedTo: {
        roles: ["salesperson", "sales-lead"],
        apiUrl: "",
      },
      belongsTo: {
        ssoBusiness: {
          roles: ["superadmin"],
          apiUrl: "",
        },
        ssoTeam: {
          roles: ["superadmin"],
          apiUrl: "",
        },
      },
    },
  },

  roleBasedFunctionalities: {
    browse: {
      all: ["superadmin"],
      own: ["salesperson"],
      team: ["sales-lead"],
    },
  },

  browse: {
    table: {
      actions: {
        manageCellEdit: false,
        columnSelectionDisabledCount: 3,
        isActionColumnVisible: true,
        isTrashedTabVisible: true,
      },
      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          headerFilter: false,
          selectableColumn: false,
          visible: true,
          download: false,
        },

        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          visible: true,
          download: false,
        },
        {
          title: "Name",
          field: "name",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },
        {
          title: "Key",
          field: "key",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },
        {
          title: "Repository",
          field: "repository",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },
        {
          title: "Port",
          field: "port",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },
        {
          title: "Published",
          field: "isPublished",
          dataType: "boolean",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },
        {
          title: "Enabled",
          field: "isEnabled",
          dataType: "boolean",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },
        {
          title: "Order",
          field: "sort",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },

        {
          title: "Total Permissions",
          field: "_count.CrmServicePermission",
          dataType: "interger",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
        },

        /* {
          dataType: "model",
          relationship: "child",
          modelKey: "crmServicePermission",
          text: "Total Permissions",
          value: "crmServicePermission",
          sortable: false,
          searchable: false,
          filterable: false,
        }, */
        {
          title: "Action",
          formatter: (cell) => {
            Vue.use(Vuetify);
            let ComponentClass = Vue.extend(ManageActionColumn);
            let instance = new ComponentClass({
              propsData: {
                cellData: cell.getData(),
                cellValue: cell.getValue(),
                cellTableData: cell.getTable().getData(),
                cellRowData: cell.getRow().getData(),
                serviceKey: "crm",
                modelKey: "crmService",
                modelId: cell.getRow().getData().id,
              },
              vuetify: new Vuetify({}),
              store,
              router,
            });
            instance.$mount();
            return instance.$el;
          },
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: "crmServicePermission", //key for tab
        label: "Permissions",
        dataType: "model",
        relationship: "child",
        modelKey: "crmServicePermission",
        mapping: {
          parent: "key",
          child: "crmServiceKey",
        },
      },
    ],
    table: {
      headers: [
        {
          title: "Name",
          field: "name",
        },
        {
          title: "Key",
          field: "key",
        },
      ],
    },
  },
  add: false,
  edit: false,
  delete: false,
};
