var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","color":"#063058","dark":"","dense":"","outlined":"","elevation":"0","elevate-on-scroll":"","height":"60"}},[_c('v-btn',{staticClass:"px-1 border-0",attrs:{"text":"","exact":"","exact-active-class":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'PageConsoleDashboard' })}}},[_vm._v(_vm._s(_vm.$app.name))]),_c('v-chip',{staticClass:"ml-1",staticStyle:{"font-size":"8px"},attrs:{"x-small":"","color":"blue darken-4"}},[_vm._v(_vm._s(_vm.$version))]),(_vm.api.isLoading && _vm.services.length < 1)?_c('v-progress-linear',{staticClass:"mx-2",staticStyle:{"width":"30px"},attrs:{"indeterminate":"","color":"white","rounded":""}}):_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"px-1"},[_vm._v("/")]),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-1",attrs:{"text":"","dark":"","elevation":"0"},on:{"click":function($event){if (_vm.currentService)
                  { _vm.$router.push({
                    name: 'PageServiceDashboard',
                    params: { serviceKey: _vm.currentService.key },
                  }); }}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.currentService ? _vm.currentService.name : "Services")+" ▼ ")])]}}])},[_c('v-list',{attrs:{"dense":"","nav":""}},_vm._l((_vm.services),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.route,"disabled":!item.isEnabled || !item.isAllowed}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1),(_vm.currentService && _vm.menu)?_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"px-1"},[_vm._v("/")]),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-1",attrs:{"text":"","dark":"","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v(" Modules ▼ ")])]}}],null,false,929545294)},[_c('v-list',{attrs:{"dense":"","nav":"","min-width":250}},[_vm._l((_vm.menu),function(item,index){return [_c('div',{key:index},[(item.type == 'divider')?[_c('v-divider',{staticClass:"my-1",attrs:{"color":"grey"}})]:_c('v-list-item',{attrs:{"to":item.route,"disabled":item.isAllowed !== undefined ? !item.isAllowed : false}},[(item.icon)?_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1):_vm._e(),(!item.route)?_c('v-list-item-subtitle',[_vm._v(_vm._s(item.name))]):_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+"   "),(item.count && item.count !== undefined)?_c('v-badge',{attrs:{"content":item.count,"color":"green"}}):_vm._e()],1)],1)],2)]})],2)],1)],1):_vm._e(),_c('v-spacer'),_c('AppHeaderProfile')],1),_c('v-main',{staticClass:"grey lighten-3"},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }