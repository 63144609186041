import LayoutNested from '@/components/layouts/LayoutNested.vue'

export default {
  path: 'service/:serviceKey',
  component: LayoutNested,
  children: [
    {
      path: 'contact',
      name: 'MockupLeadContactBrowse',
      component: () => import("@/services/lead/mockups/MockupLeadContactBrowse.vue"),
    },
    {
      path: 'deal',
      name: 'MockupLeadDealBrowse',
      component: () => import("@/services/lead/mockups/MockupLeadDealBrowse.vue"),
    },
    {
      path: 'lead',
      name: 'MockupLeadLeadBrowse',
      component: () => import("@/services/lead/mockups/MockupLeadLeadBrowse.vue"),
    },
    {
      path: 'lead/step2',
      name: 'MockupLeadLeadBrowseStep2',
      component: () => import("@/services/lead/mockups/MockupLeadLeadBrowseStep2.vue"),
    },
    {
      path: 'source',
      name: 'MockupLeadSourceBrowse',
      component: () => import("@/services/lead/mockups/MockupLeadSourceBrowse.vue"),
    },
  ],
}