import ManageActionColumn from "../../../components/common/BreadTabulatorActionDetachButton.vue";
import Vue from "vue";
import Vuetify from "vuetify";
import store from "../../../store";
import router from "../../../router";
export default {
  serviceKey: "sso",
  key: "internalRoleToInternalUser",
  name: {
    singular: "User Role",
    plural: "User Role",
    //
    internalRole: {
      singular: "User",
      plural: "Users",
    },
    internalUser: {
      singular: "Role",
      plural: "Roles",
    },
  },
  parents: ["internalRole", "internalUser"],

  apiUrls: {
    apiBrowseUrl: "",
    apiStoreUrl: "",
    apiUpdateUrl: "",
    apiReadUrl: "",
    apiMultiUpdateUrl: "",
    apiTrashedBrowseUrl: "",
    apiSoftDeleteUrl: "",
    apiHardDeleteUrl: "",
    apiRestoreUrl: "",
  },

  permissionKeys: {
    browse: "sso-internalRoleToInternalUser-browse",
    read: "sso-internalRoleToInternalUser-read",
    add: "sso-internalRoleToInternalUser-add",
    edit: "sso-internalRoleToInternalUser-edit",
    delete: "sso-internalRoleToInternalUser-delete",
    restore: "sso-internalRoleToInternalUser-restore",
    forceDelete: "sso-internalRoleToInternalUser-forceDelete",
  },

  recordAccessControl: {
    browse: {
      all: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      own: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      assignedTo: {
        roles: ["salesperson", "sales-lead"],
        apiUrl: "",
      },
      belongsTo: {
        ssoBusiness: {
          roles: ["superadmin"],
          apiUrl: "",
        },
        ssoTeam: {
          roles: ["superadmin"],
          apiUrl: "",
        },
      },
    },
  },

  browse: {
    table: {
      actions: {
        manageCellEdit: true,
        columnSelectionDisabledCount: 3,
        isActionColumnVisible: true,
        isTrashedTabVisible: true,
      },
      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          selectableColumn: false,
          headerFilter: false,
          download: false,
        },

        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          hozAlign: "left",
          download: false,
        },

        {
          title: "Role",
          field: "InternalRole.name",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          editable: false,
        },

        {
          title: "User",
          field: "InternalUser.name",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          editable: false,
        },

        {
          title: "Actions",
          formatter: (cell) => {
            Vue.use(Vuetify);
            let ComponentClass = Vue.extend(ManageActionColumn);
            let instance = new ComponentClass({
              propsData: {
                cellData: cell.getData(),
                cellValue: cell.getValue(),
                cellTableData: cell.getTable().getData(),
                cellRowData: cell.getRow().getData(),
                serviceKey: "sso",
                parentModelKey: "internalRole",
                parentModelId: cell.getRow().getData().InternalRole.id,
                intermediateModelKey: "internalUser",
                intermediateModelId: cell.getRow().getData().id,
                childModelKey: "internalRoleToInternalUser",
              },
              vuetify: new Vuetify({}),
              store,
              router,
            });
            instance.$mount();
            return instance.$el;
          },
        },

        /* {
          title: "Role",
          field: "internalRoleSlug",
          dataType: "modelParent",
          modelKey: "internalRole",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },

        {
          title: "User",
          field: "internalUserUuid",
          dataType: "modelParent",
          modelKey: "internalUser",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        }, */
      ],
    },
  },
  read: false,
  add: {
    fields: [
      {
        type: "model",
        key: "internalUserUuid",
        name: "User",
        modelKey: "internalUser",
        optionText: "name",
        optionValue: "uuid",
      },
      {
        type: "model",
        key: "internalRoleSlug",
        name: "Role",
        modelKey: "internalRole",
        optionText: "name",
        optionValue: "slug",
      },
    ],
    rules: {
      internalUserUuid: ["required"],
      internalRoleSlug: ["required"],
    },
  },
  edit: false,
  delete: false,
};
