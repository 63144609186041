<template>
  <div  v-if="isLoading" class="loader">
    <div class="spinner"></div>
  </div>
</template>
  
  <script>
export default {
  name: "ATabulatorLoder",

  props:['isLoading']
};
</script>
  
  <style>
.loader {
position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* border: 3px solid #ffffff; */
  animation: spin 1s infinite linear;
  transform: translateX(-50%) translateY(-50%);
}

.spinner::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ffffff;
  animation: spin 1s infinite linear;
}

@keyframes spin  {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>