<template>
  <v-list-item @click="save">
    <v-list-item-title>Save</v-list-item-title>
  </v-list-item>
</template>

<script>
  //BOC:[api]
  import Api from '@/objects/api'
  //EOC
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:{
      getConditions:{
        type: Function,
      },
      updateView: {
        type: Function,
      },
      view: {
        type: Object,
      },
    },
    data: () => ({
      //BOC:[api]
      api: new Api(),
      //EOC
    }),
    watch: {
      //
    },
    created() {
      //BOC:[api]
      this.api.setMethod(`POST`)
      this.api.setUrl(`${this.$service.crm}/v1/en/console/modelView/edit`)
      this.api.setCallbackCompleted((response)=>{
        this.updateView(response);
        this.$store.dispatch('showMessage','Updated successfully.');
      })
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      save() {
        var conditions = JSON.stringify(this.getConditions());
        this.api.setParams({
          id:this.view.id,
          conditions:conditions,
        })
        this.api.fetch();
      }
    }
  }
</script>