import LayoutNested from '@/components/layouts/LayoutNested.vue'

export default {
  path: '/service/:serviceKey',
  component: LayoutNested,
  children: [
    {
      path: 'mode',
      name: 'PageServiceTestingMode',
      component: () => import("@/services/testing/views/PageServiceTestingMode.vue"),
    },
    {
      path: 'refresh',
      name: 'PageServiceTestingRefresh',
      component: () => import("@/services/testing/views/PageServiceTestingRefresh.vue"),
    },
    {
      path: 'refreshPage2',
      name: 'PageServiceTestingRefreshPage2',
      component: () => import("@/services/testing/views/PageServiceTestingRefreshPage2.vue"),
    },
    {
      path: 'sso',
      name: 'PageServiceTestingSSO',
      component: () => import("@/services/testing/views/PageServiceTestingSSO.vue"),
    },
  ],
}