<template>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ title }}
        </v-card-title>
  
        <v-card-text>
          {{ message }}
        </v-card-text>
  
        <v-card-actions>
          <v-spacer></v-spacer>
  
          <v-btn color="red darken-1" text @click="disagree">
            {{ options.btnDisagree }}
          </v-btn>
  
          <v-btn color="green darken-1" text @click="agree">
            {{ options.btnAgree }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: "ConfirmPopup",
    data() {
      return {
        dialog: false,
        title: "",
        message: "",
        options: {
          btnDisagree: "Disagree",
          btnAgree: "Agree",
        },
        agreeCallback: null,
        disagreeCallback: null,
      };
    },
    methods: {
      open({
        title,
        message,
        options = {
          btnDisagree: "Disagree",
          btnAgree: "Agree",
        },
        agree = () => {},
        disagree = () => {},
      }) {
        this.dialog = true;
        this.title = title;
        this.message = message;
        this.options = options;
        this.agreeCallback = agree;
        this.disagreeCallback = disagree;
      },
      disagree() {
        this.dialog = false;
        this.disagreeCallback();
      },
      agree() {
        this.dialog = false;
        this.agreeCallback();
      },
    },
  };
  </script>