import leadStatus from "../objects/leadStatus";
import apiService from "../objects/apiServices";
import UserAuth from "../objects/userAuth";

import ManageActionColumn from "../../../components/common/BreadTabulatorActionButton.vue";

import Vue from "vue";
import Vuetify from "vuetify";
import store from "../../../store";
import router from "../../../router";

export default {
  serviceKey: "lead",
  key: "leadOther",
  column: {
    uid: "uuid", // tell the system which column to be used as unique id
    text: "name", // tell the system which column to display as text
  },
  name: {
    singular: "Assigned Lead",
    plural: "Assigned Leads",
  },
  parents: [
    //
  ],
  apiUrls: {
    apiBrowseUrl: "/v1/en/console/model/lead/assigned",
    apiStoreUrl: "/v1/en/console/tabulator/model/lead/add",
    apiUpdateUrl: "/v1/en/console/tabulator/model/lead",
    apiReadUrl: "/v1/en/console/tabulator/model/lead",
    apiMultiUpdateUrl: "/v1/en/console/tabulator/model/lead",
    apiTrashedBrowseUrl: "/v1/en/console/model/lead/assigned/deletedList",
    apiSoftDeleteUrl: "/v1/en/console/tabulator/model/lead",
    apiHardDeleteUrl: "/v1/en/console/tabulator/model/lead",
    apiRestoreUrl: "/v1/en/console/tabulator/model/lead",
  },

  recordAccessControl: {
    browse: {
      all: {
        roles: ["superadmin"],
        apiUrl: "/v1/en/console/model/lead/assigned",
      },
      own: {
        roles: ["salesperson"],
        apiUrl: "/v1/en/console/model/lead/assigned",
      },
      assignedTo: {
        roles: ["salesperson"],
        apiUrl: "/v1/en/console/model/lead/assigned",
      },
      belongsTo: {
        ssoTeam: {
          roles: ["sales-lead"],
          apiUrl: "/v1/en/console/model/lead/assigned",
        },
      },
    },
  },

  permissionKeys: {
    browse: "lead-lead-browse",
    read: "lead-lead-read",
    add: "lead-lead-add",
    edit: "lead-lead-edit",
    delete: "lead-lead-delete",
    restore: "lead-lead-restore",
    forceDelete: "lead-lead-forceDelete",
    leadAssign: "lead-lead-assign",
  },

  roleBasedFunctionalities: {
    browse: {
      all: ["superadmin"],
      own: ["salesperson"],
      team: ["sales-lead"],
    },
  },

  browse: {
    table: {
      /* callbackCellUpdated: (cell) => {
          console.log(cell);
        }, */

      actions: {
        manageCellEdit: true,
        columnSelectionDisabledCount: 3,
        isActionColumnVisible: true,
        isTrashedTabVisible: true,
      },

      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          selectableColumn: false,
          headerFilter: false,
          download: false,
          editable: false,
          //showByDefault: true,
        },

        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          hozAlign: "left",
          download: false,
          editable: false,
          //showByDefault: true,
        },

        {
          title: "Team",
          field: "ssoTeamUuid",
          dataType: "modelDropdown",
          textColumn: "ssoTeamName",
          apiServiceKey: "sso",
          apiUrl: "/v1/en/console/model/ssoTeam/dropdown/read",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          headerSort: true,
          editor: "list",
          editorParams: {
            valuesLookup: () => {
              var userAuth = new UserAuth();

              var userUuid = userAuth.getUserUuid();

              var apiUrl = new apiService();
              var ssoApiUrl = apiUrl.getServices("sso");

              if (userAuth.checkUserRoleExists("sales-lead")) {
                return new Promise((resolve, reject) => {
                  fetch(
                    `${ssoApiUrl}/v1/en/console/model/ssoTeam/tableColumnDataList/ValueByUuid/salesLead/${userUuid}`
                  )
                    .then((response) => response.json())
                    .then((data) => {
                      resolve(data);
                      const outputData = data;
                      return outputData;
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });
              } else if (userAuth.checkUserRoleExists("salesperson")) {
                return new Promise((resolve, reject) => {
                  fetch(
                    `${ssoApiUrl}/v1/en/console/model/ssoTeam/tableColumnDataList/ValueByUuid/salesperson/${userUuid}`
                  )
                    .then((response) => response.json())
                    .then((data) => {
                      resolve(data);
                      const outputData = data;
                      return outputData;
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });
              } else {
                return new Promise((resolve, reject) => {
                  fetch(
                    `${ssoApiUrl}/v1/en/console/model/ssoTeam/tableColumnDataList/ValueByUuid`
                  ) //http://localhost:8001/api/
                    .then((response) => response.json())
                    .then((data) => {
                      resolve(data);
                      const outputData = data;
                      return outputData;
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });
              }
            },
          },

          editable: false,
          formatter: (cell) => {
            return cell.getRow().getData().ssoTeamName;
          },
          //showByDefault: true,

          //width:100,
        },

        {
          title: "Name",
          field: "name",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          headerSort: true,
          editor: "input",
          editable: false,
          validator: ["required", "minLength:3", "maxLength:50"],
          //showByDefault: true,
        },

        {
          title: "Position",
          field: "position",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          headerSort: true,
          editor: "input",
          editable: false,
          validator: "required",
          //showByDefault: true,
        },

        {
          title: "Email",
          field: "email",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          headerSort: true,
          editor: "input",
          editable: false,
          validator: [
            "required",
            "regex:^[A-Za-z._]{1,}@[A-Za-z]{1,}[.]{1}[A-Za-z.]{2,6}$",
          ],
          //showByDefault: true,
        },

        {
          title: "Phone",
          field: "phone",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          headerSort: true,
          editor: "input",
          editable: false,
          validator: ["required", "numeric", "regex:^[0-9]{10,12}$"],
          //showByDefault: true,
        },

        {
          title: "Source",
          field: "leadSourceUuid",
          dataType: "modelDropdown",
          textColumn: "leadSourceName",
          apiServiceKey: "lead",
          apiUrl: "/v1/en/console/model/leadSource/dropdown/read",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          headerSort: true,
          editor: "list",
          editorParams: {
            valuesLookup: () => {
              var apiUrl = new apiService();
              var leadApiUrl = apiUrl.getServices("lead");

              return new Promise((resolve, reject) => {
                fetch(
                  `${leadApiUrl}/v1/en/console/model/leadSource/tableColumnDataList/ValueByUuid`
                )
                  .then((response) => response.json())
                  .then((data) => {
                    resolve(data);
                    const outputData = data;
                    return outputData;
                  })
                  .catch((error) => {
                    reject(error);
                  });
              });
            },
          },
          formatter: (cell) => {
            return cell.getRow().getData().leadSourceName;
          },
          editable: false,
          //showByDefault: true,
        },

        {
          title: "Quality",
          field: "leadQuality",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          headerSort: true,
          editor: "list",
          editorParams: {
            values: ["Hight", "Mid", "Low"],
          },
          editable: false,
          validator: "required",
          //showByDefault: true,
        },

        {
          title: "Status",
          field: "status",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          headerSort: true,
          editor: "list",
          editorParams: {
            valuesLookup: () => {
              var statusItems = new leadStatus();
              return statusItems.getItems();
            },
          },
          editable: false,
          validator: "required",
          ////showByDefault: true,
        },

        {
          title: "BD",
          field: "salespersonInternalUserUuid",
          dataType: "modelDropdown",
          textColumn: "salespersonInternalUserName",
          apiServiceKey: "sso",
          apiUrl: "/v1/en/console/model/internalUser/",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          headerSort: true,
          editor: "list",
          editorParams: {
            valuesLookup: (cell) => {
              var userAuth = new UserAuth();
              var userUuid = userAuth.getUserUuid();
              var apiUrl = new apiService();
              var ssoApiUrl = apiUrl.getServices("sso");
              const ssoTeamUuid = cell.getRow().getData().ssoTeamUuid;

              if (userAuth.checkUserRoleExists("salesperson")) {
                return new Promise((resolve, reject) => {
                  fetch(
                    `${ssoApiUrl}/v1/en/console/ssoTeamToInternalUser/ValueByUuid/${ssoTeamUuid}/${userUuid}`
                  )
                    .then((response) => response.json())
                    .then((data) => {
                      resolve(data);
                      const outputData = data;
                      return outputData;
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });
              } else {
                return new Promise((resolve, reject) => {
                  fetch(
                    `${ssoApiUrl}/v1/en/console/ssoTeamToInternalUser/ValueByUuid/${ssoTeamUuid}`
                  )
                    .then((response) => response.json())
                    .then((data) => {
                      resolve(data);
                      const outputData = data;
                      return outputData;
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });
              }
            },
          },
          formatter: (cell) => {
            return cell.getRow().getData().salespersonInternalUserName;
          },
          editable: false,
          //showByDefault: true,
        },

        /* {
            title: "BD Name",
            field: "salespersonInternalUserName",
            dataType: "string",
            filterable: true,
            searchable: true,
            headerFilter: false,
            selectableColumn: true,
          }, */

        {
          title: "Offer",
          field: "offer",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          headerSort: true,
          editor: "list",
          editorParams: {
            values: ["20% Discount", "10% Discount", "5% Discount"],
          },
          editable: false,
          showByDefault: false,
        },

        {
          title: "Bonus",
          field: "bonus",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          headerSort: true,
          editor: "list",
          editorParams: {
            values: ["20% Discount", "Free Shipping", "Free Gift"],
          },
          editable: false,
          showByDefault: false,
        },

        {
          title: "Comment",
          field: "comment",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          headerSort: true,
          editor: "input",
          input: "textarea",
          editable: false,
          showByDefault: false,
        },

        {
          title: "Last Contact",
          field: "timestampContacted",
          dataType: "date",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          headerSort: true,
          editor: "date",
          editable: false,
          showByDefault: false,
        },

        {
          title: "Locked date",
          field: "timestampLocked",
          dataType: "date",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          headerSort: true,
          editor: "date",
          editable: false,
          showByDefault: false,
        },

        {
          title: "Won date",
          field: "timestampWon",
          dataType: "date",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          headerSort: true,
          editor: "date",
          editable: false,
          showByDefault: false,
        },

        {
          title: "Action",
          formatter: (cell) => {
            Vue.use(Vuetify);
            let ComponentClass = Vue.extend(ManageActionColumn);
            let instance = new ComponentClass({
              propsData: {
                cellData: cell.getData(),
                cellValue: cell.getValue(),
                cellTableData: cell.getTable().getData(),
                cellRowData: cell.getRow().getData(),
                serviceKey: "lead",
                modelKey: "leadOther",
                modelId: cell.getRow().getData().id,
              },
              vuetify: new Vuetify({}),
              store,
              router,
            });
            instance.$mount();
            return instance.$el;
          },
        },
      ],

      advanceFilter: {
        columns: [
          {
            name: "name",
            type: "nominal",
            values: [],
          },
          {
            name: "description",
            type: "nominal",
            values: [],
          },
        ],
      },

      pdf: {
        headers: [
          {
            title: "Id",
            field: "id",
          },
          {
            title: "Lead Source Type",
            field: "sourceType",
          },

          {
            title: "Name",
            field: "name",
          },
        ],
      },
    },
  },

  read: {
    tabs: [
      /* {
            key: "demoProduct", //key for tab
            label: "Products",
            dataType: "model",
            relationship: "child",
            modelKey: "demoProduct",
            mapping: {
              parent: "id",
              child: "demoCategoryId",
            },
          },   */
    ],
    table: {
      headers: [
        {
          title: "Id",
          field: "id",
        },

        {
          title: "UUID",
          field: "uuid",
        },

        {
          title: "Source Name",
          field: "leadSourceName",
        },

        {
          title: "Team Name",
          field: "ssoTeamName",
        },

        {
          title: "Lead Assigned To",
          field: "salespersonInternalUserName",
        },

        {
          title: "Company",
          field: "company",
        },

        {
          title: "Name",
          field: "name",
        },

        {
          title: "Position",
          field: "position",
        },

        {
          title: "Email",
          field: "email",
        },

        {
          title: "Phone",
          field: "phone",
        },

        {
          title: "Status",
          field: "status",
        },

        {
          title: "Offer",
          field: "offer",
        },

        {
          title: "Bonus",
          field: "bonus",
        },

        {
          title: "Comment",
          field: "comment",
        },

        {
          title: "Webinar Minutes",
          field: "webinarMinutes",
        },

        {
          title: "Lead Quality",
          field: "leadQuality",
        },

        {
          title: "Assigned Date",
          field: "timestampAssigned",
        },

        {
          title: "Locked Date",
          field: "timestampLocked",
        },

        {
          title: "Won Date",
          field: "timestampWon",
        },

        {
          title: "Created",
          field: "timestampCreated",
        },
        {
          title: "Updated",
          field: "timestampUpdated",
        },
      ],
    },
  },

  // add: {
  //   fields: [
  //     /* {
  //       type: "model",
  //       key: "ssoTeamUuid",
  //       name: "Team",
  //       modelKey: "ssoTeam",
  //       apiListUrl: "/v1/en/console/model/ssoTeam",
  //       optionText: "name",
  //       optionValue: "uuid",
  //       optionTextSaveAs: "ssoTeamName",
  //     },  */

  //     /* {
  //       type: "modelDependency",
  //       parentKey: "ssoTeamUuid",
  //       parentName: "Team",
  //       parentModelKey: "ssoTeam",
  //       parentApiListUrl: "/v1/en/console/model/ssoTeam",
  //       parentOptionText: "name",
  //       parentOptionValue: "uuid",
  //       parentOptionTextSaveAs: "ssoTeamName",
  //       childKey: "salespersonInternalUserUuid",
  //       childName: "Sales Person",
  //       childModelKey: "ssoTeamToInternalUser",
  //       childApiListUrl: "/v1/en/console/ssoTeamToInternalUser/list",
  //       childOptionText: "name",
  //       childOptionValue: "uuid",
  //       childOptionTextSaveAs: "salespersonInternalUserName",
  //     }, */

  //     {
  //       type: "modelParent",
  //       parentKey: "ssoTeamUuid",
  //       parentName: "Team",
  //       parentModelKey: "ssoTeam",
  //       parentApiListUrl: "/v1/en/console/model/ssoTeam",
  //       parentOptionText: "name",
  //       parentOptionValue: "uuid",
  //       parentOptionTextSaveAs: "ssoTeamName",
  //       modelServiceKey: "lead",
  //     },

  //     {
  //       type: "modelChild",
  //       childKey: "leadSourceUuid",
  //       childName: "Source",
  //       childModelKey: "leadSource",
  //       childApiListUrl: "/v1/en/console/model/leadSource/ssoTeam",
  //       childOptionText: "name",
  //       childOptionValue: "uuid",
  //       childOptionTextSaveAs: "leadSourceName",
  //       modelServiceKey: "lead",
  //     },

  //     /* {
  //       type: "model",
  //       key: "leadSourceUuid",
  //       name: "Source",
  //       modelKey: "leadSource",
  //       apiListUrl: "/v1/en/console/model/leadSource",
  //       optionText: "name",
  //       optionValue: "uuid",
  //       optionTextSaveAs: "leadSourceName",
  //     },   */

  //     {
  //       type: "string",
  //       name: "Company Name",
  //       key: "company",
  //     },

  //     {
  //       type: "string",
  //       name: "Name",
  //       key: "name",
  //     },

  //     {
  //       type: "string",
  //       name: "Position",
  //       key: "position",
  //     },

  //     {
  //       type: "string",
  //       name: "Email",
  //       key: "email",
  //     },

  //     {
  //       type: "string",
  //       name: "Phone",
  //       key: "phone",
  //     },

  //     {
  //       type: "modelChild",
  //       childKey: "salespersonInternalUserUuid",
  //       childName: "Sales Person",
  //       childModelKey: "ssoTeamToInternalUser",
  //       childApiListUrl: "/v1/en/console/ssoTeamToInternalUser/list",
  //       childOptionText: "name",
  //       childOptionValue: "uuid",
  //       childOptionTextSaveAs: "salespersonInternalUserName",
  //       modelServiceKey: "lead",
  //     },

  //     {
  //       type: "select",
  //       name: "Status",
  //       key: "status",
  //       options: [
  //         { text: "New Lead", value: "New Lead", selected: true},
  //         { text: "Tried to Contact", value: "Tried to Contact" },
  //         { text: "Contacted", value: "Contacted" },
  //         { text: "Qualified", value: "Qualified" },
  //         { text: "Unqualified", value: "Unqualified" },
  //       ],
  //     },

  //     {
  //       type: "select",
  //       name: "Lead Quality",
  //       key: "leadQuality",
  //       options: [
  //         { text: "Hight", value: "Hight" },
  //         { text: "Mid", value: "Mid" },
  //         { text: "low", value: "low" },
  //       ],
  //     },

  //   ],
  //   rules: {
  //     name: ["required"],
  //     email: ["required"],
  //     phone: ["required"],
  //     status: ["required"],
  //     leadQuality: ["required"],
  //     company: ["required"],
  //   },
  // },

  add: false,

  edit: false,
  delete: true,
  leadMultiAssign: false,
};
