export default {
    serviceKey: 'developer',
    key:'demoCategory',
    column: {
      uid: "id", // tell the system which column to be used as unique id
      text: "name", // tell the system which column to display as text
    },
    name:{
      singular:'Category',
      plural:'Categories',
    },
    parents: [
      //
    ],

    apiUrls: {
      apiBrowseUrl: "",
      apiStoreUrl: "",
      apiUpdateUrl: "",
      apiReadUrl: "",
      apiMultiUpdateUrl: "",
      apiTrashedBrowseUrl: "",
      apiSoftDeleteUrl: "",
      apiHardDeleteUrl: "",
      apiRestoreUrl: "",
  },

  permissionKeys: {
    browse: "developer-access",
    read: "developer-access",
    add: "developer-access",
    edit: "developer-access",
    delete: "developer-access",
    restore: "developer-access",
  },

  roleBasedFunctionalities: {
    browse: {
      all: ['superadmin'],
      own: ['salesperson'],
      team: ['sales-lead'],
    },
  },
    
    browse: {
      table: {
        actions: {
          manageCellEdit: true,
          columnSelectionDisabledCount: 3,
          isActionColumnVisible: true,
          isTrashedTabVisible: true,
        },
        headers: [

          {
            formatter:"rowSelection", 
            titleFormatter:"rowSelection", 
            titleFormatterParams:{
                rowRange:"active" //only toggle the values of the active filtered rows
              },
            hozAlign:"left", 
            headerSort:false,
            filterable: false,
            searchable: false,
            selectableColumn: false,
            headerFilter: false,
            download:false,
            editable: false,
          },

          {
            title: "Index",
            formatter: (cell) => {
              return cell.getRow().getData().rownum;
            },
            filterable: false,
            searchable: false,
            headerFilter: false,
            headerSort: false,
            selectableColumn: false,
            hozAlign: "left",
            download:false,
            editable: false,
          },

          {
            title: "Name",
            field: "name",
            dataType: "string",
            filterable: true,
            searchable: true,
            headerFilter: false,
            headerSort: true,
            selectableColumn: true,
            hozAlign: "left",
            editable: false,
          },

          {
            title: "Description",
            field: "description",
            dataType: "string",
            filterable: true,
            searchable: true,
            headerFilter: false,
            headerSort: true,
            selectableColumn: true,
            hozAlign: "left",
            editor: "input",
            editable: false,
          },
          
  
        ],
        advanceFilter: {
          columns: [
            {
              name: "name",
              type: "nominal",
              values: [],
            },
            {
              name: "description",
              type: "nominal",
              values: [],
            },
          ],
        },

        pdf: {
          headers: [
            {
              title: "Id",
              field: "id",
            },
            {
              title: "Name",
              field: "name",
            },
            
            {
              title: "Description",
              field: "description",
            },
          ]
        },

      },
    },

    read: {
      tabs: [
        {
          key: "demoProduct", //key for tab
          label: "Products",
          dataType: "model",
          relationship: "child",
          modelKey: "demoProduct",
          mapping: {
            parent: "id",
            child: "demoCategoryId",
          },
        },  
      ],
      table: {
        headers: [
  
          {
            title: "Id",
            field: "id",
          },
          
          {
            title: "Name",
            field: "name",
          },
  
          {
            title: "Description",
            field: "description",
          },
  
          
          {
            title: "Created",
            field: "timestampCreated",
          },
          {
            title: "Updated",
            field: "timestampUpdated",
          },
  
  
        ],
      },
    },

    add: {
      fields: [
        {
          type: "string",
          name: "Name",
          key: "name",
        },
  
        {
          type:"textarea",
          name:"Description",
          key:"description"
        },
  
      ],
      rules: {
        name: ["required"],
      },
    },
    edit: true,
    delete: true,

  };
  