import ManageActionColumn from "../../../components/common/BreadTabulatorActionButton.vue";

import Vue from "vue";
import Vuetify from "vuetify";
import store from "../../../store";
import router from "../../../router";

export default {
  serviceKey: "sso",
  key: "internalUser",
  column: {
    uid: "uuid", // tell the system which column to be used as unique id
    text: "name", // tell the system which column to display as text
  },
  name: {
    singular: "User",
    plural: "Users",
  },
  parents: [
    //
  ],

  apiUrls: {
    apiBrowseUrl: "",
    apiStoreUrl: "/v1/en/console/internalUser/add",
    apiUpdateUrl: "",
    apiReadUrl: "",
    apiMultiUpdateUrl: "",
    apiTrashedBrowseUrl: "",
    apiSoftDeleteUrl: "",
    apiHardDeleteUrl: "",
    apiRestoreUrl: "",
  },

  permissionKeys: {
    browse: "sso-internalUser-browse",
    read: "sso-internalUser-read",
    add: "sso-internalUser-add",
    edit: "sso-internalUser-edit",
    delete: "sso-internalUser-delete",
    restore: "sso-internalUser-restore",
    forceDelete: "sso-internalUser-forceDelete",
  },

  recordAccessControl: {
    browse: {
      all: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      own: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      assignedTo: {
        roles: ["salesperson", "sales-lead"],
        apiUrl: "",
      },
      belongsTo: {
        ssoBusiness: {
          roles: ["superadmin"],
          apiUrl: "",
        },
        ssoTeam: {
          roles: ["superadmin"],
          apiUrl: "",
        },
      },
    },
  },

  roleBasedFunctionalities: {
    browse: {
      all: ["superadmin"],
      own: ["salesperson"],
      team: ["sales-lead"],
    },
  },

  browse: {
    alert: {
      text: "Kindly contact superadmin if you need to register new user.",
    },
    tools: {
      advancedBar: [],
      enableColumnSelection: false,
      enableRowSelection: false,
      enableSearch: true,
      showAdvancedBar: false,
    },
    table: {
      actions: {
        manageCellEdit: true,
        columnSelectionDisabledCount: 3,
        isActionColumnVisible: true,
        isTrashedTabVisible: true,
      },
      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          selectableColumn: false,
          headerFilter: false,
          download: false,
          editable: false,
        },

        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          hozAlign: "left",
          download: false,
          editable: false,
        },

        {
          title: "Full Name",
          field: "name",
          dataType: "string",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          editable: false,
        },
        {
          title: "Email Address",
          field: "email",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          editable: false,
        },
        {
          title: "Mobile Number",
          field: "mobile",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          editable: false,
        },
        /* {
          dataType: "model",
          relationship: "child",
          modelKey: "internalRoleToInternalUser",
          text: "Roles",
          sortable: false,
          searchable: false,
          filterable: false,
        }, */

        {
          title: "Action",
          formatter: (cell) => {
            Vue.use(Vuetify);
            let ComponentClass = Vue.extend(ManageActionColumn);
            let instance = new ComponentClass({
              propsData: {
                cellData: cell.getData(),
                cellValue: cell.getValue(),
                cellTableData: cell.getTable().getData(),
                cellRowData: cell.getRow().getData(),
                serviceKey: "sso",
                modelKey: "internalUser",
                modelId: cell.getRow().getData().id,
              },
              vuetify: new Vuetify({}),
              store,
              router,
            });
            instance.$mount();
            return instance.$el;
          },
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: "internalRoleToInternalUser", //key for tab
        label: "Roles",
        dataType: "model",
        relationship: "intermediate",
        modelKey: "internalRoleToInternalUser",
        intermediateChildModelKey: "internalRole",
        mapping: {
          parent: "uuid",
          child: "internalUserUuid",
        },
      },
    ],
    table: {
      headers: [
        {
          title: "Full Name",
          field: "name",
        },
        {
          title: "Email Address",
          field: "email",
        },
        {
          title: "Mobile Number",
          field: "mobile",
        },
        {
          title: "Created",
          field: "timestampCreated",
        },
        {
          title: "Updated",
          field: "timestampUpdated",
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: "string",
        name: "Full Name",
        key: "name",
      },
      {
        type: "string",
        name: "Email Address",
        key: "email",
      },
      {
        type: "string",
        name: "Mobile Number",
        key: "mobile",
      },
      {
        type: "string",
        name: "Signature Name",
        key: "signatureName",
      },
      // {
      //   type: "password",
      //   name: "Password",
      //   key: "password",
      // },
    ],
    rules: {
      name: ["required"],
      email: ["required", "email"],
      // password: ["required"],
    },
  },
  edit: {
    fields: [
      {
        type: "string",
        name: "Full Name",
        key: "name",
      },
      {
        type: "string",
        name: "Email Address",
        key: "email",
      },
      {
        type: "string",
        name: "Mobile Number",
        key: "mobile",
      },
      {
        type: "string",
        name: "Signature Name",
        key: "signatureName",
      },
    ],
    rules: {
      name: ["required"],
      email: ["required", "email"],
    },
  },
  delete: true,
};
