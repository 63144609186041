<template>
  <v-row no-gutters class="tb-view-area">
    <v-col md="10">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs" style="float: left; height: 40px; border-radius: 5px 5px 0px 0px;">
            <v-icon>mdi mdi-format-list-bulleted-square</v-icon>
          </v-btn>
        </template>
  
        <v-list>
          <v-list-item @click="changeTableDetails('all')">
            <v-list-item-title>All</v-list-item-title>
          </v-list-item>
          <v-list-item v-for="(view, index) in listAllViews" :key="index"  @click="changeTableDetails(view.id)">
            <v-list-item-title>{{ view.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> 
      <v-tabs
        center-active
        show-arrows
        next-icon="mdi-chevron-right"
        prev-icon="mdi-chevron-left"
        height="40px"
        v-model="selectedViewTab"
      >
        <v-tab @click="changeTableDetails('trashed')"> Trashed </v-tab>

        <v-tab @click="changeTableDetails('all')">All</v-tab>
        <v-tab
          v-for="(view, index) in listAllViews" 
          :key="view.id"
          :tabindex="index + 2"
          @click="changeTableDetails(view.id)"
        >
          <v-icon left v-if="view.isPrivate === true" size="small">
            mdi-lock
          </v-icon>
          {{ view.name }}

          <div class="text-center tb-view-dropdown-btn">
            <v-menu open-on-click bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on">
                  <span class="mdi mdi-chevron-down"></span>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="openViewNameDialog(view.id, view.name)">
                  <v-list-item-title>Rename</v-list-item-title>
                </v-list-item>
                <v-list-item @click="moveViewToRight(view.id)">
                  <v-list-item-title>Move to right</v-list-item-title>
                </v-list-item>
                <v-list-item @click="moveViewToLeft(view.id)">
                  <v-list-item-title>Move to left</v-list-item-title>
                </v-list-item>
                <template v-if="currentUserID === view.ownerUuid">
                  <v-list-item
                    @click="setPrivateOrPublic(view.id, view.isPrivate)"
                  >
                    <v-list-item-title v-if="view.isPrivate === true"
                      >Set as Public</v-list-item-title
                    >
                    <v-list-item-title v-else>Set as Private</v-list-item-title>
                  </v-list-item>
                </template>
                <v-list-item @click="openConfirmationDialog(view.id)">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-tab>

        
      </v-tabs>
    </v-col>
    <v-col md="2" align="right">
      
      <v-btn outlined @click="saveNewView" class="mr-2">New View</v-btn>
      <v-btn
        outlined
        color="primary"
        :disabled="selectedTab === 'all' || selectedTab === 'trashed'"
        @click="updateView"
        >Save</v-btn
      >
      <v-dialog max-width="400" v-model="renameEachViewDialog">
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary" dark
              >Rename Selected View Name</v-toolbar
            >
            <v-card-text>
              <v-text-field
                v-model="selectedViewName"
                hide-details
                class="center"
              >
              </v-text-field>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="renameViewName(selectedViewId, selectedViewName)"
                >Update</v-btn
              >
              <v-btn text @click="dialog.value = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <TheModalConfirmation
        ref="confirmPopup"
        @agree="agreeCallback"
        @disagree="disagreeCallback"
      ></TheModalConfirmation>

      
    </v-col>
  </v-row>
</template>

<script>
import Api from "@/objects/api";
import { mapState } from "vuex";
import TheModalConfirmation from "./TheModalConfirmation.vue";

export default {
  name: "BreadTabulatorView",
  computed: mapState({
    auth: (state) => state.auth.data,
    services: (state) => state.service.data.services,
  }),

  components: {
    TheModalConfirmation,
  },

  props: {
    listAllViews: {
      type: Array,
    },
    model: {
      type: Object,
      required: true,
    },

    selectedCustomColums: {},
    selectedAdvanceFilterConditions: {},
    selectedFilterConditions: {},
    cColumnSort: {},
    displayRowCount: {},
    allResizeColumns: {},
  },

  data: () => ({
    isPrivate: true,
    api: new Api(),
    renameEachViewDialog: false,
    selectedViewName: null,
    selectedViewId: null,
    confirmationTitle: "Confirmation",
    confirmationMessage: "Are you sure you want to delete this view?",
    currentUserID: "",
    selectedTab: "all",
    selectedViewTab: 1,
  }),

  methods: {
    changeTableDetails(viewId) {
      this.$emit("changeTableDetails", this.listAllViews, viewId);
    },

    changeSelectedTabStatus(viewId, tabIndex) {
      
      this.selectedTab = viewId;
      this.selectedViewTab = viewId === "all" ? 1 : viewId === "trashed" ? 0 : tabIndex;
      this.loadSpecificViewData(viewId);
    },

    saveNewView() {
      //console.log("selectedCustomColums");
      this.api.setMethod(`POST`);
      this.api.setUrl(`${this.$service.crm}/v1/en/console/crmView/add`);
      this.api.setParams({
        modelKey: this.model.key,
        ownerUuid: this.auth.uuid,
        cColumnCondition: JSON.stringify(this.selectedCustomColums),
        aFilterCondition: JSON.stringify([]),
        filterCondition: JSON.stringify([]),
        cColumnSort: JSON.stringify([]),
        displayRowCount: 10,
        isPrivate: this.isPrivate,
        manageColumnCustomWidth: JSON.stringify([]),
      });
      this.api.setCallbackCompleted((response) => {
        console.log("response", response);
        let viewId = response.id;
        //let tabIndex = response.activeCount;
        this.loadSpecificViewData(viewId);

       this.$store.dispatch("showMessage", {
                    message: "New View Created successfully.",
                    messageType: "success",
                    timeout:2000
                });
        /* //EOC
          // refresh the page to get the new view
          //this.$router.go(); */
      });
      this.api.fetch();
    },

    updateView() {
      const viewId = this.$route.query.viewId;

      this.api.setMethod(`POST`);
      this.api.setUrl(`${this.$service.crm}/v1/en/console/crmView/edit`);
      this.api.setParams({
        id: viewId,
        cColumnCondition: JSON.stringify(this.selectedCustomColums),
        aFilterCondition: JSON.stringify(this.selectedAdvanceFilterConditions),
        filterCondition: JSON.stringify(this.selectedFilterConditions),
        cColumnSort: JSON.stringify(this.cColumnSort),
        displayRowCount: this.displayRowCount,
        isPrivate: this.isPrivate,
        manageColumnCustomWidth: JSON.stringify(this.allResizeColumns),
      });
      this.api.setCallbackCompleted(() => {
        /* console.log("response", response.id); */
        //let viewId = response.id;
        //this.loadSpecificViewData(viewId, 1);
        this.$store.dispatch("showMessage", {
                    message: "View Updated successfully.",
                    messageType: "success",
                    timeout:2000
                });
        this.$router.go();
        /* //EOC
          // refresh the page to get the new view
          //this.$router.go(); */
      });
      this.api.fetch();
    },

    renameViewName(viewId, viewName) {
      // call API to update the view name
      this.api.setMethod(`POST`);
      this.api.setUrl(`${this.$service.crm}/v1/en/console/crmView/rename`);
      this.api.setParams({
        id: viewId,
        name: viewName,
      });

      this.api.setCallbackCompleted(() => {
        this.$store.dispatch("showMessage", {
                    message: "View Name Updated successfully.",
                    messageType: "success",
                    timeout:2000
                });
        this.renameEachViewDialog = false;
        this.$router.go();
      });
      this.api.fetch();
    },

    openViewNameDialog(viewId, viewName) {
      this.selectedViewId = viewId;
      this.selectedViewName = viewName;
      this.renameEachViewDialog = true;
    },

    deleteView(viewId) {
      // call API to delete the view
      this.api.setMethod(`POST`);
      this.api.setUrl(`${this.$service.crm}/v1/en/console/crmView/delete`);
      this.api.setParams({
        id: viewId,
      });

      this.api.setCallbackCompleted(() => {
        this.$store.dispatch("showMessage", {
                    message: "View Deleted successfully.",
                    messageType: "success",
                    timeout:2000
                });
        // refresh the page to get the new view
        this.loadSpecificViewData("all");
        this.$router.go();
      });
      this.api.fetch();
    },

    moveViewToRight(viewId) {
      this.api.setMethod(`POST`);
      this.api.setUrl(`${this.$service.crm}/v1/en/console/crmView/moveRight`);
      this.api.setParams({
        id: viewId,
        /* modelKey: this.model.key, */
      });

      this.api.setCallbackCompleted(() => {
        this.$store.dispatch("showMessage", {
                    message: "View Moved successfully.",
                    messageType: "success",
                    timeout:2000
                });
        // refresh the page to get the new view
        this.$router.go();
      });
      this.api.fetch();
    },

    moveViewToLeft(viewId) {
      this.api.setMethod(`POST`);
      this.api.setUrl(`${this.$service.crm}/v1/en/console/crmView/moveLeft`);
      this.api.setParams({
        id: viewId,
        /* modelKey: this.model.key, */
      });

      this.api.setCallbackCompleted(() => {
        this.$store.dispatch("showMessage", {
                    message: "View Moved successfully.",
                    messageType: "success",
                    timeout:2000
                });
        // refresh the page to get the new view
        this.$router.go();
      });
      this.api.fetch();
    },

    setPrivateOrPublic(viewId, isPrivate) {
      this.api.setMethod(`POST`);
      this.api.setUrl(
        `${this.$service.crm}/v1/en/console/crmView/setPrivateOrPublic`
      );
      this.api.setParams({
        id: viewId,
        isPrivate: isPrivate,
      });

      this.api.setCallbackCompleted(() => {
       this.$store.dispatch("showMessage", {
                    message: "View Changed successfully.",
                    messageType: "success",
                    timeout:2000
                });
        // refresh the page to get the new view
        this.$router.go();
      });
      this.api.fetch();
    },

    agreeCallback() {
      this.deleteView(this.selectedViewId);
    },

    disagreeCallback() {},

    openConfirmationDialog(viewId) {
      this.selectedViewId = viewId;
      this.$refs.confirmPopup.open({
        title: this.confirmationTitle,
        message: this.confirmationMessage,
        agree: () => {
          this.agreeCallback();
        },
        disagree: () => {
          this.disagreeCallback();
        },
      });
    },

    loadSpecificViewData(viewId) {
      //console.log("viewId", viewId);

      const routeName = this.$route.name;

      const page = this.$route.query.page ? this.$route.query.page : 1;
      const pageSize = this.$route.query.pageSize
        ? this.$route.query.pageSize
        : 10;
      //this.updateUrlParams(page, pageSize);
      //alert(page);

      //console.log(page, pageSize);

      this.$router
        .push({
          name: routeName,
          params: {
            serviceKey: this.model.serviceKey,
            modelKey: this.model.key,
          },
          query: {
            viewId: viewId,
            page: page,
            pageSize: pageSize,
          },
        })
        .catch(() => {});
    },

  },

  mounted() {},

  created() {
    this.currentUserID = this.auth.uuid;
  },


  watch: {
    //
  },
};
</script>

<style scoped>
.custom-save-btn {
  border-radius: 5px !important;
  height: 48px !important;
}
.as-private {
  margin: 0px auto;
}
.v-label {
  font-size: 12px !important;
}
</style>
