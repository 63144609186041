export default [
  {
    name: 'Components',
    icon: 'mdi-database',
    route: { name: 'PageDeveloperComponent', params: { serviceKey: "developer" } },
  },
  {
    name: 'Microservices',
    icon: 'mdi-database',
    route: { name: 'PageDeveloperMicroservice', params: { serviceKey: "developer" } },
  },
]