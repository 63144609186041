<template>
  <div class="tb-right-section">
    <div class="tb-show-column">
      <h4>columns:</h4>
    </div>
    <div class="tb-select-column-dropdown">
      <v-select
        v-model="selectedColumns"
        :items="allColumns"
        item-text="title"
        item-value="field"
        label="Select columns"
        multiple
        @change="applyColumnSelection"
        dense
        solo
      >
      <template v-slot:selection="{ item, index }">
        <v-chip v-if="index <= 1" x-small>
          <span>{{ item.title }}</span>
        </v-chip>
        <span
          v-if="index === 2"
          class="grey--text text-caption"
        >
          (+{{ selectedColumns.length - 1 }} others)
        </span>
      </template>
    </v-select>
    </div>

    <div>
      <v-btn class="tb-clear-btn" @click="showAllColumns" text
        >Clear</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Api from "@/objects/api";
export default {
  name: "BreadTabulatorSelectColumn",
  computed: mapState({
    auth: (state) => state.auth.data,
    services: (state) => state.service.data.services,
  }),

  data: () => ({
    allColumns: [],
    selectedColumns: [],
    isPrivate: false,
    ownerUuid: "",
    defaultColumns: [],

    api: new Api(),
  }),
  props: {
    model: {
      type: Object,
      required: true,
    },
  },

  created() {
    this.allColumns = this.$_.filter(
      this.model.browse.table.headers,
      (columns) => {
        if (columns.selectableColumn == true  ) {
          return columns;
        }
      }
    );

    this.defaultColumns = this.$_.filter(
      this.model.browse.table.headers,
      (columns) => {
        if (columns.selectableColumn == true && columns.showByDefault != false) {
          return columns;
        }
      }
    );
  },

  watch: {
    
  },

  mounted() {

    this.selectedColumns = this.$_.filter(
      this.allColumns,
      (columns) => {
          return columns;
      }
    );

  },

  

  methods: {
    applyColumnSelection() {
      this.$emit("applyColumnSelection", this.selectedColumns);
    },

    showAllColumns() {
      this.selectedColumns = this.defaultColumns;
      this.$emit("applyColumnSelection", this.selectedColumns);
    },

    showViewSelectedColumns(viewColumns) {
      this.selectedColumns = viewColumns;
      this.$emit("applyColumnSelection", this.selectedColumns);
    },

  },
};
</script>
