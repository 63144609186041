<template>
  <div>
    <AError :api="api"/>
    <v-card flat>
      <v-card-text class="d-flex">
        <!-- BOC: [viewable] -->
        <BreadDataTableViewable
          v-show="showViewableColumns"
          :columns="table ? table.headers : []"
          :selectViewableColumns="selectViewableColumns"
        ></BreadDataTableViewable>
        <!-- EOC -->
        <v-spacer></v-spacer>
        <!-- BOC: [search] -->
        <div 
          v-show="showSearch"
        >
          <v-sheet 
            rounded 
            class="d-flex"
          >
            <v-select
              v-model="searchedColumn"
              :items="searchableColumns"
              placeholder="Search"
              class="mx-1"
              dense
              outlined
              style="width:25%;"
              hide-details
            ></v-select>
            <!---->
            <v-text-field
              v-model="searchedKeyword"
              placeholder="Search a keyword"
              dense
              @keyup.native.enter="fetch"
              @click:append="fetch"
              outlined
              hide-details
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-sheet>
        </div>
        <!-- EOC -->
      </v-card-text>
      <BreadDataView
        ref="dataView"
        :api="api"
        :conditions="conditions"
        :searchedColumn="searchedColumn"
        :searchedKeyword="searchedKeyword"
        :modelKey="modelKey"
        :sortBy="sortBy"
        :sortDesc="sortDesc"
        :table="table"
        :viewableColumns="viewableColumns"
        :parentModelKey="parentModelKey"
        :parentModelId="parentModelId"
        :intermediateChildModelKey="intermediateChildModelKey"
      />
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components:{
    //
  },
  computed: mapState({
    //
  }),
  props:{
    api: {
      type: Object,
      required: true,
    },
    conditions: {
      type: Array,
      default: ()=>{ return [] },
    },
    modelKey: {
      type: String,
    },
    showSearch: {
      type: Boolean,
      default:true,
    },
    showViewableColumns: {
      type: Boolean,
      default:true,
    },
    sortBy:{
      type: Array,
      default: ()=>{ return [] },
    },
    sortDesc:{
      type: Array,
      default: ()=>{ return [] },
    },
    table:{
      type: Object,
      required: true,
    },
    //BOC
    parentModelKey: {
      type: String,
      default:null,
    },
    parentModelId: {
      type: Number,
      default:null,
    },
    intermediateChildModelKey: {
      type: String,
      default:null,
    },
    //EOC
  },
  data: () => ({
    searchableColumns:[],
    searchedColumn:null,
    searchedKeyword:"",
    //BOC:[viewable]
    viewableColumns:[],
    //EOC
  }),
  created() {
    this.getSearchableColumns()
  },
  mounted() {
    //
  },
  methods: {
    //BOC:[viewable]
    selectViewableColumns(viewableColumns) {
      var columns = []
      for(var header of this.table.headers) {
        if(viewableColumns.indexOf(header.value) > -1) columns.push(header)
      }
      this.viewableColumns = columns
    },
    //EOC
    getSearchableColumns() {
      var columns = this.$_.filter(this.table.headers,(o)=>{
        if(o.searchable != false) {
          return o
        }
      })
      this.searchableColumns = columns
      if(columns.length > 0) this.searchedColumn = columns[0].value
    },
    fetch() {
      this.$refs.dataView.fetch();
    },

    deleteSelectedRows() {
      return this.$refs.dataView.deleteSelectedRows();
    },
  }
}
</script>