import LayoutBlank from '@/components/layouts/LayoutBlank.vue'

export default {
  path: '/vision',
  component: LayoutBlank,
  children: [
    {
      path: 'expressLogin',
      name: 'PageVisionExpressLogin',
      component: () => import("@/services/vision/views/PageVisionExpressLogin.vue"),
    },
  ],
}