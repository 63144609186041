<template>
  <div>
    <v-select
      v-model="parentValue"
      :label="field.parentName"
      :items="parentItems"
      :item-text="field.parentOptionText ? field.parentOptionText : 'name'"
      :item-value="field.parentOptionValue ? field.parentOptionValue : 'id'"
      :loading="parentApi.isLoading"
      :rules="parentRules"
      @change="getParentChildItems"
    />

    <v-text-field
      v-model="form.data[field.parentOptionTextSaveAs]"
      :label="field.parentName"
      v-if="isShow"
    />

    <v-select
      v-model="childValue"
      :label="field.childName"
      :items="childItems"
      :item-text="field.childOptionText ? field.childOptionText : 'name'"
      :item-value="field.childOptionValue ? field.childOptionValue : 'id'"
      :loading="childApi.isLoading"
      :rules="childRules"
     
    />

    <v-text-field
      v-model="form.data[field.childOptionTextSaveAs]"
      :label="field.childName"
      v-if="isShow"
    />

  </div>
    

    
  </template>
  
  <script>
  //BOC:[api]
  import Api from "@/objects/api";
  //EOC
  //BOC:[model]
  import Model from "@/objects/model";
  //EOC
  //BOC:[service]
  import Service from "@/objects/service";
  //EOC
  import { mapState } from "vuex";
  export default {
    components: {
      //
    },
    computed: mapState({
      auth: (state) => state.auth.data,
      services: (state) => state.service.data.services,
      rolePermission: (state) => state.rolePermission.data,
    }),
    props: {
      field: {
        type: Object,
      },
      parentRules: {
        type: Array,
      },
      childRules: {
        type: Array,
      },
      form: {
        type: Object,
      },
    },
    watch: {
      parentValue(parentNewValue) {

        if(this.field.parentOptionTextSaveAs)  {
          let parentValue = parentNewValue;
        let parentTextValue = this.parentItems.find(item => item[this.field.parentOptionValue] === parentValue);
        this.form.setDataProperty(this.field.parentOptionTextSaveAs, parentTextValue[this.field.parentOptionText]);
        }
        this.$emit("inputParent", parentNewValue);
      },

      childValue(childNewValue) {
        if(this.field.childOptionTextSaveAs)  {
          let childValue = childNewValue;
          let childTextValue = this.childItems.find(item => item[this.field.childOptionValue] === childValue);
          this.form.setDataProperty(this.field.childOptionTextSaveAs, childTextValue[this.field.childOptionText]);
        }
        this.$emit("inputchild", childNewValue);
      },
    },
    data: () => ({
      //BOC:[api]
      parentApi: new Api(),

      childApi: new Api(),
      //EOC
      //BOC:[model]
      parentModel: new Model(),

      childModel: new Model(),
      //EOC
      //BOC:[service]
      parentService: new Service(),
      childService: new Service(),
      //EOC
      //BOC:[select]
      parentItems: [],
      childItems: [],
      //EOC
      parentValue:null,
      childValue:null,
      isShow: false,
    }),
    created() {
      //BOC:[model]
      this.parentModel.getByKey(this.field.parentModelKey);
      this.childModel.getByKey(this.field.childModelKey);
      //EOC
      //BOC:[service]
      if(this.parentModel.serviceKey) this.parentService.set("key",this.parentModel.serviceKey);
      if(this.childModel.serviceKey) this.childService.set("key",this.childModel.serviceKey);
      //EOC
      //BOC:[api]
      if(this.field.parentApiListUrl) {
        this.parentApi.setUrl(
        `${this.$service[this.parentService.key]}${this.field.parentApiListUrl}`
      );
      }else{
        this.parentApi.setUrl(
        `${this.$service[this.parentService.key]}/v1/en/console/tabulator/model/${this.field.parentModelKey}/upsert/dropdown`
      );
      }
      
      this.parentApi.setCallbackCompleted((response) => {
        this.parentItems = response.data;
        //BOC: get the value
        // if(this.form.data) {
        //   this.parentValue = this.form.data[this.$_ucfirst(this.field.parentModelKey)]
        //   //BOC: to overwrite the value in form excluding all other properties
        //   const prop = `${this.field.parentModelKey}${this.$_ucfirst(this.field.parentOptionValue)}`
        //   const val = this.parentValue[this.field.parentOptionValue]
        //   var that = this
        //   setTimeout(()=>{
        //     that.form.setDataProperty(prop, val)
        //   },100)
        //   //EOC
        // }
        //EOC
      });
      //EOC
      //BOC
      this.parentApi.fetch();
      //EOC

    },
    mounted() {
      //
    },
    methods: {
      getParentChildItems(){
        
        let parentValue = this.parentValue;

        var internalUserRole  = this.auth.roles;
        var internalUserUuid = this.auth.uuid;

        // find sales-lead in the internalUserRole

        var internalUserRoleSlug = internalUserRole.map((role) => role.slug);

        if(internalUserRoleSlug.includes('sales-lead') && this.field.childModelKey == 'ssoTeam') {
          this.childApi.setUrl(
            `${this.$service[this.childService.key]}/v1/en/console/${this.field.childModelKey}/list/${parentValue}/${internalUserUuid}`
          );
        }else if(this.field.childApiListUrl) {
        this.childApi.setUrl(
        `${this.$service[this.childService.key]}${this.field.childApiListUrl}/${parentValue}`
      );
      }else{
        this.childApi.setUrl(
        `${this.$service[this.childService.key]}/v1/en/console/tabulator/model/${this.field.childModelKey}/upsert/dropdown`
      );
      }

      this.childApi.setCallbackCompleted((response) => {
        this.childItems = response.data;
        //BOC: get the value
        // if(this.form.data) {
        //   this.childValue = this.form.data[this.$_ucfirst(this.field.childModelKey)]
        //   //BOC: to overwrite the value in form excluding all other properties
        //   const prop = `${this.field.childModelKey}${this.$_ucfirst(this.field.childOptionValue)}`
        //   const val = this.childValue[this.field.childOptionValue]
        //   var that = this
        //   setTimeout(()=>{
        //     that.form.setDataProperty(prop, val)
        //   },100)
        //   //EOC
        // }
        //EOC
      });
      //EOC
      //BOC
      this.childApi.fetch();
      //EOC
      },
    },
  };
  </script>