<template>
<v-card>
  <v-card-title>{{title}}</v-card-title>
  <v-tabs
    v-model="tab.current"
  >
    <v-tab v-for="t in tab.tabs" :key="t">
      {{ t }}
    </v-tab>
  </v-tabs>
  <v-data-table
    dense
    :headers="table.headers"
    :items="table.data"
    :items-per-page="10"
  >
  <template v-slot:top>
    <v-text-field
      v-model="table.search"
      label="Search"
      class="mx-4"
    ></v-text-field>
  </template>
  </v-data-table>
</v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components:{
    //
  },
  computed: mapState({
    //
  }),
  props:{
    title:{
      type:String,
      default:'Title',
    },
    headers:{
      type:Array,
      default:()=>['Col 1','Col 2','Col 3'],
    },
  },
  data: () => ({
    tab: {
      current:null,
      tabs:[
        'Inbox',
        'Trash',
      ]
    },
    table:{
      search:'',
      headers:[],
      data:[],
    }
  }),
  created() {
    var headers =[]
    for(var header of this.headers) {
      headers.push({text:header,value:header})
    }
    this.table.headers = this.$_.cloneDeep(headers)
  },
  mounted() {
    //
  },
  methods: {
    //
  }
}
</script>