<template>
    <div>
      <div class="d-flex ma-2" >
        <v-spacer />
        <v-btn
          :disabled = "childModel.key === 'ssoTeamToInternalUser' && !isTeamAdmin"
          class="primary"
          :to="{
            name: 'PageServiceModelBreadTabulatorChildAdd',
            params: {
              parentModelKey: parentModel.key,
              parentModelId: parent.id,
              childModelKey: childModel.key,
            },
          }"
          >Attach {{ (childModel.name[parentModel.key]) ? childModel.name[parentModel.key].singular : childModel.name.singular }}</v-btn
        >
      </div>
      

      <div>
            <span id="record-count" style="float: left; font-size: 12px"></span>
        </div>
        <BreadTabulator
        ref="tabulatorDataTable"
        layout="fitColumns"
        :autoColumns="false"
        :paginationSize="paginationSize"
        :paginationSizeSelector="paginationSizeSelector"
        :apiUrl="apiGetBrowseUrl()"
        :columns="columns"
        :modelKey="childModel.key"
      ></BreadTabulator>

    </div>
  </template>
  
  <script>
  import Api from "@/objects/api";
  //BOC:[model]
  import Model from "@/objects/model";
  //EOC
  //BOC:[service]
  import Service from "@/objects/service";
  //EOC
  import { mapState } from "vuex";
  export default {
    components: {
      //
    },
    computed: mapState({
      auth: (state) => state.auth.data,
    }),
    props: {
      childModelKey: {
        type: String,
      },
      intermediateChildModelKey: {
        type: String,
      },
      parent: {
        type: Object,
      },
      parentModel: {
        type: Object,
      },
      tabProperties: {
        type: Object,
      },
      isTeamAdmin: {
        type: Boolean,
      }
    },
    data: () => ({
        childService: new Service(),
        childModel: new Model(),
        paginationSizeSelector: [10, 50, 100],
        paginationSize: 10,
        columns: [],
        adminCount: 0,
        api: new Api(),
        //isTeamAdmin: false,
    }),
    watch: {
      //
    },
    created() {
      //BOC:[childModel]
      this.childModel.getByKey(this.childModelKey);
      //EOC
      //BOC:[childService]
      if (this.childModel.serviceKey)
        this.childService.set("key", this.childModel.serviceKey);
      //EOC

      //console.log(this.childModel.serviceKey, this.parentModel.key, this.intermediateChildModelKey, this.childModel.key,this.parent.id);

      this.columns = this.$_.filter(
        this.childModel.browse.table.headers,
        (headers) => {
          return headers;
        }
      );

/*       this.columns.push({
        title:"Action",
        filterable: false,
        headerSort: false,
        formatter: () => {
          return `<v-btn elevation="2" x-small color="error">Detach</v-btn>`;
        },
        cellClick:(e, cell) => {

          if(this.childModel.key === 'ssoTeamToInternalUser'){

            if(!this.isTeamAdmin){
              this.$store.dispatch("showMessage", {
                message: "You are not allowed to detach this member.",
                messageType: "error",
                timeout:2000
              });
              return;
            }

            let tableRowCount = cell.getTable().getData().length;
            if(tableRowCount == 1){

                this.$store.dispatch("showMessage", {
                    message: "Cannot detach the last member.",
                    messageType: "error",
                    timeout:2000
                });

              //this.$store.dispatch("showMessage", "Cannot detach the last member.");
              return;
            }
            cell.getTable().getData().forEach((item) => {
              if (item.isAdmin) {
                this.adminCount++;
              }
            });

            if (cell.getRow().getData().isAdmin && this.adminCount == 1) {
              //this.$store.dispatch("showMessage", "Cannot detach the last admin.");
              this.$store.dispatch("showMessage", {
                    message: "Cannot detach the last admin.",
                    messageType: "error",
                    timeout:2000

                });
              this.adminCount = 0;
              return;
            }
          } 

            e.preventDefault();
            this.$router.push({
              name: "PageServiceModelBreadTabulatorChildDetach",
              params: {
                serviceKey: this.childModel.serviceKey,
                parentModelKey: this.parentModel.key,
                parentModelId: this.parent.id,
                intermediateModelKey: this.intermediateChildModelKey,
                intermediateModelId: cell.getRow().getData().id,
                childModelKey: this.childModel.key,
              },
            });
        },
        download: false,

      }); */
      
      // get url params
      /* if(this.childModel.key === 'ssoTeamToInternalUser'){
        let modelId = parseInt(this.$route.params.modelId, 10);
        let internalUserUuid = this.auth.uuid;

        // get value from API
        this.api.setMethod('GET');
        this.api.setUrl(`${this.$service[this.childModel.serviceKey]}/v1/en/console/ssoTeamToInternalUser/${modelId}/${internalUserUuid}`);
        this.api.setCallbackCompleted((resp) => {
          if(resp.data[0].isAdmin){
            this.isTeamAdmin = true;
          }else{
            this.isTeamAdmin = false;
          }
        });
        this.api.fetch();

        //console.log(modelId, internalUserUuid);
      } */
      
      
    },
    mounted() {
      //
    },
    methods: {
    apiGetBrowseUrl() {

        let parentRelationKey = this.tabProperties.mapping.parent;
        return `${this.$service[this.childService.key]}/v1/en/console/tabulator/model/${this.parentModel.key}/${parentRelationKey}/${this.parent[parentRelationKey]}/child/${this.childModelKey}/browse/all`;

      /* if(this.tabProperties.apiUrl){
        let parentRelationKey = this.tabProperties.mapping.parent;
        return `${this.$service[this.childService.key]}/v1/en/console/tabulator/model/${this.parentModel.key}/${parentRelationKey}/${this.parent[parentRelationKey]}/child/${this.childModelKey}/browse/all`;
      }else{
        let parentRelationKey = this.tabProperties.mapping.parent;
        return `${this.$service[this.childService.key]}/v1/en/console/tabulator/model/${this.parentModel.key}/${parentRelationKey}/${this.parent.id}/child/${this.childModelKey}/browse/all`; 
      } */
    },
    },
  };
  </script>