<template>
  <div>
    <div v-if="count != null">
      {{count}}
    </div>
    <span v-else>-</span>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:{
      header:{
        type: Object,
        required: true,
      },
      item:{
        type: Object,
        required: true,
      },
    },
    data: () => ({
      count: null,
    }),
    created() {
      this.count = this.item._count[this.$_ucfirst(this.header.modelKey)];
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>