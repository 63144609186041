<template>
  <div class="py-1 px-3">
    <!---->
    <div class="pt-1">
      <b>Views</b>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
        <span>You may save current view settings and share it to others.</span>
      </v-tooltip>
    </div>
    <!--BOC:[renameView]-->
    <BreadDataTableViewDialogRename 
      ref="dialogRename"
      :updateView="updateView"
    />
    <!--EOC-->
    <div class="d-flex align-center">
      <!---->
      <template v-for="v in views">
        <v-menu 
          v-if="selectedViewId == v.id"
          :key="v.id" 
          offset-y
          :close-on-content-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              v-on="on"
              small
              depressed
              class="mr-1"
              :loading="apiDelete.isLoading"
              :disabled="apiDelete.isLoading"
            >
              {{v.name}} <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item-group>
              <!--BOC:[saveView]-->
              <BreadDataTableViewButtonSave
                :view="v"
                :getConditions="getConditions"
                :updateView="updateView"
              />
              <!--EOC-->
              <!--BOC:[renameView]-->
              <v-list-item @click="$refs.dialogRename.open(v)">
                <v-list-item-title>Rename</v-list-item-title>
              </v-list-item>
              <!--EOC-->
              <v-list-item :disabled="true">
                <v-list-item-title>Move Left</v-list-item-title>
              </v-list-item>
              <v-list-item :disabled="true">
                <v-list-item-title>Move Right</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item :disabled="true">
                <v-list-item-content>
                  <v-list-item-subtitle class="text-caption">Current: Everyone</v-list-item-subtitle>
                  <v-list-item-title>Manage Access</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item :disabled="true">
                <v-list-item-content>
                  <v-list-item-subtitle class="text-caption">Current: Nobody</v-list-item-subtitle>
                  <v-list-item-title>Manage Ownership</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="deleteView(v.id)">
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-btn 
          v-else
          :key="v.id" 
          small 
          depressed 
          class="mr-1"
          @click="selectView(v)"
        >{{v.name}}</v-btn>
      </template>
      <!---->
      <!---->
      <v-btn 
        small 
        outlined 
        color="primary"
        @click="addView()"
        :loading="apiAdd.isLoading"
      >+ New View</v-btn>
      <!---->
    </div>
  </div>
</template>

<script>
  //BOC:[api]
  import Api from '@/objects/api'
  //EOC
  //BOC:[renameView]
  import BreadDataTableViewDialogRename from '@/components/common/BreadDataTableViewDialogRename'
  //EOC
  //BOC:[saveView]
  import BreadDataTableViewButtonSave from '@/components/common/BreadDataTableViewButtonSave'
  //EOC
  import { mapState } from 'vuex'
  export default {
    components:{
      //BOC:[renameView]
      BreadDataTableViewDialogRename,
      //EOC
      //BOC:[saveView]
      BreadDataTableViewButtonSave,
      //EOC
    },
    computed: mapState({
      //
    }),
    props:{
      model:{
        type:Object,
      },
      getConditions:{
        type:Function,
      },
      setFiltersViaConditions:{
        type:Function,
      },
    },
    data: () => ({
      //BOC:[api]
      api: new Api(),
      //EOC
      //BOC:[apiAdd]
      apiAdd: new Api(),
      //EOC
      //BOC:[apiDelete]
      apiDelete: new Api(),
      //EOC
      //BOC:[views]
      views:[],
      selectedViewId:null,
      //EOC
    }),
    watch: {
      //
    },
    created() {
      //BOC:[api]
      this.api.setMethod(`GET`)
      this.api.setUrl(`${this.$service.crm}/v1/en/console/modelView`)
      this.api.setQuery(`modelKey=${this.model.key}`);
      this.api.setCallbackCompleted((response)=>{
        this.views = response;
        if(this.views.length > 0) this.selectView(this.views[0])
      })
      //EOC
      //BOC:[apiAdd]
      this.apiAdd.setMethod(`POST`)
      this.apiAdd.setUrl(`${this.$service.crm}/v1/en/console/modelView/add`)
      this.apiAdd.setCallbackCompleted((response)=>{
        var view = response;
        this.views.push(view);
        this.selectedViewId = view.id;
      })
      //EOC
      //BOC:[apiDelete]
      this.apiDelete.setMethod(`POST`)
      this.apiDelete.setUrl(`${this.$service.crm}/v1/en/console/modelView/delete`)
      this.apiDelete.setCallbackCompleted((response)=>{
        var view = response;
        this.views = this.$_.reject(this.views,{id:view.id});
      })
      //EOC
      //BOC:[api]
      this.api.fetch()
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      //BOC:[views]
      selectView(view) {
        this.selectedViewId = view.id;
        var conditions = JSON.parse(view.conditions);
        this.setFiltersViaConditions(conditions);
      },
      //EOC
      //BOC:[apiAdd]
      addView() {
        var conditions = JSON.stringify(this.getConditions());
        this.apiAdd.setParams({
          modelKey:this.model.key,
          conditions:conditions,
        })
        this.apiAdd.fetch();
      },
      //EOC
      //BOC:[apiDelete]
      deleteView(id) {
        this.apiDelete.setParams({
          id:id,
        });
        this.apiDelete.fetch();
      },
      //EOC
      //BOC:[renameView & saveView]
      updateView(newView) {
        var i = this.$_.findIndex(this.views,{id:newView.id});
        var views = this.$_.cloneDeep(this.views);
        views[i] = this.$_.clone(newView);
        this.views = views;
      },
      //EOC
    }
  }
</script>