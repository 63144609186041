var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('v-btn',{staticClass:"float-right mb-5",attrs:{"color":"primary","to":{
          name: 'PageServiceModelBreadTabulatorChildAdd',
          params: {
            parentModelKey: _vm.parentModel.key,
            parentModelId: _vm.parent.id,
            childModelKey: _vm.childModel.key,
          },
        }}},[_vm._v("+ New "+_vm._s(_vm.childModel.name.singular)+" ")]),_vm._m(0),_c('BreadTabulator',{ref:"tabulatorDataTable",attrs:{"layout":"fitColumns","autoColumns":false,"paginationSize":_vm.paginationSize,"paginationSizeSelector":_vm.paginationSizeSelector,"apiUrl":_vm.apiGetBrowseUrl(),"columns":_vm.columns,"modelKey":_vm.childModelKey}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticStyle:{"float":"left","font-size":"12px"},attrs:{"id":"record-count"}})])}]

export { render, staticRenderFns }