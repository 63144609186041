export default {
  key: "HrdcSettingsErrorLog",
  serviceKey: "hrdc",
  name: {
    singular: "Error Log",
    plural: "Error Log",
  },
  parents: [
    //
  ],
  add: {
    name: "Error Log",
  },
  view: {
    name: "Error Log",
  },
};
