export default [
  {
    name: 'Lead Sources',
    icon: 'mdi-database',
    route: { name: 'BrowseLeadSource', params: { modelKey: 'leadSource'}, query: {
        viewId: 'all',
      }, },
  },
  {
    name: 'New Leads',
    icon: 'mdi-database',
    route: { name: 'BrowseNewLead', params: { modelKey: 'leadNew'}, query: {
        viewId: 'all',
      }, },
  },
  {
    name: 'Assigned Leads',
    icon: 'mdi-database',
    route: { name: 'BrowseAssignedLead', params: { modelKey: 'leadOther'}, query: {
        viewId: 'all',
      }, },
  }, 

  /* {
    name: 'Sources',
    icon: 'mdi-database',
    route: { name: 'MockupLeadSourceBrowse' },
  },
  {
    name: 'Leads',
    icon: 'mdi-database',
    route: { name: 'MockupLeadLeadBrowse' },
  },
  {
    name: 'Contacts',
    icon: 'mdi-database',
    route: { name: 'MockupLeadContactBrowse' },
  }, */
  // {
  //   name: 'Deals',
  //   icon: 'mdi-database',
  //   route: { name: 'MockupLeadDealBrowse' },
  // },
  // {
  //   name: 'Raw Leads',
  //   icon: 'mdi-database',
  //   route: { name: 'PageServiceModelBrowse', params: { modelKey: 'lead' } },
  // },
  // {
  //   name: 'Lead Lists',
  //   icon: 'mdi-database',
  //   route: { name: 'PageServiceModelBrowse', params: { modelKey: 'leadList' } },
  // },

  // {
  //   name: 'Pipelines',
  //   icon: 'mdi-database',
  //   route: { name: 'PageServiceModelBrowse', params: { modelKey: 'pipeline' } },
  //   disabled: true,
  // },
]