export default {
  serviceKey: "developer",
  key: "demoOrderToDemoProduct",
  column: {
    uid: "id", // tell the system which column to be used as unique id
    text: "name", // tell the system which column to display as text
  },
  name: {
    singular: "Order Product",
    plural: "Order Products",

    demoOrder: {
      singular: 'Product',
      plural: 'Products',
    },

    demoProduct: {
      singular: 'Order',
      plural: 'Orders',
    },
  },
  parents: [
    "demoOrder",
    "demoProduct",
  ],

  apiUrls: {
    apiBrowseUrl: "",
    apiStoreUrl: "",
    apiUpdateUrl: "",
    apiReadUrl: "",
    apiMultiUpdateUrl: "",
    apiTrashedBrowseUrl: "",
      apiSoftDeleteUrl: "",
      apiHardDeleteUrl: "",
      apiRestoreUrl: "",
},

  browse: {
    table: {
      actions: {
        manageCellEdit: true,
        columnSelectionDisabledCount: 3,
        isActionColumnVisible: true,
          isTrashedTabVisible: true,
      },
      
      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          selectableColumn: false,
          headerFilter: false,
          download: false,
        },

        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          hozAlign: "left",
          download: false,
        },

        {
          title: "Order ID",
          field: "DemoOrder.orderNo",
          dataType: "string",
          /* modelKey: "demoOrder", */
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
        },

        {
          title: "Product Name",
          field: "DemoProduct.name",
          dataType: "string",
          /* modelKey: "demoProduct", */
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
        },

        {
          title: "Quantity",
          field: "quantity",
          dataType: "integer",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
        },

        {
          title: "Unit Price",
          field: "unitPrice",
          dataType: "float",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          editor: "input",
        },

        {
          title: "Discount",
          field: "discount",
          dataType: "float",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          editor: "input",
        },

        {
          title: "Status",
          field: "status",
          dataType: "boolean",
          filterable: true,
          searchable: true,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          editor: "input",
        },
      ],
      advanceFilter: {
        columns: [
          {
            name: "unitPrice",
            type: "nominal",
            values: [],
          },
          {
            name: "discount",
            type: "nominal",
            values: [],
          },
        ],
      },

      pdf: {
        headers: [
          {
            title: "Id",
            field: "id",
          },
          {
            title: "Quantity",
            field: "quantity",
          },

          {
            title: "Unit Price",
            field: "unitPrice",
          },

          {
            title: "Discount",
            field: "discount",
          },
        ],
      },
    },
  },

  read:false,

  add: {
    fields: [

      {
        type: "model",
        key: "demoOrderId",
        name: "Order",
        modelKey: "demoOrder",
        optionText: "orderNo",
        optionValue: "id",
      },

      {
        type: "model",
        key: "demoProductId",
        name: "Product",
        modelKey: "demoProduct",
        optionText: "name",
        optionValue: "id",
      },

      {
        type: "number",
        name: "Quantity",
        key: "quantity",
      },

      {
        type: "float",
        name: "Unit Price",
        key: "unitPrice",
      },

      {
        type: "float",
        name: "Discount",
        key: "discount",
      },

      {
        type: "select",
        name: "Status",
        key: "status",
        options: [
          {
            text: "Active",
            value: 'active',
          },
          {
            text: "Inactive",
            value: 'Inactive',
          },
        ],
      },

    ],
    rules: {
      quantity: ["required"],
      unitPrice: ["required"],
    },
  },
  edit: true,
  delete: true,
};
