<template>
  <div>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <ALoader v-if="isLoading" :isLoading="isLoading"/>
      <v-card v-else :loading="isLoading">
        <v-card-text>
          <template v-for="(field, index) in form.fields">
            
            <template v-if="field.type == 'model'">
              <BreadTabulatorFormSelectModel
                :form="form"
                :key="index"
                :field="field"
                @input="form.data[field.key] = $event"
                :rules="rules[field.key] ? rules[field.key] : []"
                :disabled="(parentModel) ? (field.key == ((parentModel.column) ? `${parentModel.key}${$_ucfirst(parentModel.column.uid)}` : `${parentModel.key}Id`)) : false"
                ></BreadTabulatorFormSelectModel>
            </template>

            <template v-else-if="field.type =='modelDependency'">
              <BreadTabulatorFormDependanceSelectModel
                :form="form"
                :key="index"
                :field="field"
                @inputParent="form.data[field.parentKey] = $event"
                @inputchild="form.data[field.childKey] = $event"
                :parentRules="rules[field.parentKey] ? rules[field.parentKey] : []"
                :childRules="rules[field.childKey] ? rules[field.childKey] : []"
                ></BreadTabulatorFormDependanceSelectModel>
              </template>

              <template v-else-if="field.type =='modelParent'">
              <BreadTabulatorFormParentSelectModel
                :form="form"
                :key="index"
                :field="field"
                @inputParent="form.data[field.parentKey] = $event"
                :parentRules="rules[field.parentKey] ? rules[field.parentKey] : []"
                @getParentSelectedItem="getParentSelectedItem"
                ></BreadTabulatorFormParentSelectModel>
              </template>


              <template v-else-if="field.type =='modelChild'">
              <BreadTabulatorFormChildSelectModel
                :form="form"
                :key="index"
                :field="field"
                @inputchild="form.data[field.childKey] = $event"
                :childRules="rules[field.childKey] ? rules[field.childKey] : []"
                :parentSelectedValue = "parentSelectedValue"
                ></BreadTabulatorFormChildSelectModel>
              </template>
              

            <template v-else-if="field.type == 'boolean'">
              <v-checkbox
                v-model="form.data[field.key]"
                :key="index"
                :label="field.name"
                :rules="rules[field.key] ? rules[field.key] : []"
              ></v-checkbox>
            </template>

            <template v-else-if="field.type== 'select'">
              <v-select
                v-model="form.data[field.key]"
                :key="index"
                :label="field.name"
                :items="field.options"
                item-text="text"
                item-value="value"
                :rules="rules[field.key] ? rules[field.key] : []"
              ></v-select>
            </template>

            <template v-else-if="field.type == 'textarea'">
              <v-textarea
                v-model="form.data[field.key]"
                :key="index"
                :label="field.name"
                :rules="rules[field.key] ? rules[field.key] : []"
              ></v-textarea>
            </template>

            <template v-else-if="field.type == 'float'">
              <v-text-field
                v-model="form.data[field.key]"
                :key="index"
                :label="field.name"
                :rules="rules[field.key] ? rules[field.key] : []"
                type="number"
                step="0.01"
              ></v-text-field>
            </template>

            
            <template v-else>
              <v-text-field
                v-model="form.data[field.key]"
                :type="field.type"
                :key="index"
                :label="field.name"
                :max="field.max ? field.max : false"
                :min="field.min ? field.min : false"
                :rules="rules[field.key] ? rules[field.key] : []"
              ></v-text-field>
            </template>
            
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="$router.go(-1)">
            <span>Cancel</span>
          </v-btn>
          <v-btn
            :disabled="isLoading"
            color="primary"
            :loading="api.isLoading"
            @click="validate"
          >
            <span>Confirm</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import BreadTabulatorFormSelectModel from "@/components/common/BreadTabulatorFormSelectModel.vue";
import BreadTabulatorFormDependanceSelectModel from "@/components/common/BreadTabulatorFormDependanceSelectModel.vue";
import BreadTabulatorFormParentSelectModel from "@/components/common/BreadTabulatorFormParentSelectModel.vue";
import BreadTabulatorFormChildSelectModel from "@/components/common/BreadTabulatorFormChildSelectModel.vue";

//BOC:[form]
import Form from "@/objects/form";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    BreadTabulatorFormSelectModel,
    BreadTabulatorFormDependanceSelectModel,
    BreadTabulatorFormParentSelectModel,
    BreadTabulatorFormChildSelectModel,
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: {
    action: {
      type: String,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      required: true,
    },
    parentModel: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    //BOC:[form]
    form: new Form(),
    //EOC
    //BOC:[rules]
    rules: {},
    //EOC
    parentSelectedValue: null,
    selectedDataDefault: '',
    
  }),
  watch: {
    //
  },
  created() {
    //BOC:[form]
    this.form.get({ model: this.model, action: this.action });
    this.createRules();
    //EOC
    this.setDefaultValueForSelectModel();

   
  },
  mounted() {
    //
  },
  methods: {
    setData(data) {
      this.form.setData(data);
    },

    setDataProperty(key, value) {
      this.form.setDataProperty(key, value);
    },

    getParentSelectedItem(parentItem) {
      //console.log('parent Item', parentItem)

      this.parentSelectedValue = parentItem;
    },

    setDefaultValueForSelectModel() {
      this.form.fields.forEach((field) => {
        if (field.type === "select") {
          /// get the selected true value from the options and set it as the default value
          var selectedOption = field.options.find((option) => option.selected === true);

          this.form.data[field.key] = selectedOption;
          
        }
      });
    },

    //BOC:[form]
    validate() {
      this.api.callbackReset();
      if (this.$refs.form.validate()) {
        this.submit();
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    submit() {
      var data = this.$_.clone(this.form.data);

      /* console.log(data);
      console.log(this.form.fields);  */

      this.form.fields.forEach((field) => {
        if (field.type === "number") {
          data[field.key] = parseInt(this.form.data[field.key],10);
        }

        if (field.type === "float") {
          data[field.key] = parseFloat(this.form.data[field.key]);
        }

        if(field.type === "date"){
          data[field.key] = new Date(this.form.data[field.key]);
        }

        if(field.type === "dependenceModel"){
          data[field.parentKey] = this.form.data[field.parentKey];
          data[field.childKey] = this.form.data[field.childKey];
        }
      });

      //BOC: remove unneccessary data
      /* if (data.timestampUpdated) delete data.timestampUpdated;
      if (data.timestampDeleted)  delete data.timestampDeleted; */
      if (data._count) delete data._count;
      //EOC
      //BOC: remove parent data
      if(this.model.parents) {
        for(var parent of this.model.parents) {
          delete data[this.$_ucfirst(parent)];
        }
      }
      //EOC
      
      if(this.model.key == "leadNew"){
        data['internalUserUuid'] = this.auth.uuid;

        if(typeof data['status'] == 'string'){
          data['status'] = this.form.data['status'];
        }

        if(typeof data['status'] == 'object'){
          data['status'] = this.form.data['status'].value;
        }
        
      } 

      //console.log('data', typeof data['status']);

      data = JSON.stringify(data);
      //
      this.api.setParams({
        modelKey: this.model.key,
        data: data,
      });
      this.api.fetch();
    },
    //EOC
    //BOC:[rules]
    createRules() {
      var rules = {};
      var validations = {
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid email.";
        },
        mobile: (value) => {
          const pattern = /^[+()-\d]+$/;
          return pattern.test(value) || "Invalid mobile number.";
        },
        required: (value) => !!value || "Required.",
      };
      //
      for (const [key, value] of Object.entries(this.form.rules)) {
        rules[key] = [];
        for (const ruleKey of value) {
          rules[key].push(validations[ruleKey]);
        }
      }
      //
      this.rules = rules;
    },
    //EOC
  },
};
</script>