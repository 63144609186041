export default [
  {
    name: 'Mode',
    icon: 'mdi-database',
    route: { name: 'PageServiceTestingMode' },
  },
  {
    name: 'SSO',
    icon: 'mdi-database',
    route: { name: 'PageServiceTestingSSO' },
  },
  {
    name: 'Refresh',
    icon: 'mdi-database',
    route: { name: 'PageServiceTestingRefresh' },
  },
]