import _ from "lodash";

export default {
  state: {
    data: {
      currentService: null,
      services: [],
    },
  },
  getters: {
    //
  },
  actions: {
    selectCurrentServiceByServiceKey(context, serviceKey) {
      context.commit("selectCurrentServiceByServiceKey", serviceKey)
    },
    updateServices(context, services) {
      context.commit("updateServices", services)
    },
  },
  mutations: {
    selectCurrentServiceByServiceKey(state, serviceKey) {
      const service = _.find(state.data.services, { key: serviceKey });
      if(!service) {
        state.data.currentService = null;
      }
      return state.data.currentService = _.clone(service);
    },
    updateServices(state, services) {
      //BOC: add dashboard route to each service
      for(var item of services) {
        item.route = { name: 'PageServiceLanding', params: { serviceKey: item.key } }
      }
      //EOC
      return state.data.services = services
    },
  }
}