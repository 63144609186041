export default {
    key: "revertForm",
    serviceKey: "hrdc",
    name: {
      singular: "Grant Approval & Revert Form",
      plural: "Grant Approval & Revert Form",
    },
    parents: [
      //
    ],
    add: {
      name: "Grant Approval & Revert Form",
    },
    view: {
      name: "View Application",
    },
  };
  