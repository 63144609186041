import store from '@/store'
import axios from 'axios'

export default class Api {
	constructor() {
		this.isLoading = false;
		this.isError = false;
		this.error = null;
		this.url = null;
		this.query = null;
		this.baseUrl = null;
		this.method = "get";
		this.headers = {};
		this.params = {};
		this.info = null;
		this.data = null;
		this.callbackCompleted = () => {};
	}
	callbackReset() {
		this.isLoading = true;
		this.isError = false;
		this.error = null;
	} 
	callbackError(e) {
		this.isLoading = false;
		this.isError = true;
		this.error = e;
		if(this.onCallbackErrorCompleted) {
			this.onCallbackErrorCompleted(e);
		}
	}
	callbackSuccess(resp) {
		this.isLoading = false;
		if(resp.data) this.setData(resp.data);
		if(resp.info) this.setInfo(resp.info);
		if(this.callbackCompleted) this.callbackCompleted(resp);
	}
	setData(data) {
		this.data = data;
	}
	setMethod(method) {
		this.method = method;
	}
	setInfo(info) {
		this.info = info;
	}
	setUrl(url) {
		this.baseUrl = url;
		this.url = (this.query) ? `${this.baseUrl}?${this.query}` : this.baseUrl;
	}
	setHeaders(headers) {
		this.headers = headers;
	}
	setParams(params) {
		this.params = params;
	}
	setQuery(query) {
		this.query = query;
		this.url = (this.query) ? `${this.baseUrl}?${this.query}` : this.baseUrl;
	}
	setCallbackError(func) {
		this.onCallbackErrorCompleted = func;
	}
	setCallbackCompleted(func) {
		this.callbackCompleted = func;
	}
	async fetch() {
		//BOC:[auto insert token]
		if (typeof store.state.auth.data.token != 'undefined' && Object.keys(this.headers).length == 0) this.headers.Authorization = 'Bearer ' + store.state.auth.data.token
		//EOC
		try {
			if(!this.url) return this.callbackError('API is missing. Please contact system developer.')
			this.callbackReset();
			await axios({
				method: this.method,
				headers: this.headers,
				url: this.url,
				data: this.params,
			})
			.then(
				(response) => {
					if (response.data == null) {
						this.callbackError('Item not found.');
					} else if (response.data.error) {
						this.callbackError(response.data.error);
					} else {
						this.callbackSuccess(response.data);
					}
				},
				(error) => {
					if (typeof error.response != 'undefined' && typeof error.response.data != 'undefined') {
						var err;
						if (typeof error.response.data.errors != 'undefined' && error.response.data.errors) {
							//read Laravel ValidationException errors message
							var errors = error.response.data.errors;
							err = errors[Object.keys(errors)[0]];
						} else if (typeof error.response.data.message != 'undefined') {
							err = error.response.data.message;
							if(!err) err = "Error. Received blank error message from server."
							// if (err == 'Authorization token expired') {
							// 	//BOC:[locale]
							// 	var locale = window.location.pathname.replace(/^\/([^/]+).*/i, '$1')
							// 	if (locale == '/') locale = 'en'
							// 	//EOC
							// 	window.location.replace('/' + locale + '/error/expired');
							// }
						} else {
							err = error;
						}
						this.callbackError(err);
					} else {
						this.callbackError(error);
					}
				}
			);
		} catch (e) {
			this.callbackError(e);
		}
	}
}