<template>
  <div>
    <vue-visual-filter
      :filtering-options="filteringOptions"
      @filter-update="captureFilterUpdate"
    >
      <template #groupTypes="{ groupTypes, group }">
        <div class="float-left width-85">
          <v-select
            v-model="group.groupType"
            :items="groupTypes"
            class="advance-filter-select"
            label="Condition Type"
          ></v-select>
        </div>
      </template>

      <template #filterAddition="{ filterTypes, addFilter }">
        <div class="text-center float-left width-10 btn-margin">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                <v-icon>mdi mdi-filter-plus-outline</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="filter in filterTypes"
                :key="filter"
                @click="addFilter(filter)"
              >
                <v-list-item-title>{{ filter }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div style="clear: both"></div>
      </template>

      <template #groupDeletion="{ deleteGroup }">
        <div class="float-left width-10">
          <v-btn @click="deleteGroup" elevation="2" icon color="error">
            <v-icon>mdi mdi-delete-outline</v-icon>
          </v-btn>
        </div>
        <div style="clear: both"></div>
      </template>

      <template #fieldUpdation="{ fieldNames, condition, updateField }">
        <div class="float-left width-30">
          <v-select
            v-model="condition.fieldName"
            :items="fieldNames"
            @change="updateField"
            label="Field"
            class="advance-filter-select"
          >
          </v-select>
        </div>
      </template>

      <template
        #methodUpdation="{ numericMethodNames, nominalMethodNames, condition }"
      >
        <div class="float-left width-30">
          <v-select
            v-if="numericMethodNames"
            v-model="condition.method"
            :items="numericMethodNames"
            class="advance-filter-select"
          ></v-select>

          <v-select
            v-else
            v-model="condition.method"
            :items="nominalMethodNames"
            class="advance-filter-select"
          ></v-select>
        </div>
      </template>

      <template #argumentUpdation="{ condition }">
        <div class="float-left width-20">
          <v-text-field
            v-if="
              condition.method === 'like' ||
              condition.method === 'starts' ||
              condition.method === 'ends' ||
              condition.method === 'keywords'
            "
            v-model="condition.argument"
            placeholder="Value"
            :style="{ width: 'auto' }"
            type="text"
          ></v-text-field>
          <v-text-field
            v-else
            v-model="condition.argument"
            placeholder="Value"
            :style="{ width: 'auto' }"
            type="number"
          ></v-text-field>
        </div>
      </template>

      <template #conditionDeletion="{ deleteCondition }">
        <div class="float-left width-10">
          <v-btn @click="deleteCondition" elevation="4" icon color="error">
            <v-icon>mdi mdi-delete-outline</v-icon>
          </v-btn>
        </div>
        <div style="clear: both"></div>
      </template>
    </vue-visual-filter>
    
  </div>
</template>


<script>
import Filtertypes from "../../services/developer/objects/filtertypes";

export default {
  name: "BreadTabulatorAdvanceFilterable",
  props: {
    model: {
      type: Object,
      required: true,
    },
    setAdvancedFilterConditions: {
      type: Function,
    },
  },
  data: () => ({
    filteringOptions: {
      data: [],
      methods: {
        numeric: {
          ">"(cellValue, argument) {
            return cellValue > argument;
          },
          "="(cellValue, argument) {
            return cellValue == argument;
          },
          "<"(cellValue, argument) {
            return cellValue < argument;
          },
          "!="(cellValue, argument) {
            return cellValue != argument;
          },
          "<="(cellValue, argument) {
            return cellValue <= argument;
          },
          ">="(cellValue, argument) {
            return cellValue >= argument;
          },
        },
        nominal: {
          like(cellValue, argument) {
            return cellValue.includes(argument);
          },
          starts(cellValue, argument) {
            return cellValue.starts(argument);
          },
          ends(cellValue, argument) {
            return cellValue.ends(argument);
          },
          keywords(cellValue, argument) {
            return cellValue.keywords(argument);
          },
        },
      },
    },

    filterTypes: new Filtertypes(),
    advanceFilterConditions : [],
  }),

  created() {
    this.filteringOptions.data =
      this.model.browse.table.advanceFilter.columns.map((column) => {
        return {
          name: column.name.toLowerCase(),
          type: column.type,
          values: column.values,
        };
      });
  },

  methods: {
    captureFilterUpdate(ctx) {
      this.setAdvancedFilterConditions(ctx.filter);
      //console.log(ctx.filter);
    },

   /*  buildPrismaFilter(filter) {
      if (filter.type === "group") {
        if (filter.groupType === "or") {
          return {
            OR: filter.filters.map(this.buildPrismaFilter),
          };
        } else if (filter.groupType === "and") {
          return {
            AND: filter.filters.map(this.buildPrismaFilter),
          };
        } else if (filter.groupType === "not and") {
          return {
            NOT: {
              AND: filter.filters.map(this.buildPrismaFilter),
            },
          };
        }
      } else if (filter.type === "condition") {
        switch (filter.method) {
          case "like":
            return {
              [filter.fieldName]: {
                contains: filter.argument,
                mode: "insensitive",
              },
            };

          case "=":
            return {
              [filter.fieldName]: filter.argument,
            };

          case "!=":
            return {
              NOT: [
                {
                  [filter.fieldName]: filter.argument,
                },
              ],
            };

          case "starts":
            return {
              [filter.fieldName]: {
                startsWith: filter.argument,
                mode: "insensitive",
              },
            };

          case "ends":
            return {
              [filter.fieldName]: {
                endsWith: filter.argument,
                mode: "insensitive",
              },
            };

          case "keywords":
            return {
              [filter.fieldName]: {
                contains: filter.argument,
                mode: "insensitive",
              },
            };

          case "<":
            return {
              [filter.fieldName]: {
                lt: filter.argument,
              },
            };

          case "<=":
            return {
              [filter.fieldName]: {
                lte: filter.argument,
              },
            };

          case ">":
            return {
              [filter.fieldName]: {
                gt: filter.argument,
              },
            };

          case ">=":
            return {
              [filter.fieldName]: {
                gte: filter.argument,
              },
            };

          default:
            break;
        }
      }
    }, */

    /* extractFilters(json) {
      if (json.type === "group") {
        const subFilters = json.filters.map((subFilter) =>
          this.extractFilters(subFilter)
        );
        if (json.groupType === "or") {
          return { subFilters };
        } else if (json.groupType === "not and") {
          return { subFilters };
        } else if (json.groupType === "and") {
          return { subFilters };
        }
      } else if (json.type === "condition") {
        if (json.dataType === "nominal") {
          return {
            field: json.fieldName,
            type: json.method,
            value: json.argument,
          };
        } else {
          return {
            field: json.fieldName,
            type: json.method,
            value: Number(json.argument),
          };
        }
      }
    }, */ 

   /*  flattenSubFilters(filters) {
      return filters.flatMap((filter) => {
        if (filter.subFilters) {
          const subFilterContents = this.flattenSubFilters(filter.subFilters);
          return subFilterContents.length > 0 ? [subFilterContents] : [];
        }
        return filter;
      });
    },  */
  },
};
</script>

<style scoped>

.advance-filter-select {
  text-transform: uppercase;
}

.v-menu__content .v-list {
  text-transform: uppercase !important;
}

.float-left {
  float: left;
}

.width-70 {
  width: 70%;
}

.width-60 {
  width: 60%;
}

.width-80{
  width: 80%;

}

.width-85{
  width: 85%;

}

.width-50 {
  width: 50%;
}

.width-40 {
  width: 40%;
}

.width-30 {
  width: 30%;
}

.width-20 {
  width: 20%;
}

.width-10 {
  width: 10%;
}

.flot-right {
  float: right;
}

.btn-margin {
  margin: 10px 0px;
}

.advance-filter-header {
  padding: 10px 0px 0px 0px;
  border-bottom: solid thin rgba(0, 0, 0, 0.12);
  margin: 0px 0px 10px;
}

.advance-filter-header h6 {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  text-transform: uppercase;
}
</style>