var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.parent != null)?_c('div',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"exact-active-class":"grey--text","to":{
          name: 'PageServiceModelRead',
          params: {
            serviceKey: _vm.parentModel.serviceKey,
            modelKey: _vm.parentModel.key,
            modelId: _vm.parent.id,
          },
        }}},[_vm._v(_vm._s(_vm.text))])],1):_c('span',[_vm._v("-")])])}
var staticRenderFns = []

export { render, staticRenderFns }