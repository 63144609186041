import ManageActionColumn from "../../../components/common/BreadTabulatorActionDetachButton.vue";
import Vue from "vue";
import Vuetify from "vuetify";
import store from "../../../store";
import router from "../../../router";

export default {
  serviceKey: "crm",
  key: "crmServicePermissionToInternalRole",
  column: {
    uid: "id", // tell the system which column to be used as unique id
    text: "id", // tell the system which column to display as text
  },
  name: {
    singular: "Role Permission",
    plural: "Role Permission",
    //
    /* crmServicePermission: {
      singular: 'Role',
      plural: 'Roles',
    },
    internalUser: {
      singular: 'Permission',
      plural: 'Permissions',
    } */
  },
  parents: ["crmServicePermission", "internalRole"],

  apiUrls: {
    apiBrowseUrl: "",
    apiStoreUrl: "",
    apiUpdateUrl: "",
    apiReadUrl: "",
    apiMultiUpdateUrl: "",
    apiTrashedBrowseUrl: "",
    apiSoftDeleteUrl: "",
    apiHardDeleteUrl: "",
    apiRestoreUrl: "",
  },

  permissionKeys: {
    browse: "crm-crmServicePermissionToInternalRole-browse",
    read: "crm-crmServicePermissionToInternalRole-read",
    add: "crm-crmServicePermissionToInternalRole-add",
    edit: "crm-crmServicePermissionToInternalRole-edit",
    delete: "crm-crmServicePermissionToInternalRole-delete",
    restore: "crm-crmServicePermissionToInternalRole-restore",
  },

  recordAccessControl: {
    browse: {
      all: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      own: {
        roles: ["superadmin"],
        apiUrl: "",
      },
      assignedTo: {
        roles: ["salesperson", "sales-lead"],
        apiUrl: "",
      },
      belongsTo: {
        ssoBusiness: {
          roles: ["superadmin"],
          apiUrl: "",
        },
        ssoTeam: {
          roles: ["superadmin"],
          apiUrl: "",
        },
      },
    },
  },

  roleBasedFunctionalities: {
    browse: {
      all: ["superadmin"],
      own: ["salesperson"],
      team: ["sales-lead"],
    },
  },

  browse: {
    table: {
      actions: {
        manageCellEdit: true,
        columnSelectionDisabledCount: 3,
        isActionColumnVisible: true,
        isTrashedTabVisible: true,
      },
      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          headerFilter: false,
          selectableColumn: false,
          visible: true,
          download: false,
        },

        {
          title: "Index",
          formatter: (cell) => {
            return cell.getRow().getData().rownum;
          },
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          visible: true,
          download: false,
        },

        {
          title: "Role",
          field: "internalRoleSlug",
          dataType: "string",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },

        {
          title: "Permission",
          field: "CrmServicePermission.name",
          dataType: "string",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: true,
          selectableColumn: true,
          hozAlign: "left",
        },

        /* {
          dataType: "model",
          relationship: "parent",
          modelKey: "crmServicePermission",
          text: "Permission",
          value: "crmServicePermission",
          sortable: false,
          searchable: false,
          filterable: false,
        },
        {
          dataType: "model",
          relationship: "parent",
          modelKey: "internalRole",
          text: "Role",
          value: "internalRole",
          sortable: false,
          searchable: false,
          filterable: false,
        }, */

        {
          title: "Actions",
          formatter: (cell) => {
            Vue.use(Vuetify);
            let ComponentClass = Vue.extend(ManageActionColumn);
            let instance = new ComponentClass({
              propsData: {
                cellData: cell.getData(),
                cellValue: cell.getValue(),
                cellTableData: cell.getTable().getData(),
                cellRowData: cell.getRow().getData(),
                serviceKey: "crm",
                parentModelKey: "crmServicePermission",
                parentModelId: cell.getRow().getData().CrmServicePermission.id,
                intermediateModelKey: "internalRole",
                intermediateModelId: cell.getRow().getData().id,
                childModelKey: "crmServicePermissionToInternalRole",
              },
              vuetify: new Vuetify({}),
              store,
              router,
            });
            instance.$mount();
            return instance.$el;
          },
        },
      ],
    },
  },
  read: {
    table: {
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Key",
          value: "key",
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: "model",
        key: "crmServicePermissionKey",
        name: "Permission",
        modelKey: "crmServicePermission",
        optionText: "name",
        optionValue: "key",
      },
      {
        type: "model",
        key: "internalRoleSlug",
        name: "Role",
        modelKey: "internalRole",
        optionText: "name",
        optionValue: "slug",
      },
    ],
    rules: {
      crmServicePermissionKey: ["required"],
      internalRoleSlug: ["required"],
    },
  },
  edit: false,
  delete: false,
};
