var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex ma-2"},[_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"disabled":_vm.childModel.key === 'ssoTeamToInternalUser' && !_vm.isTeamAdmin,"to":{
            name: 'PageServiceModelBreadTabulatorChildAdd',
            params: {
              parentModelKey: _vm.parentModel.key,
              parentModelId: _vm.parent.id,
              childModelKey: _vm.childModel.key,
            },
          }}},[_vm._v("Attach "+_vm._s((_vm.childModel.name[_vm.parentModel.key]) ? _vm.childModel.name[_vm.parentModel.key].singular : _vm.childModel.name.singular))])],1),_vm._m(0),_c('BreadTabulator',{ref:"tabulatorDataTable",attrs:{"layout":"fitColumns","autoColumns":false,"paginationSize":_vm.paginationSize,"paginationSizeSelector":_vm.paginationSizeSelector,"apiUrl":_vm.apiGetBrowseUrl(),"columns":_vm.columns,"modelKey":_vm.childModel.key}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticStyle:{"float":"left","font-size":"12px"},attrs:{"id":"record-count"}})])}]

export { render, staticRenderFns }